import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './index.css';
import { NavItems, NavItemsLabelMap } from '../nav-drawer/nav-drawer';

export interface SubMenuItems {
  text: string;
  onClick: () => { success: boolean };
  icon?: { icon: JSX.Element; isPrefix: boolean };
  id_css_selector?: string;
}

interface SubMenuProps {
  menuId: NavItems;
  trigger: React.ReactNode;
  items: SubMenuItems[];
  isOpen: boolean;
  onOpen: (menuId: string | null) => void;
}

export const SubMenu: React.FC<SubMenuProps> = ({
  menuId,
  trigger,
  items,
  isOpen,
  onOpen,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onOpen(menuId);
  };

  const handleClose = () => {
    onOpen(null);
  };

  const onItemClick = (item: { text: string; onClick: () => void }) => {
    handleClose();
    item.onClick();
  };

  const showSubmenuTitle = (): boolean => {
    return !(menuId === NavItems.ME || menuId == NavItems.HOME);
  };

  return (
    <div className="sub-menu-wrapper" id={menuId + 'trigger2'}>
      <div
        id={menuId + 'trigger'}
        className="trigger-wrapper"
        style={{ zIndex: '5' }}
        onClick={handleClick}
      >
        {trigger}
      </div>

      <Menu
        id={`${menuId}-menu menu`}
        sx={{ zIndex: '1' }}
        anchorEl={document.getElementById(menuId + 'trigger')}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': `${menuId}-button`,
        }}
      >
        {showSubmenuTitle() && (
          <div
            key={`${menuId}-title`}
            id={`${menuId}-title`}
            className="submenu-title"
          >
            <span>{NavItemsLabelMap[menuId]}</span>
          </div>
        )}
        {items.map((item, index) => (
          <MenuItem
            className={item.id_css_selector ? `${item.id_css_selector}` : ''}
            key={index}
            id={
              item.id_css_selector
                ? `menu-${item.id_css_selector}`
                : `menu-${menuId}-${index}`
            }
            onClick={() => onItemClick(item)}
          >
            <div
              className={
                item.id_css_selector
                  ? `submenu-text-wrapper ${item.id_css_selector}`
                  : 'submenu-text-wrapper'
              }
              id={`${menuId}-text-wrapper-${index}`}
            >
              {' '}
              {item.icon?.isPrefix && (
                <div className="h-full flex flex-col justify-center">
                  {item.icon.icon}
                </div>
              )}
              <span>{item.text}</span>
              {item.icon && !item.icon.isPrefix && (
                <div className="h-full flex flex-col justify-center">
                  {item.icon.icon}
                </div>
              )}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
