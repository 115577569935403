import { ApiPaginationResponse, ApiResponse } from '../../types/api';
import {
  BaseManagerProficiency,
  BaseUserProficiency,
  GetUpcomingExpirationsDto,
  UserProficiencyModel,
} from '../../types/user';
import { order } from '../../utils/orders';

import axiosInstance from '../axios';

class ProficienciesService {
  updateUserProficiencies(body: {
    userProficiency: UserProficiencyModel[];
  }): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/userproficiency`, body);
  }

  getUpcomingExpirationsForUser(
    body: GetUpcomingExpirationsDto,
  ): Promise<ApiResponse<any>> {
    return axiosInstance.get(`/api/userproficiency/upcoming_expirations`, {
      params: body,
    });
  }

  updateManagerProficiencies(body: {
    managerProficiencies: UserProficiencyModel[];
  }): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/userproficiencybymanager`, body);
  }

  editOrCreateUserProficiency(
    body: Partial<BaseUserProficiency>,
  ): Promise<ApiResponse<any[]>> {
    return axiosInstance.post(`/api/userproficiency`, body);
  }

  editOrCreateManagerProficiency(
    body: Partial<BaseManagerProficiency>,
  ): Promise<ApiResponse<any[]>> {
    return axiosInstance.post(`/api/userproficiencybymanager`, body);
  }

  getSkillRelationships(user_id: number): Promise<ApiResponse<any[]>> {
    return axiosInstance.get(
      `/api/userproficiency/skill_relationships/${user_id}`,
    );
  }

  // if not date, defaults to proficiencies last updated more than 6 months from today
  getStale(
    user_id: number,
    limit = 25,
    page?: number,
    date?: Date,
    asc?: boolean,
    order_by?: string,
  ): Promise<ApiResponse<ApiPaginationResponse<UserProficiencyModel>>> {
    let queryString = `?limit=${limit}`;
    if (date) {
      queryString += `&date=${date}`;
    }

    if (asc) {
      queryString += `&asc=${asc}`;
    }
    if (page) {
      queryString += `&page=${page}`;
    }
    if (order_by) {
      queryString += `&order_by=${order_by}`;
    }

    return axiosInstance.get(
      `/api/userproficiency/stale/${user_id}` + queryString,
    );
  }
}

export const proficienciesService = new ProficienciesService();
