import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FC } from 'react';
import {
  getDesiredContractColor,
  getProficiencyColorFromScore,
} from '../../../utils/helperfunctions';
import './index.css';

interface ProficiencyBarProps {
  partiallyMeetsTarget: number; // between 0 and 100
  meetsTarget: number; // between 0 and 100
  score: number; //between 0 and org's max number
  desiredScore: number; //between 0 and org's max number
  skillName: string;
  maxSme: number;
}
const ProficiencyBar: FC<ProficiencyBarProps> = ({
  score,
  partiallyMeetsTarget,
  meetsTarget,
  skillName,
  maxSme,
  desiredScore,
}) => {
  let currentColor = getProficiencyColorFromScore(
    score,
    desiredScore,
    meetsTarget,
    partiallyMeetsTarget,
  );

  let desiredColor = getDesiredContractColor(currentColor);

  const series: any[] = [
    //colorful bar showing users proficiency
    {
      name: 'Current',
      color: currentColor,
      data: [score],
      pointWidth: 30,
      minPointLength: 0.75,
    },
  ];

  if (desiredScore > -1) {
    // if a desired proficiency is set
    series.push(
      //skinny bar showing desired proficiency
      {
        name: 'Desired',
        color: desiredColor,
        data: [desiredScore],
        pointWidth: 10,
      },
    );
  }

  const options = {
    chart: {
      type: 'bar',
      minPointLength: 0.5,
      backgroundColor: null,
      borderWidth: 0,
      margin: [-50, 0, -15, 0],
      width: 160,
      height: 40,
      style: {
        overflow: 'visible',
      },
      skipClone: true,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },

    yAxis: {
      min: 0,
      max: maxSme,
      softMax: score > maxSme ? score : maxSme,
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      enabled: true,
      hideDelay: 0,
      outside: true,
      shared: true,
    },
    plotOptions: {
      series: {
        minPointLength: 0.5,
        animation: true,
        lineWidth: 0,
        // lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2,
            },
          },
        },
        fillOpacity: 1,
      },
      column: {
        minPointLength: 0.5,
        negativeColor: desiredColor,
        borderColor: desiredColor,
      },
      bar: {
        minPointLength: 0.5,
        grouping: false,
        shadow: false,
        borderWidth: 0,
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
    },
    series: series,
    xAxis: {
      categories: [skillName],
    },
  };

  return (
    <div className="proficiencybar-container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ProficiencyBar;
