const UpdateSkillsSVG = ({ size = 'xs' }) => {
  const sizeMap = {
    xs: 0.05,
    sm: 0.075,
    md: 0.1,
    lg: 0.15,
  };
  //@ts-ignore
  const width = sizeMap[size] * 4000;
  //@ts-ignore
  const height = sizeMap[size] * 2798;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4000 2798" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_937_3221)">
      <path d="M4000 2797.04H0V0H4000V2797.04Z" fill="#FAF9FF"/>
      <path d="M703.821 2488.67H3158.97C3375.24 2335.21 3240.65 2235.95 3199.83 2218.27C3007.61 2135.07 2653.13 2410.19 2612.61 2287.49C2577.05 2179.83 2746.57 2157 2746.57 1984.47C2746.57 1821.19 2262.29 1795.55 1953.11 2055.92C1674.63 2290.44 1139.46 2242.71 993.629 2154.79C749.155 2007.39 282.583 2126.21 703.821 2488.67Z" fill="#F8F7FF"/>
      <path d="M3453.64 1508.83L3447.16 1504.31C3456.73 1490.59 3465.04 1477.16 3471.87 1464.43L3478.84 1468.15C3471.87 1481.17 3463.39 1494.85 3453.64 1508.83Z" fill="#B3A4F5"/>
      <path d="M3356.43 1619.32L3350.99 1613.59C3375.95 1589.87 3398.39 1566.05 3417.68 1542.81L3423.76 1547.87C3404.27 1571.33 3381.61 1595.37 3356.43 1619.32ZM3243.47 1713.44L3238.77 1707.08C3265.53 1687.36 3291.15 1666.91 3314.91 1646.31L3320.09 1652.28C3296.16 1673.01 3270.39 1693.6 3243.47 1713.44ZM3120.83 1794.43L3116.8 1787.63C3145 1770.96 3172.67 1753.36 3199.05 1735.31L3203.51 1741.83C3176.99 1759.97 3149.17 1777.67 3120.83 1794.43ZM2990.96 1863.16L2987.59 1856.03C3016.97 1842.17 3046.2 1827.28 3074.45 1811.76L3078.25 1818.69C3049.85 1834.28 3020.49 1849.24 2990.96 1863.16ZM2855.35 1919.68L2852.65 1912.25C2883.04 1901.19 2913.51 1889.03 2943.19 1876.12L2946.33 1883.37C2916.51 1896.33 2885.89 1908.56 2855.35 1919.68ZM2715.16 1963.61L2713.15 1955.97C2744.35 1947.75 2775.79 1938.43 2806.6 1928.25L2809.08 1935.76C2778.12 1945.97 2746.52 1955.35 2715.16 1963.61ZM1938.8 1976.36C1906.07 1968.45 1875.29 1959.39 1844.75 1948.65L1847.36 1941.2C1877.67 1951.84 1908.17 1960.83 1940.65 1968.69L1938.8 1976.36ZM2571.52 1994.36L2570.23 1986.57C2602.05 1981.31 2634.23 1974.95 2665.84 1967.65L2667.61 1975.36C2635.84 1982.68 2603.51 1989.08 2571.52 1994.36ZM2083.48 2001.89C2049.81 1997.87 2017.25 1992.8 1986.68 1986.81L1988.2 1979.07C2018.57 1985 2050.95 1990.04 2084.41 1994.05L2083.48 2001.89ZM2425.61 2011.36L2425.08 2003.48C2457.31 2001.29 2489.93 1998.01 2522.03 1993.75L2523.07 2001.59C2490.81 2005.87 2458.01 2009.16 2425.61 2011.36ZM2229.88 2013.08C2196.05 2011.79 2163.19 2009.73 2132.19 2006.97L2132.88 1999.09C2163.76 2001.85 2196.49 2003.89 2230.17 2005.19L2229.88 2013.08ZM2323.76 2014.81C2308.69 2014.81 2293.56 2014.69 2278.79 2014.44L2278.93 2006.53C2310.73 2007.08 2343.84 2007.13 2376.41 2005.99L2376.69 2013.88C2359.11 2014.51 2341.29 2014.81 2323.76 2014.81Z" fill="#B3A4F5"/>
      <path d="M1798.88 1931.2C1784.81 1925.44 1770.55 1919.24 1755.27 1912.23L1758.56 1905.04C1773.75 1912.01 1787.91 1918.17 1801.87 1923.89L1798.88 1931.2Z" fill="#B3A4F5"/>
      <path d="M1329.88 835.586H1288.81V550.666H1329.88V835.586Z" fill="#F0EEFF"/>
      <path d="M1388.4 835.586H1347.32V633.8H1388.4V835.586Z" fill="#F0EEFF"/>
      <path d="M1446.91 946.894H1405.84V741.453H1446.91V946.894Z" fill="#F0EEFF"/>
      <path d="M1505.43 1053.33H1464.35V775.92H1505.43V1053.33Z" fill="#F0EEFF"/>
      <path d="M1680.96 741.453H1639.89V616.24H1680.96V741.453Z" fill="#F0EEFF"/>
      <path d="M1739.47 788.867H1698.4V650.587H1739.47V788.867Z" fill="#F0EEFF"/>
      <path d="M1797.99 896.533H1756.91V693.12H1797.99V896.533Z" fill="#F0EEFF"/>
      <path d="M1973.52 835.586H1932.45V725.653H1973.52V835.586Z" fill="#F0EEFF"/>
      <path d="M1622.44 835.586H1581.37V502.76H1622.44V835.586Z" fill="#F8F7FF"/>
      <path d="M1271.37 876.773H1230.3V456.56H1271.37V876.773Z" fill="#F8F7FF"/>
      <path d="M1212.86 669.173H1171.79V584.533H1212.86V669.173Z" fill="#F8F7FF"/>
      <path d="M1154.35 749.547H1113.27V642.734H1154.35V749.547Z" fill="#F8F7FF"/>
      <path d="M1095.83 775.92H1054.76V678.853H1095.83V775.92Z" fill="#F8F7FF"/>
      <path d="M978.808 734.693H937.737V688.48H978.808V734.693Z" fill="#F8F7FF"/>
      <path d="M1563.93 908.374H1522.87V703.92H1563.93V908.374Z" fill="#F8F7FF"/>
      <path d="M1856.49 835.586H1815.43V584.533H1856.49V835.586Z" fill="#F8F7FF"/>
      <path d="M1915.01 762.96H1873.93V522.52H1915.01V762.96Z" fill="#F8F7FF"/>
      <path d="M1037.32 704.853H996.251V619.253H1037.32V704.853Z" fill="#F8F7FF"/>
      <path d="M2032.03 669.173H1990.96V597.667H2032.03V669.173Z" fill="#F8F7FF"/>
      <path d="M1184.96 1351.6H757.625V924.267H1184.96V1351.6Z" fill="#F2F0FF"/>
      <path d="M757.631 1171.76C745.791 1171.76 736.156 1181.4 736.156 1193.24C736.156 1205.07 745.791 1214.71 757.631 1214.71C769.466 1214.71 779.095 1205.07 779.095 1193.24C779.095 1181.4 769.466 1171.76 757.631 1171.76ZM757.631 1222.6C741.432 1222.6 728.255 1209.43 728.255 1193.24C728.255 1177.04 741.432 1163.87 757.631 1163.87C773.822 1163.87 786.996 1177.04 786.996 1193.24C786.996 1209.43 773.822 1222.6 757.631 1222.6ZM744.886 1260.59H770.366V1246.07L773.187 1245.23C781.219 1242.83 788.71 1238.49 794.847 1232.69L796.988 1230.68L809.58 1237.95L822.314 1215.88L809.752 1208.63L810.427 1205.76C811.402 1201.63 811.895 1197.41 811.895 1193.24C811.895 1189.07 811.402 1184.85 810.427 1180.72L809.752 1177.87L822.314 1170.61L809.58 1148.53L796.988 1155.8L794.85 1153.79C788.711 1147.99 781.22 1143.65 773.184 1141.24L770.366 1140.4V1125.91H744.886V1140.4L742.07 1141.24C734.028 1143.65 726.534 1147.99 720.399 1153.79L718.26 1155.8L705.68 1148.53L692.936 1170.61L705.511 1177.87L704.835 1180.72C703.862 1184.85 703.367 1189.07 703.367 1193.24C703.367 1197.41 703.862 1201.63 704.835 1205.76L705.511 1208.63L692.936 1215.88L705.68 1237.95L718.26 1230.68L720.399 1232.69C726.538 1238.49 734.028 1242.83 742.066 1245.23L744.886 1246.07V1260.59ZM778.267 1268.48H736.984V1251.88C729.771 1249.33 723.014 1245.43 717.18 1240.43L702.791 1248.73L682.142 1212.99L696.532 1204.68C695.824 1200.88 695.466 1197.05 695.466 1193.24C695.466 1189.43 695.824 1185.59 696.532 1181.8L682.142 1173.51L702.791 1137.75L717.18 1146.05C723.011 1141.05 729.767 1137.15 736.984 1134.6V1118H778.267V1134.6C785.48 1137.15 792.238 1141.05 798.071 1146.05L812.474 1137.75L833.106 1173.51L818.73 1181.8C819.438 1185.59 819.796 1189.43 819.796 1193.24C819.796 1197.05 819.438 1200.88 818.728 1204.68L833.106 1212.99L812.474 1248.73L798.071 1240.43C792.235 1245.43 785.478 1249.33 778.267 1251.88V1268.48Z" fill="#DDD6FF"/>
      <path d="M771.632 1836.09C754.928 1836.09 741.34 1849.68 741.34 1866.39C741.34 1883.08 754.928 1896.65 771.632 1896.65C788.326 1896.65 801.908 1883.08 801.908 1866.39C801.908 1849.68 788.326 1836.09 771.632 1836.09ZM771.632 1904.56C750.572 1904.56 733.437 1887.44 733.437 1866.39C733.437 1845.32 750.572 1828.19 771.632 1828.19C792.682 1828.19 809.809 1845.32 809.809 1866.39C809.809 1887.44 792.682 1904.56 771.632 1904.56ZM753.097 1958.44H790.152V1938.55L792.974 1937.71C803.989 1934.43 814.268 1928.48 822.697 1920.52L824.837 1918.51L842.094 1928.47L860.616 1896.36L843.393 1886.41L844.068 1883.56C845.404 1877.91 846.08 1872.12 846.08 1866.39C846.08 1860.64 845.404 1854.87 844.068 1849.2L843.393 1846.35L860.616 1836.4L842.094 1804.31L824.837 1814.27L822.697 1812.25C814.276 1804.31 803.994 1798.35 792.968 1795.04L790.152 1794.2V1774.32H753.097V1794.2L750.282 1795.04C739.246 1798.36 728.964 1804.31 720.549 1812.25L718.41 1814.27L701.165 1804.31L682.633 1836.4L699.872 1846.35L699.196 1849.2C697.86 1854.88 697.182 1860.65 697.182 1866.39C697.182 1872.12 697.86 1877.89 699.196 1883.56L699.872 1886.41L682.633 1896.36L701.165 1928.47L718.41 1918.51L720.549 1920.52C728.974 1928.48 739.252 1934.41 750.277 1937.71L753.097 1938.55V1958.44ZM798.053 1966.35H745.196V1944.37C734.994 1940.92 725.453 1935.4 717.314 1928.25L698.276 1939.25L671.837 1893.47L690.878 1882.49C689.817 1877.16 689.281 1871.76 689.281 1866.39C689.281 1861.01 689.817 1855.61 690.878 1850.28L671.837 1839.29L698.276 1793.51L717.314 1804.51C725.445 1797.37 734.986 1791.85 745.196 1788.37V1766.43H798.053V1788.37C808.254 1791.84 817.797 1797.37 825.934 1804.51L844.989 1793.51L871.408 1839.29L852.386 1850.28C853.445 1855.61 853.982 1861.01 853.982 1866.39C853.982 1871.76 853.445 1877.16 852.386 1882.49L871.408 1893.47L844.989 1939.25L825.934 1928.25C817.79 1935.41 808.249 1940.92 798.053 1944.37V1966.35Z" fill="#DDD6FF"/>
      <path d="M1184.97 1038.48C1121.87 1038.48 1070.71 987.36 1070.71 924.267C1070.71 861.16 1121.87 810.04 1184.97 810.04C1248.06 810.04 1299.19 861.16 1299.19 924.267C1299.19 987.36 1248.06 1038.48 1184.97 1038.48ZM1184.97 683.066C1051.75 683.066 943.77 791.04 943.77 924.267C943.77 1057.48 1051.75 1165.45 1184.97 1165.45C1318.19 1165.45 1426.16 1057.48 1426.16 924.267C1426.16 791.04 1318.19 683.066 1184.97 683.066Z" fill="white"/>
      <path d="M1072.15 863.773L985.16 817C1024.79 743.307 1101.35 697.52 1184.96 697.52V796.293C1137.76 796.293 1094.53 822.147 1072.15 863.773Z" fill="#CCC2FF"/>
      <path d="M1068.9 870.253L979.39 828.494C981.214 824.6 983.159 820.72 985.178 816.973L1072.13 863.813C1071.01 865.893 1069.93 868.053 1068.9 870.253Z" fill="white"/>
      <path d="M968.529 992C961.69 970.133 958.224 947.347 958.224 924.253C958.224 890.827 965.345 858.613 979.392 828.493L1068.9 870.253C1061 887.2 1056.99 905.36 1056.99 924.253C1056.99 937.333 1058.95 950.2 1062.8 962.52L968.529 992Z" fill="#DDD6FF"/>
      <path d="M997.357 1051.63C984.847 1033.23 975.148 1013.17 968.529 992L1062.8 962.52C1066.52 974.427 1071.98 985.72 1079.04 996.094L997.357 1051.63Z" fill="white"/>
      <path d="M1130.76 1144.47C1076.36 1131.11 1028.99 1098.15 997.36 1051.64L1079.03 996.094C1096.92 1022.39 1123.65 1041.01 1154.31 1048.55L1130.76 1144.47Z" fill="#CCC2FF"/>
      <path d="M1184.96 1151C1166.66 1151 1148.42 1148.8 1130.76 1144.47L1154.3 1048.55C1164.26 1050.99 1174.58 1052.23 1184.96 1052.23C1226.6 1052.23 1265.78 1031.85 1289.76 997.733L1370.57 1054.52C1328.11 1114.93 1258.73 1151 1184.96 1151Z" fill="#B3A4F5"/>
      <path d="M1370.57 1054.51L1289.76 997.734C1290.99 995.987 1292.17 994.2 1293.32 992.387L1376.85 1045.09C1374.84 1048.27 1372.73 1051.44 1370.57 1054.51Z" fill="white"/>
      <path d="M1376.87 1045.08L1293.31 992.4C1295.33 989.2 1297.22 985.88 1298.95 982.52L1386.84 1027.57C1383.79 1033.53 1380.43 1039.41 1376.87 1045.08Z" fill="white"/>
      <path d="M1386.84 1027.57L1298.94 982.52C1308.22 964.413 1312.93 944.813 1312.93 924.253H1411.71C1411.71 960.12 1403.11 995.84 1386.84 1027.57Z" fill="#CCC2FF"/>
      <path d="M1411.71 924.253H1312.93C1312.93 869.627 1278.25 820.987 1226.64 803.213L1258.79 709.827C1350.25 741.307 1411.71 827.48 1411.71 924.253Z" fill="#FFD4CC"/>
      <path d="M1226.64 803.213C1213.29 798.627 1199.27 796.293 1184.96 796.293V697.52C1210.23 697.52 1235.07 701.653 1258.79 709.827L1226.64 803.213Z" fill="white"/>
      <path d="M953.06 1240.73L914.485 1294.75H991.636L953.06 1240.73Z" fill="#CCC2FF"/>
      <path d="M1083.11 1466.28L1121.69 1412.28H1044.54L1083.11 1466.28Z" fill="#FFCCD6"/>
      <path d="M1350.17 1084.71L1344.51 1079.2C1374.39 1048.44 1394.61 1010.08 1403.01 968.253L1410.76 969.813C1402.07 1013.12 1381.11 1052.85 1350.17 1084.71Z" fill="#A292ED"/>
      <path d="M1401.31 872.494C1399.96 866.894 1398.4 861.28 1396.63 855.827L1404.15 853.387C1405.97 859.054 1407.6 864.854 1408.99 870.667L1401.31 872.494Z" fill="#A292ED"/>
      <path d="M1384.03 824.987C1346.08 749.04 1269.81 701.867 1184.97 701.867C1144.73 701.867 1105.31 712.72 1070.98 733.267L1066.93 726.48C1102.48 705.214 1143.3 693.974 1184.97 693.974C1272.82 693.974 1351.8 742.813 1391.09 821.453L1384.03 824.987Z" fill="#A292ED"/>
      <path d="M1184.97 1154.55C1057.98 1154.55 954.674 1051.24 954.674 924.254C954.674 871.72 971.902 822.254 1004.49 781.187L1010.68 786.107C979.211 825.747 962.576 873.52 962.576 924.254C962.576 1046.88 1062.34 1146.65 1184.97 1146.65C1245.59 1146.65 1302.25 1122.69 1344.51 1079.2L1350.17 1084.69C1306.42 1129.75 1247.75 1154.55 1184.97 1154.55Z" fill="#A292ED"/>
      <path d="M1011.22 885.267L862.148 821.947H722.247V814.04H863.758L864.498 814.36L1014.32 877.987L1011.22 885.267Z" fill="#CCC2FF"/>
      <path d="M703.481 821.947H677.8V814.04H703.481V821.947Z" fill="#CCC2FF"/>
      <path d="M827.71 795.773H722.247V787.88H827.71V795.773Z" fill="#CCC2FF"/>
      <path d="M789.411 769.6H636.317V761.707H789.411V769.6Z" fill="#CCC2FF"/>
      <path d="M789.411 743.427H722.247V735.533H789.411V743.427Z" fill="#CCC2FF"/>
      <path d="M3458.21 2496.57H431.888V2488.67H3458.21V2496.57Z" fill="#C4B8FF"/>
      <path d="M1112.16 2616.07H887.124V2608.16H1112.16V2616.07Z" fill="#C4B8FF"/>
      <path d="M1673.76 2616.07H1594.64V2608.16H1673.76V2616.07Z" fill="#C4B8FF"/>
      <path d="M2576.68 2616.07H1764.97V2608.16H2576.68V2616.07Z" fill="#C4B8FF"/>
      <path d="M2775.2 2616.07H2644.75V2608.16H2775.2V2616.07Z" fill="#C4B8FF"/>
      <path d="M3277.13 2555.85H1336.48V2547.96H3277.13V2555.85Z" fill="#C4B8FF"/>
      <path d="M2777.45 1307.45C2736.99 1287.01 2699.49 1261 2666.05 1230.13L2746.44 1143.03C2771.8 1166.44 2800.21 1186.16 2830.88 1201.64L2777.45 1307.45Z" fill="#DDD6FF"/>
      <path d="M2996.73 1359.55C2927.83 1359.55 2861.15 1345.39 2798.55 1317.47L2846.83 1209.23C2894.13 1230.32 2944.57 1241.01 2996.73 1241.01C3102.41 1241.01 3203.32 1195.41 3273.59 1115.91L3362.4 1194.4C3269.65 1299.35 3136.37 1359.55 2996.73 1359.55Z" fill="#F2F0FF"/>
      <path d="M3391.57 1158.05L3295.69 1088.36C3298.19 1084.95 3300.61 1081.49 3302.97 1077.99L3401.15 1144.41C3398.07 1148.96 3394.84 1153.55 3391.57 1158.05Z" fill="#F2F0FF"/>
      <path d="M3458.41 1029.67L3346.32 991.186C3352.05 974.48 3356.61 957.2 3359.87 939.827L3476.36 961.653C3472.05 984.653 3466.01 1007.53 3458.41 1029.67Z" fill="#E9E6FF"/>
      <path d="M3482.4 919.067L3364.44 907.614C3365.59 895.72 3366.17 883.6 3366.17 871.574C3366.17 667.88 3200.45 502.147 2996.73 502.147C2947.49 502.147 2899.67 511.707 2854.6 530.574L2808.83 421.254C2868.47 396.28 2931.69 383.614 2996.73 383.614C3265.8 383.614 3484.71 602.52 3484.71 871.574C3484.71 887.414 3483.93 903.387 3482.4 919.067Z" fill="#F2F0FF"/>
      <path d="M2721.24 625.733L2632.84 546.773C2672.67 502.2 2719.69 465.667 2772.59 438.213L2827.19 543.4C2787.09 564.213 2751.45 591.92 2721.24 625.733Z" fill="#F2F0FF"/>
      <path d="M2615.93 1176.4C2546.83 1090.24 2508.77 981.973 2508.77 871.573C2508.77 794.107 2526.49 719.947 2561.44 651.187L2667.11 704.88C2640.69 756.853 2627.31 812.933 2627.31 871.573C2627.31 956.333 2655.35 1036.09 2708.4 1102.25L2615.93 1176.4Z" fill="#F2F0FF"/>
      <path d="M2996.73 1304.23V1296.33C3230.95 1296.33 3421.49 1105.79 3421.49 871.573C3421.49 837.787 3417.49 804.133 3409.6 771.573L3417.28 769.707C3425.32 802.893 3429.39 837.16 3429.39 871.573C3429.39 1110.15 3235.31 1304.23 2996.73 1304.23Z" fill="#B3A4F5"/>
      <path d="M3398.65 734.08C3397.59 730.96 3396.48 727.84 3395.33 724.76L3402.73 722.013C3403.91 725.16 3405.04 728.333 3406.13 731.52L3398.65 734.08Z" fill="#B3A4F5"/>
      <path d="M2587.45 757.84L2579.84 755.72C2631.75 569.2 2803.17 438.933 2996.73 438.933C3141.08 438.933 3275.47 510.76 3356.23 631.067L3349.67 635.467C3270.37 517.347 3138.44 446.827 2996.73 446.827C2806.71 446.827 2638.41 574.72 2587.45 757.84Z" fill="#B3A4F5"/>
      <path d="M2881.04 1288.52C2720.2 1243.84 2598.2 1108.76 2570.21 944.413L2578.01 943.08C2605.48 1104.43 2725.25 1237.04 2883.16 1280.91L2881.04 1288.52Z" fill="#B3A4F5"/>
      <path d="M3335.61 871.573C3335.61 1058.73 3183.89 1210.45 2996.75 1210.45C2809.59 1210.45 2657.87 1058.73 2657.87 871.573C2657.87 684.427 2809.59 532.707 2996.75 532.707C3183.89 532.707 3335.61 684.427 3335.61 871.573Z" fill="#F8F7FF"/>
      <path d="M2842.45 1071.08H2769.16V929.693H2842.45V1071.08Z" fill="#CCC2FF"/>
      <path d="M2931.8 1071.08H2858.51V792.294H2931.8V1071.08Z" fill="#CCC2FF"/>
      <path d="M2931.8 950.72H2858.51V908.68H2931.8V950.72Z" fill="#FFB0BF"/>
      <path d="M2931.8 908.68H2858.51V792.294H2931.8V908.68Z" fill="#FFD4CC"/>
      <path d="M3021.13 1071.08H2947.84V830.347H3021.13V1071.08Z" fill="#CCC2FF"/>
      <path d="M3110.48 1071.08H3037.19V631.24H3110.48V1071.08Z" fill="#CCC2FF"/>
      <path d="M3199.83 1071.08H3126.53V706.067H3199.83V1071.08Z" fill="#CCC2FF"/>
      <path d="M3021.13 950.72H2947.84V929.694H3021.13V950.72Z" fill="#FFB0BF"/>
      <path d="M2842.45 992.773H2769.16V971.76H2842.45V992.773Z" fill="#FFB0BF"/>
      <path d="M2842.45 971.76H2769.16V929.694H2842.45V971.76Z" fill="#FFD4CC"/>
      <path d="M3021.13 929.693H2947.84V908.68H3021.13V929.693Z" fill="#FFD4CC"/>
      <path d="M3110.48 950.72H3037.19V866.734H3110.48V950.72Z" fill="#FFB0BF"/>
      <path d="M3110.48 866.733H3037.19V792.293H3110.48V866.733Z" fill="#FFD4CC"/>
      <path d="M3199.83 892.32H3126.53V706.067H3199.83V892.32Z" fill="#FFD4CC"/>
      <path d="M2931.8 992.773H2858.51V950.733H2931.8V992.773Z" fill="#B3A4F5"/>
      <path d="M2842.45 1013.79H2769.16V992.773H2842.45V1013.79Z" fill="#B3A4F5"/>
      <path d="M3110.48 971.76H3037.19V950.734H3110.48V971.76Z" fill="#B3A4F5"/>
      <path d="M3021.13 1033.99H2947.84V950.734H3021.13V1033.99Z" fill="#B3A4F5"/>
      <path d="M3199.83 950.934H3126.53V912.454H3199.83V950.934Z" fill="#B3A4F5"/>
      <path d="M3199.83 912.453H3126.53V891.44H3199.83V912.453Z" fill="#FFB0BF"/>
      <path d="M585.459 1639.21C550.252 1639.21 521.678 1610.68 521.678 1575.44C521.678 1540.23 550.252 1511.69 585.459 1511.69C620.71 1511.69 649.24 1540.23 649.24 1575.44C649.24 1610.68 620.71 1639.21 585.459 1639.21ZM764.339 1606.95V1543.96H728.064C724.327 1526.96 717.651 1511.12 708.571 1496.88L734.207 1471.24L689.698 1426.73L664.062 1452.37C649.819 1443.29 633.93 1436.57 616.926 1432.83V1396.6H553.992V1432.83C536.988 1436.57 521.1 1443.29 506.902 1452.37L481.264 1426.73L436.755 1471.24L462.394 1496.88C453.314 1511.12 446.592 1526.96 442.854 1543.96H406.623V1606.95H442.854C446.592 1623.95 453.314 1639.8 462.394 1654.04L436.755 1679.67L481.264 1724.19L506.902 1698.55C521.1 1707.63 536.988 1714.35 553.992 1718.08V1754.32H616.926V1718.08C633.93 1714.35 649.819 1707.63 664.062 1698.55L689.698 1724.19L734.207 1679.67L708.571 1654.04C717.651 1639.8 724.327 1623.95 728.064 1606.95H764.339Z" fill="#F0EDFF"/>
      <path d="M410.575 1606.95H402.674V1540.01H439.712C443.338 1525.07 449.27 1510.77 457.38 1497.45L431.168 1471.24L481.263 1421.15L507.474 1447.35C520.736 1439.25 535.027 1433.32 550.042 1429.69V1392.65H620.876V1429.69C636.892 1433.57 652.119 1440.07 666.186 1449.03L661.938 1455.69C647.751 1446.65 632.32 1440.25 616.078 1436.68L612.975 1436V1400.55H557.943V1436L554.842 1436.68C538.574 1440.27 523.162 1446.65 509.031 1455.69L506.355 1457.4L481.263 1432.32L442.342 1471.24L467.43 1496.32L465.724 1499C456.67 1513.2 450.272 1528.63 446.712 1544.81L446.032 1547.92H410.575V1606.95ZM585.458 1643.16C548.111 1643.16 517.727 1612.79 517.727 1575.44C517.727 1563.57 520.844 1551.89 526.744 1541.67L533.588 1545.63C528.382 1554.64 525.628 1564.95 525.628 1575.44C525.628 1608.43 552.469 1635.27 585.458 1635.27C606.264 1635.27 625.258 1624.72 636.27 1607.07L642.975 1611.24C630.511 1631.23 609.008 1643.16 585.458 1643.16ZM620.876 1758.27H550.042V1721.23C534.002 1717.35 518.787 1710.84 504.774 1701.87L509.032 1695.21C523.158 1704.25 538.571 1710.65 554.842 1714.23L557.943 1714.91V1750.36H612.975V1714.91L616.078 1714.23C632.324 1710.65 647.754 1704.25 661.938 1695.21L664.611 1693.51L689.698 1718.6L731.412 1676.88L736.999 1682.47L689.698 1729.77L663.487 1703.56C650.182 1711.67 635.875 1717.59 620.876 1721.23V1758.27Z" fill="#CCC2FF"/>
      <path d="M2426.44 673.493H2131.39V378.44H2426.44V673.493Z" fill="#E9E6FF"/>
      <path d="M2189.16 615.72V436.227H2368.65V615.72H2189.16Z" fill="white"/>
      <path d="M2278.24 593.067H2236.35L2191.32 493.76L2235.27 473.84L2261.48 531.653C2288.84 495.413 2349.45 425 2449.31 358.373L2476.08 398.507C2345.17 485.853 2285.88 580.653 2285.31 581.6L2278.24 593.067Z" fill="#FFB0BF"/>
      <path d="M2430.39 677.453H2268.96V669.547H2422.48V635.773H2430.39V677.453Z" fill="#CCC2FF"/>
      <path d="M2392.4 1707.43H2130.41V1732.41H2392.4V1707.43Z" fill="#40327A"/>
      <path d="M2797.99 1447.09L2752.45 1668.04L2752.12 1669.67L2739.2 1732.44H2339.31L2398.09 1447.09H2797.99Z" fill="white"/>
      <path d="M2812.17 1447.09L2766.64 1668.04L2766.31 1669.67L2753.4 1732.44H2353.51L2412.28 1447.09H2812.17Z" fill="#C4B8FF"/>
      <path d="M2766.64 1668.04L2766.31 1669.67L2753.4 1732.44H2353.51L2766.64 1668.04Z" fill="#DDD6FF"/>
      <path d="M2611.99 1589.75C2608.68 1605.85 2592.93 1618.91 2576.83 1618.91C2560.72 1618.91 2550.36 1605.85 2553.68 1589.75C2556.99 1573.64 2572.73 1560.59 2588.84 1560.59C2604.95 1560.59 2615.31 1573.64 2611.99 1589.75Z" fill="white"/>
      <path d="M2780.64 1619.71L2772.91 1618.11L2808.31 1446.31L2816.04 1447.89L2780.64 1619.71Z" fill="#7760DB"/>
      <path d="M2840.07 2178.12C2812.6 2178.12 2790.27 2200.45 2790.27 2227.91C2790.27 2255.37 2812.6 2277.71 2840.07 2277.71C2867.52 2277.71 2889.85 2255.37 2889.85 2227.91C2889.85 2200.45 2867.52 2178.12 2840.07 2178.12ZM2840.07 2285.6C2808.25 2285.6 2782.37 2259.72 2782.37 2227.91C2782.37 2196.11 2808.25 2170.21 2840.07 2170.21C2871.87 2170.21 2897.75 2196.11 2897.75 2227.91C2897.75 2259.72 2871.87 2285.6 2840.07 2285.6Z" fill="#40327A"/>
      <path d="M3029.71 2488.67H2650.41V2227.91H3029.71V2488.67Z" fill="#FFB0BF"/>
      <path d="M3029.71 2488.67H2990.2V2227.91H3029.71V2488.67Z" fill="#40327A"/>
      <path d="M2990.2 2362.24H2650.43V2354.33H2990.2V2362.24Z" fill="#40327A"/>
      <path d="M2847.96 2389.89H2792.65V2354.33H2847.96V2389.89Z" fill="white"/>
      <path d="M1187.72 2411.28V2387.59L1490.25 2363.88V2411.28H1187.72Z" fill="#DDD6FF"/>
      <path d="M1533.72 2044.07V2411.28H1486.31V2044.07H1533.72Z" fill="#C4B8FF"/>
      <path d="M1832.28 2411.28V2387.59L1529.76 2363.88V2411.28H1832.28Z" fill="#C4B8FF"/>
      <path d="M1533.72 2055.92V2133.67L1486.31 2128.23V2055.92H1533.72Z" fill="#A796F2"/>
      <path d="M1861.09 1984.47H1665.65C1640.79 1984.47 1620.15 1965.25 1618.37 1940.44L1585.27 1478.29C1583.93 1459.57 1568.32 1445.31 1549.84 1445.31L1549.83 1445.27H1190.56L1190.56 1445.31C1189.7 1445.31 1188.84 1445.29 1187.97 1445.36C1168.38 1446.77 1153.64 1463.79 1155.04 1483.37L1188.12 1945.52C1192.56 2007.53 1244.17 2055.59 1306.34 2055.59H1375.28H1665.6H1665.61H1861.09C1880.73 2055.59 1896.65 2039.67 1896.65 2020.03C1896.65 2000.39 1880.73 1984.47 1861.09 1984.47Z" fill="#E5659B"/>
      <path d="M1259.1 1940.44L1226 1478.29C1224.59 1458.71 1207.57 1443.95 1187.97 1445.36C1168.38 1446.77 1153.64 1463.79 1155.04 1483.37L1188.12 1945.52C1192.56 2007.53 1244.17 2055.59 1306.34 2055.59H1541.33C1560.97 2055.59 1576.89 2039.67 1576.89 2020.03C1576.89 2000.39 1560.97 1984.47 1541.33 1984.47H1306.39C1281.52 1984.47 1260.88 1965.25 1259.1 1940.44Z" fill="#FF95A9"/>
      <path d="M1235.99 2459.04C1235.99 2475.4 1222.73 2488.67 1206.36 2488.67C1189.99 2488.67 1176.73 2475.4 1176.73 2459.04C1176.73 2442.67 1189.99 2429.4 1206.36 2429.4C1222.73 2429.4 1235.99 2442.67 1235.99 2459.04Z" fill="#40327A"/>
      <path d="M1539.64 2459.04C1539.64 2475.4 1526.37 2488.67 1510.01 2488.67C1493.64 2488.67 1480.37 2475.4 1480.37 2459.04C1480.37 2442.67 1493.64 2429.4 1510.01 2429.4C1526.37 2429.4 1539.64 2442.67 1539.64 2459.04Z" fill="#40327A"/>
      <path d="M1843.29 2459.04C1843.29 2475.4 1830.03 2488.67 1813.65 2488.67C1797.29 2488.67 1784.03 2475.4 1784.03 2459.04C1784.03 2442.67 1797.29 2429.4 1813.65 2429.4C1830.03 2429.4 1843.29 2442.67 1843.29 2459.04Z" fill="#40327A"/>
      <path d="M1540.4 1519.49H1276.21V1511.59H1540.4V1519.49Z" fill="#FF95A9"/>
      <path d="M1545.17 1585.95H1280.95V1578.05H1545.17V1585.95Z" fill="#FF95A9"/>
      <path d="M1549.92 1652.36H1285.73V1644.45H1549.92V1652.36Z" fill="#FF95A9"/>
      <path d="M1554.71 1718.77H1290.47V1710.88H1554.71V1718.77Z" fill="#FF95A9"/>
      <path d="M1559.44 1785.19H1295.21V1777.28H1559.44V1785.19Z" fill="#FF95A9"/>
      <path d="M1564.23 1851.64H1299.99V1843.73H1564.23V1851.64Z" fill="#FF95A9"/>
      <path d="M1568.96 1918.05H1304.73V1910.15H1568.96V1918.05Z" fill="#FF95A9"/>
      <path d="M1588.16 1988.41H1306.39C1279.58 1988.41 1257.07 1967.47 1255.16 1940.73L1224.27 1509.41L1232.15 1508.85L1263.04 1940.16C1264.66 1962.79 1283.7 1980.52 1306.39 1980.52H1588.16V1988.41Z" fill="white"/>
      <path d="M1623.12 1988.41H1606.6V1980.52H1623.12V1988.41Z" fill="white"/>
      <path d="M1537.67 2287.49H1529.76V2055.92H1537.67V2287.49Z" fill="#7760DB"/>
      <path d="M1537.67 2315.15H1529.76V2300H1537.67V2315.15Z" fill="#7760DB"/>
      <path d="M1338.99 2415.24H1187.72V2407.33H1338.99V2415.24Z" fill="#7760DB"/>
      <path d="M1391.28 2415.24H1363.63V2407.33H1391.28V2415.24Z" fill="#7760DB"/>
      <path d="M1832.28 2415.24H1454.49V2407.33H1832.28V2415.24Z" fill="#7760DB"/>
      <path d="M1787.17 2059.53H1764.97V2051.63H1787.17V2059.53Z" fill="#7760DB"/>
      <path d="M1683.97 2059.53H1306.34C1242.4 2059.53 1188.74 2009.57 1184.18 1945.8L1165.52 1685.19L1173.4 1684.63L1192.06 1945.24C1196.33 2004.91 1246.53 2051.63 1306.34 2051.63H1683.97V2059.53Z" fill="#7760DB"/>
      <path d="M1161.5 1628.99L1151.1 1483.65C1149.58 1462.43 1165.22 1443.84 1186.21 1441.55L1186.19 1441.36L1190.56 1441.32H1330.89V1449.21L1190.56 1449.25C1189.62 1449.25 1188.94 1449.25 1188.25 1449.29C1170.87 1450.55 1157.74 1465.71 1158.98 1483.09L1169.38 1628.41L1161.5 1628.99Z" fill="#7760DB"/>
      <path d="M1585.53 1537.25L1581.33 1478.57C1580.64 1468.95 1575.67 1460.28 1567.69 1454.8L1572.16 1448.28C1582.13 1455.13 1588.35 1465.96 1589.21 1478.01L1593.41 1536.69L1585.53 1537.25Z" fill="#7760DB"/>
      <path d="M1590.71 1609.43L1585.53 1537.25L1593.41 1536.69L1598.59 1608.87L1590.71 1609.43Z" fill="#7760DB"/>
      <path d="M1601.8 1764.32L1592.11 1628.99L1599.99 1628.43L1609.68 1763.76L1601.8 1764.32Z" fill="#7760DB"/>
      <path d="M1605.13 1810.77L1603.92 1793.96L1611.8 1793.4L1613.01 1810.21L1605.13 1810.77Z" fill="#7760DB"/>
      <path d="M1861.09 2059.53H1805.79V2051.63H1861.09C1878.52 2051.63 1892.71 2037.45 1892.71 2020.03C1892.71 2002.6 1878.52 1988.41 1861.09 1988.41H1665.65C1638.84 1988.41 1616.35 1967.47 1614.44 1940.73L1609.08 1866.03L1616.97 1865.47L1622.32 1940.16C1623.93 1962.79 1642.97 1980.51 1665.65 1980.52H1861.09C1882.88 1980.52 1900.6 1998.24 1900.6 2020.03C1900.6 2041.81 1882.88 2059.53 1861.09 2059.53Z" fill="#7760DB"/>
      <path d="M2840.05 2496.57H2495.76V2488.67H2840.05V2496.57Z" fill="#40327A"/>
      <path d="M3145.03 2496.57H2908.03V2488.67H3145.03V2496.57Z" fill="#40327A"/>
      <path d="M2856.39 1732.44L2860.55 1771.95L2939.12 2488.67H2959.07L2900.09 1732.44H2856.39Z" fill="#DDD6FF"/>
      <path d="M1012.99 1732.44L1008.84 1771.95L930.256 2488.67H910.307L969.291 1732.44H1012.99Z" fill="#DDD6FF"/>
      <path d="M939.336 2442.11L931.48 2441.24L980.47 1994.44L988.326 1995.29L939.336 2442.11Z" fill="#B3A4F5"/>
      <path d="M991.542 1965.97L983.686 1965.11L995.716 1855.39L1003.57 1856.25L991.542 1965.97Z" fill="#B3A4F5"/>
      <path d="M930.457 2281.12L922.58 2280.51L946.697 1971.29L954.576 1971.91L930.457 2281.12Z" fill="white"/>
      <path d="M2982.15 1732.44V1771.95H894.226V1732.44H2982.15Z" fill="#8B77E8"/>
      <path d="M2273.77 1771.95H1773.23L1764.77 1732.44H2259.21L2273.77 1771.95Z" fill="#624AC9"/>
      <path d="M2982.17 1775.89H2413.49V1768H2982.17V1775.89Z" fill="#40327A"/>
      <path d="M2157.27 1775.89H1280.76V1768H2157.27V1775.89Z" fill="#40327A"/>
      <path d="M1160.94 1775.89H894.207V1768H1160.94V1775.89Z" fill="#40327A"/>
      <path d="M1237.38 1775.89H1200.92V1768H1237.38V1775.89Z" fill="#40327A"/>
      <path d="M2927.88 2422.33L2910.89 2267.39L2918.75 2266.52L2935.73 2421.47L2927.88 2422.33Z" fill="white"/>
      <path d="M2909.12 2251.23L2899.08 2159.72L2906.93 2158.85L2916.97 2250.37L2909.12 2251.23Z" fill="white"/>
      <path d="M2415.13 830.733C2400.19 821.707 2380.76 826.52 2371.73 841.467C2370.15 844.093 2369 846.88 2368.25 849.72L2368.23 849.707L2354.43 901.84L2354.73 901H2354.75L2349.21 916.013L2397.05 933.64L2397.07 933.653L2399.67 917.507L2425.85 874.12C2434.88 859.187 2430.08 839.747 2415.13 830.733Z" fill="#FF95A9"/>
      <path d="M2263.87 1147.4L2059.59 1096.43V1096.45C2054.69 1095.24 2049.63 1094.44 2044.4 1094.17C2041.35 1094.01 2038.33 1094.08 2035.35 1094.27C2033.17 1094.36 2030.99 1094.56 2028.79 1094.92L2028.6 1094.95C2028.44 1094.97 2028.29 1095 2028.13 1095.03L1999.04 1099.79L2012.48 1181.89L2012.35 1183.51L1995.36 1388.91V1388.92L1986.13 1367.51L1891.43 1147.79L1879.19 1119.4L1846.48 1124.75C1814.65 1104.95 1772.27 1110.05 1746.24 1138.85C1739.48 1146.32 1734.51 1154.75 1731.11 1163.63L1731.05 1163.59L1643.92 1391.35L1647.61 1392.75L1576.87 1577.63L1573.19 1576.21L1545.72 1647.96L1600.99 1669.09L1653.65 1584.43L1802.31 1345.55L1849.15 1563.95L1853 1563.11L1909.11 1824.79L1905.24 1825.61L1934.51 1962.03L1997.03 2253.52L2054.67 2130.51L2024.68 1947.28C2024.68 1947.27 2024.68 1947.27 2024.68 1947.27V1947.25L1984.52 1701.88C1995.59 1702.68 2006.91 1702.25 2018.32 1700.37C2036.29 1697.44 2052.91 1691.27 2067.73 1682.6L2076.55 1703.32L2080.19 1701.77L2096.63 1740.43L2092.99 1741.97L2101.79 1762.65L2105.41 1761.11L2167.49 1907.08L2162.81 1909.08L2087.59 2069.59L2091.16 2071.27L1984.13 2299.63L1980.57 2297.96L1971.13 2318.09L1961.25 2339.17L1964.83 2340.85L1952.01 2368.19L1948.43 2366.51L1936.01 2393L1999.41 2422.71L2023.36 2376.35L2039.64 2452.21V2449.11H2106.28C2106.28 2448.6 2106.31 2448.09 2106.28 2447.57C2106.23 2446.27 2106.07 2444.99 2105.87 2443.73L2105.93 2443.72L2077.68 2271.13L2252.91 1931.75C2253.6 1930.53 2254.28 1929.31 2254.88 1928.01C2260.61 1915.77 2260.41 1902.31 2255.51 1890.77L2255.56 1890.76L2142.89 1584.87L2138.8 1548.85C2138.49 1544.24 2137.97 1539.61 2137.21 1534.96L2105.45 1255.53L2119.91 1256.28L2120.11 1252.33L2247.36 1258.84L2247.15 1262.79L2294.6 1265.21C2294.64 1265.21 2294.69 1265.21 2294.73 1265.21L2294.99 1265.23C2322.12 1266.64 2346.53 1247.41 2350.95 1219.99C2350.97 1219.85 2350.97 1219.72 2350.99 1219.6L2393.21 957.453L2341.01 938.24L2263.87 1147.4Z" fill="#7760DB"/>
      <path d="M2349.21 916.013L2354.75 901H2354.73L2354.43 901.84L2349.19 916.027L2341.01 938.24L2393.21 957.453L2397.05 933.64L2349.21 916.013Z" fill="white"/>
      <path d="M1879.19 1119.4L1891.43 1147.79L1986.13 1367.51L1947.4 1277.63L1948.52 1127.19L1956.32 1125.92L2005.33 1268.15L1995.36 1388.91L2012.35 1183.51L2012.48 1181.89L1999.04 1099.79L1976.67 1103.45L1879.19 1119.4Z" fill="white"/>
      <path d="M1956.32 1125.92L1948.52 1127.19L1947.4 1277.63L1986.13 1367.51L1995.36 1388.92V1388.91L2005.33 1268.15L1956.32 1125.92Z" fill="#40327A"/>
      <path d="M2039.64 2449.11V2488.61H2139.91C2145.39 2488.61 2149.83 2484.17 2149.83 2478.69C2149.83 2475.07 2147.84 2471.73 2144.65 2469.99L2106.36 2449.11H2106.28H2039.64Z" fill="#40327A"/>
      <path d="M1879.19 1119.4L1976.67 1103.45L1970.8 1067.63L1969.49 1067.95C1938.69 1075.56 1907.55 1056.77 1899.92 1025.99L1883.77 960.707L1882.36 954.973C1879.51 943.427 1867.83 936.373 1856.28 939.24C1844.72 942.093 1837.68 953.773 1840.53 965.32C1843.39 976.88 1855.08 983.92 1866.63 981.067L1866.64 981.053L1878.88 1030.57L1875.4 1031.43C1871.89 1032.29 1868.39 1032.6 1864.96 1032.43L1879.19 1119.4Z" fill="#FF95A9"/>
      <path d="M2019.05 971.947C2022.03 983.573 2015.01 995.413 2003.39 998.387C1991.76 1001.36 1979.93 994.36 1976.95 982.733L2019.05 971.947ZM1875.4 1031.43L1878.88 1030.57L1866.64 981.053L1866.63 981.067C1855.08 983.92 1843.39 976.88 1840.53 965.32C1837.68 953.773 1844.72 942.093 1856.28 939.24C1867.83 936.373 1879.51 943.427 1882.36 954.973L1883.77 960.707L1899.92 1025.99C1907.55 1056.77 1938.69 1075.56 1969.49 1067.95L1970.8 1067.63L2024.91 1054.23C2040.85 1050.28 2050.25 1033.8 2045.55 1018.07L2025.25 950.36L1933.67 973.027C1920.96 976.173 1908.13 968.52 1904.79 955.92L1884.12 872.4C1897.75 881.413 1914.91 884.987 1931.99 880.76L1996.37 864.827C2000.31 870.933 2003.48 877.667 2005.67 884.96L2010.97 902.707C2032.03 893.333 2044.01 869.987 2038.28 846.84C2034.48 831.467 2023.71 819.693 2010.17 813.92C2004.55 796.44 1988.59 784.787 1970.84 784.12C1967.64 783.8 1964.35 783.973 1961.05 784.787L1958.92 785.32C1958.91 785.32 1958.89 785.32 1958.87 785.333L1925.03 793.707L1921.55 794.573C1914.09 796.413 1908.08 801.027 1904.25 807.027C1897.55 801.827 1888.63 799.627 1879.76 801.813C1866.45 805.107 1857.65 817.173 1857.95 830.32C1822.08 841.627 1800.68 878.96 1809.84 915.973L1825.05 977.48L1831.92 1005.2C1835.81 1020.95 1849.55 1031.65 1864.96 1032.43C1868.39 1032.6 1871.89 1032.29 1875.4 1031.43Z" fill="#40327A"/>
      <path d="M2005.67 884.96C2003.48 877.667 2000.31 870.934 1996.37 864.827L1931.99 880.76C1914.91 884.987 1897.75 881.414 1884.12 872.4L1904.79 955.92C1908.13 968.52 1920.96 976.174 1933.67 973.027L2025.25 950.36L2010.97 902.707L2005.67 884.96Z" fill="#FF95A9"/>
      <path d="M2003.39 998.387C2015.01 995.413 2022.03 983.573 2019.05 971.947L1976.95 982.733C1979.93 994.36 1991.76 1001.36 2003.39 998.387Z" fill="white"/>
      <path d="M1913.33 2441.44C1911.16 2446.09 1910.13 2451 1910.13 2455.81C1910.13 2468.57 1917.36 2480.83 1929.69 2486.64C1946.24 2494.37 1965.92 2487.67 1974.29 2471.43L1999.41 2422.71L1936.01 2393L1913.33 2441.44Z" fill="#40327A"/>
      <path d="M2091.16 2071.27L2087.59 2069.59L2084.01 2067.91L2054.67 2130.51L1997.03 2253.52L1976.99 2296.28L1980.57 2297.96L1984.13 2299.63L2091.16 2071.27Z" fill="#40327A"/>
      <path d="M1964.83 2340.85L1961.25 2339.17L1957.67 2337.49L1944.85 2364.83L1948.43 2366.51L1952.01 2368.19L1964.83 2340.85Z" fill="#40327A"/>
      <path d="M2160.21 1910.17L2162.81 1909.08L2167.49 1907.08L2105.41 1761.11L2101.79 1762.65L2098.15 1764.2L2160.21 1910.17Z" fill="#40327A"/>
      <path d="M2089.35 1743.52L2092.99 1741.97L2096.63 1740.43L2080.19 1701.77L2076.55 1703.32L2072.92 1704.87L2089.35 1743.52Z" fill="#40327A"/>
      <path d="M2120.11 1252.33L2119.91 1256.28L2119.71 1260.23L2246.95 1266.73L2247.15 1262.79L2247.36 1258.84L2120.11 1252.33Z" fill="#40327A"/>
      <path d="M1570.84 1717.61L1588.19 1689.68L1570.83 1717.61H1570.84Z" fill="white"/>
      <path d="M1537.23 1670.2L1588.19 1689.68L1600.99 1669.09L1545.72 1647.96L1537.23 1670.2Z" fill="white"/>
      <path d="M1537.23 1670.2L1536.88 1671.08C1536.08 1671.24 1535.28 1671.41 1534.47 1671.65L1481.39 1687.07C1468.81 1690.71 1461.59 1703.87 1465.24 1716.44C1468.24 1726.79 1477.69 1733.51 1487.96 1733.52V1733.53L1543.23 1733.61V1733.6C1546.16 1733.6 1549.13 1733.21 1552.09 1732.36C1560.33 1729.96 1566.83 1724.51 1570.75 1717.6L1570.83 1717.63V1717.61L1588.19 1689.68L1537.23 1670.2Z" fill="#FF95A9"/>
      <path d="M1853 1563.11L1849.15 1563.95L1845.28 1564.77L1901.39 1826.44L1905.24 1825.61L1909.11 1824.79L1853 1563.11Z" fill="#40327A"/>
      <path d="M1647.61 1392.75L1643.92 1391.35L1640.23 1389.93L1569.49 1574.8L1573.19 1576.21L1576.87 1577.63L1647.61 1392.75Z" fill="#40327A"/>
      <path d="M2267.04 2496.57H1716.89V2488.67H2267.04V2496.57Z" fill="#40327A"/>
      <path d="M834.871 2496.57H761.78V2488.67H834.871V2496.57Z" fill="#40327A"/>
      <path d="M1574.89 2496.57H1271.44V2488.67H1574.89V2496.57Z" fill="#40327A"/>
      <path d="M1242.92 2555.85H676.837V2547.96H1242.92V2555.85Z" fill="#C4B8FF"/>
      <path d="M3627.28 1134.48L3638.41 1387.56L3542.25 1371.13L3624.8 1138.19C3624.87 1137.95 3624.56 1137.79 3624.41 1137.99L3465.8 1358.05L3390.75 1345.24L3625.91 1133.88C3626.4 1133.41 3627.25 1133.76 3627.28 1134.48Z" fill="#DDD6FF"/>
      <path d="M3624.8 1138.19L3542.25 1371.13L3519.45 1435.45C3517.63 1440.59 3510.59 1441.13 3508 1436.33L3465.81 1358.08L3465.8 1358.05L3624.41 1137.99C3624.56 1137.79 3624.87 1137.95 3624.8 1138.19Z" fill="#CCC2FF"/>
      <path d="M3563.84 1228.79L3557.43 1224.17L3568.15 1209.28L3574.56 1213.91L3563.84 1228.79Z" fill="white"/>
      <path d="M3469.01 1360.37L3462.6 1355.75L3546.4 1239.47L3552.81 1244.09L3469.01 1360.37Z" fill="white"/>
      <path d="M3642.57 1392.28L3541.6 1375.03L3542.92 1367.24L3634.25 1382.84L3623.33 1134.65L3631.23 1134.31L3642.57 1392.28Z" fill="#CCC2FF"/>
      <path d="M768.29 1610.89H728.064V1603H760.388V1543.97H768.29V1610.89Z" fill="#CCC2FF"/>
      <path d="M3306.6 1832.64H3298.69V1810.48H3306.6V1832.64Z" fill="#DDD6FF"/>
      <path d="M3306.6 1965.63H3298.69V1943.45H3306.6V1965.63Z" fill="#DDD6FF"/>
      <path d="M3271.52 1842.04L3260.44 1822.85L3267.28 1818.89L3278.36 1838.09L3271.52 1842.04Z" fill="#DDD6FF"/>
      <path d="M3338.01 1957.2L3326.92 1938.01L3333.77 1934.07L3344.85 1953.25L3338.01 1957.2Z" fill="#DDD6FF"/>
      <path d="M3252.68 1863.77L3233.49 1852.68L3237.44 1845.84L3256.64 1856.93L3252.68 1863.77Z" fill="#DDD6FF"/>
      <path d="M3367.84 1930.25L3348.65 1919.17L3352.6 1912.33L3371.8 1923.41L3367.84 1930.25Z" fill="#DDD6FF"/>
      <path d="M3247.24 1892H3225.08V1884.11H3247.24V1892Z" fill="#DDD6FF"/>
      <path d="M3380.21 1892H3358.05V1884.11H3380.21V1892Z" fill="#DDD6FF"/>
      <path d="M3237.44 1930.25L3233.49 1923.41L3252.68 1912.33L3256.64 1919.17L3237.44 1930.25Z" fill="#DDD6FF"/>
      <path d="M3352.6 1863.77L3348.65 1856.93L3367.84 1845.84L3371.8 1852.68L3352.6 1863.77Z" fill="#DDD6FF"/>
      <path d="M3267.28 1957.2L3260.44 1953.25L3271.52 1934.07L3278.36 1938.01L3267.28 1957.2Z" fill="#DDD6FF"/>
      <path d="M3333.77 1842.04L3326.92 1838.09L3338.01 1818.89L3344.85 1822.85L3333.77 1842.04Z" fill="#DDD6FF"/>
      <path d="M3030.73 1628.71C3049.08 1635.37 3063.67 1649.96 3070.35 1668.32C3077.01 1649.96 3091.6 1635.37 3109.95 1628.71C3091.6 1622.04 3077.01 1607.44 3070.35 1589.09C3063.67 1607.44 3049.08 1622.04 3030.73 1628.71ZM3074.29 1690.84H3066.39C3066.39 1658.75 3040.29 1632.65 3008.21 1632.65V1624.76C3040.29 1624.76 3066.39 1598.65 3066.39 1566.57H3074.29C3074.29 1598.65 3100.39 1624.76 3132.47 1624.76V1632.65C3100.39 1632.65 3074.29 1658.75 3074.29 1690.84Z" fill="#DDD6FF"/>
      <path d="M2495.76 1137.92C2486.25 1137.92 2478.52 1145.65 2478.52 1155.16C2478.52 1164.65 2486.25 1172.39 2495.76 1172.39C2505.25 1172.39 2512.99 1164.65 2512.99 1155.16C2512.99 1145.65 2505.25 1137.92 2495.76 1137.92ZM2495.76 1180.29C2481.89 1180.29 2470.63 1169.01 2470.63 1155.16C2470.63 1141.29 2481.89 1130.03 2495.76 1130.03C2509.61 1130.03 2520.89 1141.29 2520.89 1155.16C2520.89 1169.01 2509.61 1180.29 2495.76 1180.29Z" fill="#DDD6FF"/>
      <path d="M1352.6 1267.49C1343.11 1267.49 1335.37 1275.21 1335.37 1284.72C1335.37 1294.23 1343.11 1301.95 1352.6 1301.95C1362.11 1301.95 1369.84 1294.23 1369.84 1284.72C1369.84 1275.21 1362.11 1267.49 1352.6 1267.49ZM1352.6 1309.85C1338.75 1309.85 1327.47 1298.57 1327.47 1284.72C1327.47 1270.85 1338.75 1259.59 1352.6 1259.59C1366.47 1259.59 1377.73 1270.85 1377.73 1284.72C1377.73 1298.57 1366.47 1309.85 1352.6 1309.85Z" fill="#DDD6FF"/>
      <path d="M1984.51 252.307H1976.61V230.133H1984.51V252.307Z" fill="#DDD6FF"/>
      <path d="M1984.51 385.28H1976.61V363.12H1984.51V385.28Z" fill="#DDD6FF"/>
      <path d="M1949.44 261.707L1938.36 242.507L1945.2 238.56L1956.28 257.747L1949.44 261.707Z" fill="#DDD6FF"/>
      <path d="M2015.93 376.866L2004.84 357.666L2011.69 353.72L2022.77 372.907L2015.93 376.866Z" fill="#DDD6FF"/>
      <path d="M1930.6 283.427L1911.41 272.347L1915.36 265.507L1934.55 276.587L1930.6 283.427Z" fill="#DDD6FF"/>
      <path d="M2045.76 349.92L2026.57 338.84L2030.52 331.987L2049.72 343.08L2045.76 349.92Z" fill="#DDD6FF"/>
      <path d="M1925.16 311.667H1902.99V303.76H1925.16V311.667Z" fill="#DDD6FF"/>
      <path d="M2058.13 311.667H2035.97V303.76H2058.13V311.667Z" fill="#DDD6FF"/>
      <path d="M1915.36 349.92L1911.41 343.08L1930.6 331.987L1934.55 338.84L1915.36 349.92Z" fill="#DDD6FF"/>
      <path d="M2030.52 283.427L2026.57 276.587L2045.76 265.507L2049.72 272.347L2030.52 283.427Z" fill="#DDD6FF"/>
      <path d="M1945.2 376.866L1938.36 372.907L1949.44 353.72L1956.28 357.666L1945.2 376.866Z" fill="#DDD6FF"/>
      <path d="M2011.69 261.707L2004.84 257.747L2015.92 238.56L2022.77 242.507L2011.69 261.707Z" fill="#DDD6FF"/>
      <path d="M1717.31 421.733C1735.65 428.4 1750.24 442.987 1756.91 461.347C1763.59 442.987 1778.17 428.4 1796.52 421.733C1778.17 415.067 1763.59 400.467 1756.91 382.12C1750.24 400.467 1735.65 415.067 1717.31 421.733ZM1760.87 483.853H1752.96C1752.96 451.773 1726.87 425.68 1694.79 425.68V417.787C1726.87 417.787 1752.96 391.68 1752.96 359.6H1760.87C1760.87 391.68 1786.96 417.787 1819.04 417.787V425.68C1786.96 425.68 1760.87 451.773 1760.87 483.853Z" fill="#DDD6FF"/>
      <path d="M3507.71 1875.63C3490.16 1875.63 3475.88 1889.91 3475.88 1907.45C3475.88 1925 3490.16 1939.27 3507.71 1939.27C3525.25 1939.27 3539.52 1925 3539.52 1907.45C3539.52 1889.91 3525.25 1875.63 3507.71 1875.63ZM3507.71 1947.17C3485.8 1947.17 3467.99 1929.35 3467.99 1907.45C3467.99 1885.55 3485.8 1867.72 3507.71 1867.72C3529.6 1867.72 3547.43 1885.55 3547.43 1907.45C3547.43 1929.35 3529.6 1947.17 3507.71 1947.17Z" fill="#DDD6FF"/>
      <path d="M3238.01 2098.75H3161.64V2090.84H3238.01V2098.75Z" fill="#DDD6FF"/>
      <path d="M3203.77 2132.99H3195.88V2056.6H3203.77V2132.99Z" fill="#DDD6FF"/>
      <path d="M3043.01 1981.57H2999.25V1973.67H3043.01V1981.57Z" fill="#DDD6FF"/>
      <path d="M3025.09 1999.51H3017.19V1955.75H3025.09V1999.51Z" fill="#DDD6FF"/>
      <path d="M541.696 1925.25H497.935V1917.35H541.696V1925.25Z" fill="#DDD6FF"/>
      <path d="M523.766 1943.17H515.864V1899.41H523.766V1943.17Z" fill="#DDD6FF"/>
      <path d="M2213.2 792.813H2169.44V784.92H2213.2V792.813Z" fill="#DDD6FF"/>
      <path d="M2195.27 810.747H2187.37V766.987H2195.27V810.747Z" fill="#DDD6FF"/>
      </g>
      <defs>
      <clipPath id="clip0_937_3221">
      <rect width="4000" height="2797.04" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
  );
};

export default UpdateSkillsSVG;
