import { ApiPaginationResponse, ApiResponse } from '../../types/api';
import {
  FetchMasterSkillResponse,
  FetchSkillsByCateogoryResponse,
  MasterJobRoles,
  Skill,
  SkillCategory,
  SkillDetail,
  SkillSourceModel,
} from '../../types/skills';
import axiosInstance from '../axios';

class SkillsService {
  getSkills(include_deleted?: boolean): Promise<ApiResponse<Skill[]>> {
    let query = include_deleted ? `?include_archived=true` : '';
    return axiosInstance.get(`/api/skills/${query}`);
  }

  getSkill(id: string): Promise<ApiResponse<SkillDetail>> {
    return axiosInstance.get(`/api/skills/${id}`);
  }
  //TODO see what's actually required to make a new skill
  createSkill(body: any): Promise<ApiResponse<Skill>> {
    return axiosInstance.post(`/api/skills`, body);
  }
  //TODO double check this below is same as above
  createMultipleSkills(body: Skill[]): Promise<ApiResponse<any>> {
    return axiosInstance.post('/api/skills/create/createSkills', body);
  }
  updateSkill(id: string, body: any) {
    return axiosInstance.put(`/api/skills/${id}`, body);
  }

  deleteSkill(id: string) {
    return axiosInstance.delete(`/api/skills/${id}`);
  }

  deleteMultipleSkills(body: Skill[]) {
    return axiosInstance.delete(`/api/skills/delete/deleteSkills`, {
      data: body,
    });
  }

  fetchSkillCategories(): Promise<ApiResponse<SkillCategory[]>> {
    return axiosInstance.get('/api/skillcategory');
  }
  // UpdateSkillCateogryDTO
  updateSkillCategories(body: any): Promise<ApiResponse<SkillCategory>> {
    return axiosInstance.put('/api/skillcategory/categories/update', body);
  }

  // update the organization's default skill category
  updateDefaultSkillCetegory(
    category_id: number,
  ): Promise<ApiResponse<SkillCategory>> {
    return axiosInstance.patch('/api/skillcategory/default', {
      category_id: category_id,
    });
  }

  //TODO confirm return type
  //TODO what are the differences between two api calls below?
  fetchMasterSkills(): Promise<ApiResponse<Skill[]>> {
    return axiosInstance.get('/api/masterskills');
  }

  fetchActiveMasterSkills(): Promise<ApiResponse<FetchMasterSkillResponse[]>> {
    return axiosInstance.get(' /api/masterskills/masterskill/active');
  }

  fetchMasterSkillCategories(): Promise<ApiResponse<SkillSourceModel[]>> {
    return axiosInstance.get('/api/masterskills/masterskillcategory/active');
  }
  //admin

  //should this be in what service?
  getJobRoles(): Promise<ApiResponse<MasterJobRoles[]>> {
    return axiosInstance.get('/api/masterskills/masterjoboles/active');
  }

  getMasterSkillsByIndustry(
    name: string,
  ): Promise<ApiResponse<SkillSourceModel[]>> {
    return axiosInstance.get(
      `/api/masterskills/getMasterSkillsByIndustry/${name}`,
    );
  }

  getSkillsByCategory(
    user_id: number,
  ): Promise<ApiResponse<FetchSkillsByCateogoryResponse[]>> {
    return axiosInstance.get(`/api/skills/by_category/${user_id}`);
  }

  getSkillsAndProficienciesByRelType(
    user_id: number,
    limit = 25,
    page?: number,
    asc?: boolean,
    order_by?: string,
  ): Promise<ApiResponse<ApiPaginationResponse<any[]>>> {
    let queryString = `?limit=${limit}`;

    if (asc) {
      queryString += `&asc=${asc}`;
    }
    if (page) {
      queryString += `&page=${page}`;
    }
    if (order_by) {
      queryString += `&order_by=${order_by}`;
    }

    return axiosInstance.get(
      `/api/skills/gap_by_reltype/${user_id}` + queryString,
    );
  }

  getPlansThatShareSkills(
    skill_name: string,
    skill2_name: string,
  ): Promise<ApiResponse<any[]>> {
    return axiosInstance.get(`/api/skills/common_plans`, {
      params: { skill_one: skill_name, skill_two: skill2_name },
    });
  }
}

export const skillsService = new SkillsService();
