
const IncompleteSurveyManagerSVG = ({ size = 'xs' }) => {
  const sizeMap = {
    xs: 0.05,
    sm: 0.075,
    md: 0.1,
    lg: 0.2,
  };
  //@ts-ignore
  const width = sizeMap[size] * 4000;
  //@ts-ignore
  const height = sizeMap[size] * 2346;
  return (
    <svg width={width} height={height} viewBox="0 0 4000 2346" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4000 2345.09H0V0H4000V2345.09Z" fill="#FAF9FF"/>
    <path d="M2689.72 1463.4V1939.41H813.678V1755.44L1125.33 1706.91L2668.23 1466.75L2689.72 1463.4Z" fill="#E9E6FF"/>
    <path d="M2900.87 1430.52V1939.41H2828.32L2803.08 1777.25L2752.71 1453.59L2900.87 1430.52Z" fill="#DDD6FF"/>
    <path d="M1761.49 1939.41L2689.72 1794.91V1939.41H1761.49Z" fill="#F2F0FF"/>
    <path d="M3130.27 1726.33V1939.41H2828.32L2803.08 1777.25L2900.87 1762.04L3130.27 1726.33Z" fill="#E9E6FF"/>
    <path d="M2689.15 1746.75V1939.41H1104.41V1746.75H2689.15Z" fill="#D6CEFF"/>
    <path d="M1104.41 1746.73V429.214H2689.15V1746.73H1104.41Z" fill="#FAF9FF"/>
    <path d="M384.306 1964.72V1990.87H3524.19V1964.72H384.306Z" fill="#B3A4F5"/>
    <path d="M3354.8 2045.13H2783.48V2018.99H3354.8V2045.13Z" fill="#DDD6FF"/>
    <path d="M2760.63 2045.13H2701.79V2018.99H2760.63V2045.13Z" fill="#CCC2FF"/>
    <path d="M1455.44 2084.36H706.84V2071.28H1455.44V2084.36Z" fill="#E9E6FF"/>
    <path d="M1489.55 2084.36H1475.91V2071.28H1489.55V2084.36Z" fill="#CCC2FF"/>
    <path d="M2228.41 2084.36H1516.35V2071.28H2228.41V2084.36Z" fill="#E9E6FF"/>
    <path d="M2524.76 2084.36H2263.37V2071.28H2524.76V2084.36Z" fill="#DDD6FF"/>
    <path d="M2988.76 2084.36H2542.25V2071.28H2988.76V2084.36Z" fill="#DDD6FF"/>
    <path d="M3036.73 2084.36H3021.47V2071.28H3036.73V2084.36Z" fill="#E9E6FF"/>
    <path d="M2686.15 2045.13H2448.24V2018.99H2686.15V2045.13Z" fill="#DDD6FF"/>
    <path d="M2421.33 2045.13H1792.09V2018.99H2421.33V2045.13Z" fill="#CCC2FF"/>
    <path d="M1769.51 2045.13H1329.67V2018.99H1769.51V2045.13Z" fill="#DDD6FF"/>
    <path d="M1314.92 2045.13H1292.57V2018.99H1314.92V2045.13Z" fill="#CCC2FF"/>
    <path d="M1275.85 2045.13H519.936V2018.99H1275.85V2045.13Z" fill="#DDD6FF"/>
    <path d="M2692.32 1375.87H2687.09V1009.69H2692.32V1375.87Z" fill="#624AC9"/>
    <path d="M1829.73 1746.75L1104.41 1859.64V1746.75H1829.73Z" fill="#B3A4F5"/>
    <path d="M2803.08 1777.25L2689.72 1794.91V1463.39L2752.71 1453.59L2803.08 1777.25Z" fill="#B3A4F5"/>
    <path d="M2828.32 1939.41H2689.72V1794.91L2803.08 1777.25L2828.32 1939.41Z" fill="#DDD6FF"/>
    <path d="M1767.69 1939.41L2689.15 1795.99V1939.41H1767.69Z" fill="#FAF9FF"/>
    <path d="M1107.03 753.053H1101.8V426.6H1567.73V431.827H1107.03V753.053Z" fill="#624AC9"/>
    <path d="M1107.03 1812.11H1101.8V1459.15H1107.03V1812.11Z" fill="#624AC9"/>
    <path d="M1730.57 431.827H1667.05V426.6H1730.57V431.827Z" fill="#624AC9"/>
    <path d="M2689.72 431.827H1756.05V426.6H2689.72V431.827Z" fill="#624AC9"/>
    <path d="M3403.92 1969.95H2764.33V1964.72H3403.92V1969.95Z" fill="#4F37B3"/>
    <path d="M2691.76 741.987H2686.53V429.214H2691.76V741.987Z" fill="#624AC9"/>
    <path d="M1107.03 1234.69H1101.8V1186.66H1107.03V1234.69Z" fill="#624AC9"/>
    <path d="M2692.32 1489.2H2687.09V1431.68H2692.32V1489.2Z" fill="#624AC9"/>
    <path d="M2691.76 1939.41H2686.53V1659.6H2691.76V1939.41Z" fill="#624AC9"/>
    <path d="M2668.23 1466.75V1725.81H1125.33V1706.91L2668.23 1466.75Z" fill="#E9E6FF"/>
    <path d="M2297.03 450.12L1125.33 632.507V450.12H2297.03Z" fill="#F2F0FF"/>
    <path d="M2006.64 821.04H1786.92V689.027H2006.64V821.04Z" fill="#E9E6FF"/>
    <path d="M1752.24 821.04H1532.53V689.027H1752.24V821.04Z" fill="#E9E6FF"/>
    <path d="M2261.03 821.04H2041.32V689.027H2261.03V821.04Z" fill="#E9E6FF"/>
    <path d="M2515.43 821.04H2295.71V689.027H2515.43V821.04Z" fill="#FFCEE4"/>
    <path d="M1497.85 987.507H1278.14V855.493H1497.85V987.507Z" fill="#E9E6FF"/>
    <path d="M1752.24 987.507H1532.53V855.493H1752.24V987.507Z" fill="#E9E6FF"/>
    <path d="M2006.64 987.507H1786.92V855.493H2006.64V987.507Z" fill="#E9E6FF"/>
    <path d="M2261.03 987.507H2041.32V855.493H2261.03V987.507Z" fill="#E9E6FF"/>
    <path d="M2515.43 987.507H2295.71V855.493H2515.43V987.507Z" fill="#FFCEE4"/>
    <path d="M1497.85 1153.98H1278.14V1021.97H1497.85V1153.98Z" fill="#E9E6FF"/>
    <path d="M1752.24 1153.98H1532.53V1021.97H1752.24V1153.98Z" fill="#E9E6FF"/>
    <path d="M2006.64 1153.98H1786.92V1021.97H2006.64V1153.98Z" fill="#E9E6FF"/>
    <path d="M2261.03 1153.98H2041.32V1021.97H2261.03V1153.98Z" fill="#E9E6FF"/>
    <path d="M2515.43 1153.98H2295.71V1021.97H2515.43V1153.98Z" fill="#FFCEE4"/>
    <path d="M1497.85 1320.45H1278.14V1188.44H1497.85V1320.45Z" fill="#E9E6FF"/>
    <path d="M1752.24 1320.45H1532.53V1188.44H1752.24V1320.45Z" fill="#E9E6FF"/>
    <path d="M2006.64 1320.45H1786.92V1188.44H2006.64V1320.45Z" fill="#E9E6FF"/>
    <path d="M2261.03 1320.45H2041.32V1188.44H2261.03V1320.45Z" fill="#E9E6FF"/>
    <path d="M2515.43 1320.45H2295.71V1188.44H2515.43V1320.45Z" fill="#FFCEE4"/>
    <path d="M1497.85 1486.92H1278.14V1354.91H1497.85V1486.92Z" fill="#E9E6FF"/>
    <path d="M1752.24 1486.92H1532.53V1354.91H1752.24V1486.92Z" fill="#E9E6FF"/>
    <path d="M2006.64 1486.92H1786.92V1354.91H2006.64V1486.92Z" fill="#E9E6FF"/>
    <path d="M1031.07 1826.04L1004.38 1964.72H775.401L762.276 1896.59L748.733 1826.04H1031.07Z" fill="#FAF9FF"/>
    <path d="M1025.77 1826.04L1019.91 1856.48L999.072 1964.72H775.401L762.276 1896.59L748.733 1826.04H1025.77Z" fill="#FFE159"/>
    <path d="M1025.76 1826.05L1019.91 1856.49L762.287 1896.6L748.726 1826.05H1025.76Z" fill="#FFC359"/>
    <path d="M453.085 865.587L519.113 847.88C571.6 833.8 626.564 842.4 671.484 869.8L682.58 945.187C683.374 950.534 688.358 954.253 693.726 953.467C699.084 952.667 702.797 947.68 702.009 942.32L693.65 885.547C702.382 892.707 710.552 900.694 718.065 909.454L722.692 914.84H722.694L741.961 1045.74C742.758 1051.09 747.74 1054.81 753.109 1054.02C758.465 1053.22 762.178 1048.23 761.392 1042.87L746.658 942.827L859.96 1075.07L793.942 1092.78C721.674 1112.17 644.728 1088.57 595.756 1032.09L627.52 1022.88C632.669 1021.38 635.653 1016.06 634.272 1010.91L634.218 1010.71C632.709 1005.51 627.26 1002.51 622.056 1004.03L581.717 1015.72L453.085 865.587Z" fill="#A292ED"/>
    <path d="M760.173 720.827C760.701 716.373 764.733 713.2 769.178 713.72L782.578 715.32C787.025 715.84 790.201 719.88 789.673 724.32C789.322 727.28 787.426 729.667 784.889 730.787C783.606 731.36 782.16 731.6 780.668 731.413L767.268 729.827C762.821 729.293 759.645 725.267 760.173 720.827ZM753.754 775.36L731.158 772.68L730.214 777.107C728.09 787.08 727.226 796.867 727.452 806.373L787.473 813.493C791.92 814.027 795.096 818.053 794.568 822.507C794.217 825.453 792.32 827.853 789.782 828.973C788.5 829.533 787.054 829.773 785.561 829.6L728.981 822.88C730.405 831.613 732.773 840.027 735.968 848.013L825.134 858.6C829.58 859.12 832.756 863.16 832.229 867.6C831.877 870.56 829.981 872.947 827.444 874.067C826.161 874.64 824.714 874.88 823.222 874.693L744.58 865.36C774.472 915.16 838.724 941.893 899.773 914.96L765.63 610.853L734.55 756.76L755.666 759.267C760.112 759.787 763.288 763.827 762.761 768.267C762.409 771.227 760.513 773.613 757.976 774.733C756.693 775.307 755.246 775.547 753.754 775.36Z" fill="#CCC2FF"/>
    <path d="M1208.55 881.32C1212.99 882.013 1216.01 886.173 1215.32 890.627C1214.6 895.053 1210.43 898.093 1206 897.373L1171.28 891.853C1170.11 891.68 1169.03 891.24 1168.12 890.64C1166.02 889.28 1164.63 886.987 1164.45 884.453C1164.39 883.84 1164.41 883.2 1164.51 882.547C1165 879.493 1167.13 877.107 1169.85 876.147C1171.08 875.693 1172.45 875.56 1173.84 875.773L1208.55 881.32ZM1250 753.053L1006.08 890.68L1061.02 975.6L1063.86 979.987C1066.3 983.773 1065.23 988.787 1061.46 991.227C1058.68 993.04 1055.21 992.92 1052.6 991.227C1051.67 990.613 1050.86 989.827 1050.2 988.827L1039.42 972.173L991.888 898.693L955.812 919.053C955.574 919.2 955.355 919.32 955.116 919.453L992.484 977.173C994.926 980.947 993.834 985.987 990.062 988.427C987.282 990.24 983.807 990.12 981.206 988.413C980.274 987.813 979.459 987 978.804 986.027L941.754 928.733C936.155 933.373 931.191 938.56 926.923 944.173L1021.81 1103.47L988.255 1155L1059.22 1166.29L1107.5 1173.98C1155.91 1181.68 1201.16 1151.48 1214.01 1106.01L1083.66 1085.27C1082.49 1085.07 1081.41 1084.65 1080.5 1084.05C1077.88 1082.35 1076.37 1079.21 1076.91 1075.93C1077.6 1071.51 1081.77 1068.49 1086.22 1069.18L1217.08 1090.01C1217.1 1089.75 1217.14 1089.51 1217.16 1089.26L1219.07 1069.64L1060.29 1044.36L1100.93 981.96L1225.7 1001.81L1230.49 952.787L1133.89 937.4C1132.74 937.227 1131.67 936.813 1130.73 936.187C1128.13 934.507 1126.62 931.373 1127.14 928.093C1127.85 923.667 1132.02 920.627 1136.45 921.347L1232.07 936.573L1239.2 863.547L1183.81 854.707L1250 753.053Z" fill="#B3A4F5"/>
    <path d="M1021.81 1103.47L988.255 1155L922.674 1053.64C900.138 1018.81 903.355 975.067 926.923 944.173L1021.81 1103.47Z" fill="#8B77E8"/>
    <path d="M1225.7 1001.81L1219.07 1069.64L1060.29 1044.36L1100.93 981.96L1225.7 1001.81Z" fill="#CCC2FF"/>
    <path d="M843.273 1228.58L797.567 1109.51L678.456 1063.92C630.109 1045.42 576.76 1066.76 553.569 1110.96L620.877 1136.73C622.048 1137.17 623.061 1137.84 623.875 1138.67C626.219 1141 627.092 1144.55 625.841 1147.83C624.133 1152.26 619.169 1154.48 614.741 1152.77L546.897 1126.82C546.817 1127.08 546.719 1127.34 546.639 1127.6L533.495 1169.35L639.401 1209.89C640.573 1210.33 641.567 1211.01 642.4 1211.84C644.723 1214.16 645.616 1217.72 644.365 1220.99C642.659 1225.42 637.695 1227.65 633.247 1225.94L528.333 1185.79L519.935 1212.46L646.411 1260.86L694.916 1387.25L779.717 1360.45C779.976 1360.37 780.233 1360.28 780.492 1360.2L730.319 1229.51C728.631 1225.08 730.835 1220.1 735.261 1218.41C738.537 1217.16 742.112 1218.03 744.435 1220.36C745.249 1221.17 745.924 1222.19 746.381 1223.36L796.356 1353.52C840.533 1330.3 861.837 1276.91 843.273 1228.58Z" fill="#CCC2FF"/>
    <path d="M574.059 1402.01C569.632 1403.69 564.648 1401.49 562.94 1397.07L549.637 1362.39C547.929 1357.96 550.153 1352.99 554.581 1351.28C557.857 1350.03 561.412 1350.91 563.735 1353.23C564.568 1354.07 565.244 1355.05 565.68 1356.23L579.003 1390.89C580.691 1395.35 578.487 1400.31 574.059 1402.01ZM593.477 1302.7C596.753 1301.43 600.308 1302.3 602.651 1304.64C603.464 1305.46 604.14 1306.47 604.596 1307.64L641.625 1404.09L694.916 1387.25L646.411 1260.86L519.935 1212.46L474.883 1355.51L530.477 1376.8L551.841 1432.45L625.205 1409.28L588.533 1313.8C586.845 1309.37 589.049 1304.39 593.477 1302.7Z" fill="#B3A4F5"/>
    <path d="M551.841 1432.45L439.461 1467.97L474.882 1355.51L530.477 1376.8L551.841 1432.45Z" fill="#DDD6FF"/>
    <path d="M785.425 1332.37L751.03 1242.77L755.913 1240.9L790.306 1330.49L785.425 1332.37Z" fill="#8B77E8"/>
    <path d="M682.52 1362.39L657.854 1298.12L662.735 1296.25L687.401 1360.51L682.52 1362.39Z" fill="#8B77E8"/>
    <path d="M441.314 1469.81L437.616 1466.12L536.274 1367.36L539.971 1371.05L441.314 1469.81Z" fill="#8B77E8"/>
    <path d="M617.954 1293L614.258 1289.3L795.723 1107.66L799.42 1111.36L617.954 1293Z" fill="#8B77E8"/>
    <path d="M755.451 1153.18L647.109 1111.71L648.979 1106.83L757.32 1148.29L755.451 1153.18Z" fill="#8B77E8"/>
    <path d="M605.364 1218.07L559.58 1200.55L561.448 1195.66L607.233 1213.19L605.364 1218.07Z" fill="#8B77E8"/>
    <path d="M1213.98 952.813L1162.88 944.68L1163.71 939.507L1214.8 947.64L1213.98 952.813Z" fill="#8B77E8"/>
    <path d="M1183.4 857.293L1181.39 856.973L1182.21 851.814L1184.21 852.12L1183.4 857.293Z" fill="#B3A4F5"/>
    <path d="M1100.24 980.92L1062.08 916.867L1066.58 914.187L1104.74 978.24L1100.24 980.92Z" fill="#8B77E8"/>
    <path d="M1250 753.054L1239.2 863.547L1183.81 854.707L1250 753.054Z" fill="#8B77E8"/>
    <path d="M899.736 1775.51H894.507V1294.18L1115.93 954.16L1120.31 957.013L899.736 1295.73V1775.51Z" fill="#8B77E8"/>
    <path d="M897.383 916.013L820.895 742.627L825.68 740.507L902.167 913.907L897.383 916.013Z" fill="#8B77E8"/>
    <path d="M870.55 1698.07H865.321V1176.15L776.654 1072.66L780.628 1069.26L870.55 1174.21V1698.07Z" fill="#8B77E8"/>
    <path d="M899.736 1234.69H894.507V1077.18H899.736V1234.69Z" fill="#8B77E8"/>
    <path d="M678.021 932.16L671.826 890.08L676.999 889.32L683.194 931.4L678.021 932.16Z" fill="#8B77E8"/>
    <path d="M1179.4 866.253L1175.02 863.4L1247.82 751.627L1252.2 754.48L1179.4 866.253Z" fill="#8B77E8"/>
    <path d="M1043.44 1828.67H731.052V1823.44H1043.44V1828.67Z" fill="#624AC9"/>
    <path d="M1104.32 1358.33C1101.98 1358.93 1099.61 1358.64 1097.59 1357.67C1095.05 1356.44 1093.05 1354.15 1092.3 1351.2L1082.15 1311.42C1080.8 1306.13 1083.99 1300.75 1089.28 1299.4C1094.57 1298.05 1099.96 1301.24 1101.31 1306.53L1111.46 1346.32C1112.81 1351.6 1109.61 1356.99 1104.32 1358.33ZM1219.54 1340.39C1224.83 1339.04 1230.21 1342.24 1231.56 1347.52L1239.57 1378.92H1340.48L942.272 1186.66C933.292 1261.32 973.436 1329.45 1035.84 1360.79L1010.68 1262.16C1009.33 1256.87 1012.52 1251.49 1017.81 1250.14C1023.1 1248.79 1028.48 1251.99 1029.83 1257.27L1058.65 1370.24C1075.63 1375.87 1093.82 1378.93 1112.8 1378.93L1219.17 1378.92L1212.41 1352.41C1211.06 1347.12 1214.25 1341.75 1219.54 1340.39Z" fill="#B3A4F5"/>
    <path d="M1273.56 1349.51L920.154 1178.89L922.426 1174.18L1275.83 1344.8L1273.56 1349.51Z" fill="#8B77E8"/>
    <path d="M1896.79 1586.23C1622.05 1586.23 1398.53 1362.72 1398.53 1087.98C1398.53 813.24 1622.05 589.733 1896.79 589.733C2011.37 589.733 2123.31 629.72 2211.99 702.347L2229.05 716.32L2214.19 732.613C2201.56 746.467 2188.75 760.813 2176.12 775.253L2162.87 790.4L2147.23 777.747C2076.55 720.573 1987.6 689.08 1896.79 689.08C1676.83 689.08 1497.89 868.027 1497.89 1087.98C1497.89 1307.93 1676.83 1486.88 1896.79 1486.88C2116.73 1486.88 2295.68 1307.93 2295.68 1087.98C2295.68 1017.85 2277.13 948.853 2242.07 888.453L2234.6 875.613L2244.28 864.333C2256.87 849.653 2270 834.733 2284.41 818.707L2302.84 798.227L2317.61 821.48C2368.27 901.173 2395.03 993.333 2395.03 1087.98C2395.03 1362.72 2171.52 1586.23 1896.79 1586.23Z" fill="#FAF9FF"/>
    <path d="M2299.97 832.693C2286.56 847.6 2273.25 862.68 2260.15 877.96C2296.04 939.76 2316.6 1011.53 2316.6 1087.98C2316.6 1319.47 2128.27 1507.79 1896.79 1507.79C1665.29 1507.79 1476.97 1319.47 1476.97 1087.98C1476.97 856.493 1665.29 668.173 1896.79 668.173C1996.56 668.173 2088.27 703.147 2160.37 761.48C2172.96 747.093 2185.73 732.773 2198.73 718.52C2116.43 651.12 2011.23 610.653 1896.79 610.653C1633.59 610.653 1419.45 824.773 1419.45 1087.98C1419.45 1351.19 1633.59 1565.31 1896.79 1565.31C2159.99 1565.31 2374.12 1351.19 2374.12 1087.98C2374.12 994.173 2346.89 906.56 2299.97 832.693Z" fill="#FAF9FF"/>
    <path d="M1750.05 1442.04L1732.64 1412.28C1731.88 1410.97 1655.97 1281.48 1540.04 1111.06L1528.28 1093.77L1627.72 1026.12L1639.48 1043.42C1709.23 1145.94 1764.97 1234.28 1799.32 1290.11C1871.67 1168 2058.43 872.627 2303.27 632.64L2318.2 617.987L2402.39 703.88L2387.45 718.52C2170.88 930.813 1977.36 1215.5 1868.47 1411.16L1851.27 1442.04H1750.05Z" fill="#FAF9FF"/>
    <path d="M1838.97 1421.13H1762.05L1750.69 1401.71C1749.93 1400.41 1673.47 1270.01 1557.33 1099.3L1622.19 1055.18C1706.25 1178.75 1769.91 1281.66 1799.72 1330.9C1914.61 1130.99 2105.32 855.947 2317.91 647.573L2372.81 703.587C2154.64 917.427 1959.79 1204.05 1850.19 1400.99L1838.97 1421.13Z" fill="#FAF9FF"/>
    <path d="M2299.97 832.693C2286.56 847.6 2273.25 862.68 2260.15 877.96C2296.04 939.76 2316.6 1011.53 2316.6 1087.98C2316.6 1319.47 2128.27 1507.79 1896.79 1507.79C1665.29 1507.79 1476.97 1319.47 1476.97 1087.98C1476.97 856.493 1665.29 668.173 1896.79 668.173C1996.56 668.173 2088.27 703.147 2160.37 761.48C2172.96 747.093 2185.73 732.773 2198.73 718.52C2116.43 651.12 2011.23 610.653 1896.79 610.653C1633.59 610.653 1419.45 824.773 1419.45 1087.98C1419.45 1351.19 1633.59 1565.31 1896.79 1565.31C2159.99 1565.31 2374.12 1351.19 2374.12 1087.98C2374.12 994.173 2346.89 906.56 2299.97 832.693Z" fill="#FFA6CE"/>
    <path d="M1838.97 1421.13H1762.05L1750.69 1401.71C1749.93 1400.41 1673.47 1270.01 1557.33 1099.3L1622.19 1055.18C1706.25 1178.75 1769.91 1281.66 1799.72 1330.9C1914.61 1130.99 2105.32 855.947 2317.91 647.573L2372.81 703.587C2154.64 917.427 1959.79 1204.05 1850.19 1400.99L1838.97 1421.13Z" fill="#FFA6CE"/>
    <path d="M1969.39 677.107C1963.27 676.027 1957.04 675.08 1950.88 674.267L1951.55 669.08C1957.79 669.893 1964.09 670.867 1970.29 671.96L1969.39 677.107ZM2079.09 712.773C2051.13 699.133 2021.64 688.64 1991.4 681.6L1992.59 676.507C2023.2 683.64 2053.08 694.253 2081.39 708.067L2079.09 712.773ZM1509.81 932.053L1504.96 930.093C1536.16 852.933 1589.07 787.107 1657.93 739.72C1728.47 691.2 1811.05 665.547 1896.79 665.547C1909.81 665.547 1922.96 666.16 1935.87 667.347L1935.39 672.547C1922.64 671.373 1909.65 670.787 1896.79 670.787C1725.89 670.787 1573.99 773.333 1509.81 932.053ZM2225.31 1437.56L2221.72 1433.76C2316.91 1344.25 2371.49 1218.22 2371.49 1087.98C2371.49 998.96 2346.65 912.267 2299.6 837.013C2286.24 851.893 2273.96 865.867 2262.13 879.653L2258.16 876.253C2270.69 861.64 2283.73 846.827 2298.03 830.947L2300.33 828.387L2302.17 831.293C2350.95 908.053 2376.73 996.813 2376.73 1087.98C2376.73 1155.27 2363 1220.4 2335.95 1281.55C2309.81 1340.61 2272.59 1393.11 2225.31 1437.56ZM1896.79 1567.92C1772.19 1567.92 1654.01 1520.21 1564.05 1433.56L1567.68 1429.8C1656.67 1515.49 1773.55 1562.69 1896.79 1562.69C2008.95 1562.69 2117.87 1522.68 2203.49 1450.04L2206.88 1454.03C2120.31 1527.48 2010.19 1567.92 1896.79 1567.92Z" fill="#E6659A"/>
    <path d="M1840.51 1423.75H1762.05V1418.52H1837.44L1847.89 1399.71C1957.35 1203.06 2111.12 986.133 2259.23 819.44L2263.13 822.907C2115.27 989.347 1961.75 1205.92 1852.47 1402.25L1840.51 1423.75Z" fill="#E6659A"/>
    <path d="M2303.17 778.88L2299.35 775.32C2322.64 750.307 2346.09 726.16 2369.11 703.547L2316.04 649.4L2319.77 645.747L2376.51 703.627L2374.64 705.453C2351.08 728.533 2327.04 753.24 2303.17 778.88Z" fill="#E6659A"/>
    <path d="M1797.48 1332.25C1773.16 1292.1 1722.32 1209.64 1654.99 1108.57L1659.33 1105.67C1726.72 1206.82 1777.61 1289.35 1801.96 1329.55L1797.48 1332.25Z" fill="#E6659A"/>
    <path d="M1642.19 1089.44C1634.97 1078.7 1627.59 1067.76 1620.03 1056.65L1624.35 1053.71C1631.91 1064.83 1639.31 1075.77 1646.53 1086.53L1642.19 1089.44Z" fill="#E6659A"/>
    <path d="M2923.75 987.613L2878.12 981.267L2878.11 981.32L2923.73 987.667L2923.75 987.613Z" fill="#FFC7BD"/>
    <path d="M2998.39 848.44C2998.84 846.733 2999.33 845.04 2999.88 843.373L2977.67 840.28C2977.61 842.013 2977.29 843.733 2976.73 845.427L2998.39 848.44Z" fill="#624AC9"/>
    <path d="M2865.04 824.614L2864.32 829.8L2918.24 837.293C2917.12 835.613 2916.35 833.72 2916.09 831.72L2865.04 824.614Z" fill="#624AC9"/>
    <path d="M3737.04 945.88L3416.53 901.306L3415.81 906.48L3736.32 951.053L3737.04 945.88Z" fill="#624AC9"/>
    <path d="M3400.69 899.107L3383.52 896.72L3382.8 901.893L3399.97 904.28L3400.69 899.107Z" fill="#624AC9"/>
    <path d="M3673.76 1027.07L3685.28 944.253L3687.85 944.614H3687.87L3539.57 924L3537.28 940.4L3528.05 1006.83L3673.76 1027.07Z" fill="#FF809F"/>
    <path d="M3195.16 908.787C3193.13 914.613 3190.6 920.213 3187.57 925.52C3186.67 929.573 3186.28 934.12 3186.59 939.28C3187.08 947.84 3188.85 954.467 3191.32 959.987L3479.04 1000.01L3490.57 917.187L3200.84 876.907C3200.65 886.893 3199.05 896.573 3196.17 905.707C3195.87 906.72 3195.53 907.76 3195.16 908.787Z" fill="#FFC359"/>
    <path d="M2989.63 893.627L2987.47 909.16L2615.88 857.493L2612.83 879.533L3011.31 934.96C3011.05 932.813 3010.68 930.787 3010.2 928.867C3006.99 923.64 3004.21 918.12 3001.96 912.333C3001.96 912.333 3001.96 912.333 3001.93 912.307C3001.56 911.307 3001.19 910.333 3000.84 909.333C2997.2 899.12 2995.16 888.173 2994.95 876.773C2994.77 867.08 2995.96 857.68 2998.31 848.733L2976.63 845.72C2976.37 846.453 2976.08 847.187 2975.71 847.893C2971.13 856.907 2960.12 860.507 2951.11 855.92L2923.13 841.72C2921.2 840.733 2919.65 839.293 2918.48 837.64L2624.33 796.733L2618.04 841.96L2989.63 893.627Z" fill="#FFC359"/>
    <path d="M2489.77 862.427L2495.35 822.32L2497.93 822.68L2461.53 817.613L2455.96 857.733L2489.77 862.427Z" fill="#8B77E8"/>
    <path d="M2552.43 832.84L2568.15 835.013L2565.99 850.56L2550.27 848.373L2552.43 832.84ZM2494.95 863.147L2607.64 878.827L2610.71 856.773L2580.49 852.573L2582.65 837.04L2612.87 841.24L2619.03 796.947L2497.93 822.68L2500.52 823.04L2494.95 863.147Z" fill="#8B77E8"/>
    <path d="M2385.93 810.48L2385.43 807.04L2385.37 806.653L2380.2 807.413L2390.13 875.16L2429.6 880.64L2430.32 875.467L2394.73 870.52L2385.93 810.48Z" fill="#FAF9FF"/>
    <path d="M2385.93 810.48L2391.11 809.72L2396.93 849.52L2450.79 857.013L2456.36 816.907L2385.43 807.04L2385.93 810.48Z" fill="#E6659A"/>
    <path d="M3283.97 1043.28L3600.64 1087.32L3601.36 1082.14L3284.69 1038.1L3283.97 1043.28Z" fill="#624AC9"/>
    <path d="M3673.76 1027.07L3528.05 1006.83L3521.03 1057.4L3519.21 1070.43L3667.51 1091.04V1091.04L3664.92 1090.68L3673.76 1027.07Z" fill="#E6659A"/>
    <path d="M3191.32 959.987C3200.37 980.28 3219.08 985.707 3220.93 1017.12C3221.19 1021.5 3221.19 1025.4 3221.01 1028.96L3470.19 1063.62L3479.04 1000.01L3191.32 959.987Z" fill="#FFAA45"/>
    <path d="M2927.84 988.187L2957.35 989.947L2971.07 990.773L2988.83 991.84C2997.93 977.533 3011.09 969 3011.67 942.533C3011.73 939.84 3011.59 937.32 3011.31 934.96L2612.83 879.533L2603.97 943.147L2878.12 981.267L2923.75 987.613L2927.84 988.187Z" fill="#FFAA45"/>
    <path d="M2455.96 857.733L2453.05 878.627L2486.87 883.333L2489.77 862.427L2455.96 857.733Z" fill="#624AC9"/>
    <path d="M2494.95 863.147L2492.04 884.053L2489.45 883.693L2598.93 941.493L2607.64 878.827L2494.95 863.147Z" fill="#624AC9"/>
    <path d="M2396.93 849.52L2399.33 865.88L2448.6 872.733L2447.88 877.907L2450.79 857.014L2396.93 849.52Z" fill="#BF307B"/>
    <path d="M3678.93 1027.79L3725.36 1034.25L3716.52 1097.85L3736.88 951.427L3690.45 944.973L3678.93 1027.79Z" fill="#8B77E8"/>
    <path d="M3479.04 1000.01L3470.19 1063.62L3519.21 1070.43L3528.04 1006.83L3479.04 1000.01Z" fill="#DDD6FF"/>
    <path d="M3528.04 1006.83L3539.56 924L3490.57 917.187L3479.04 1000.01L3528.04 1006.83Z" fill="#FAF9FF"/>
    <path d="M3537.28 940.4L3539.56 924L3528.04 1006.83L3519.21 1070.43L3521.03 1057.4L3528.05 1006.83L3537.28 940.4Z" fill="#8B77E8"/>
    <path d="M3678.93 1027.79L3690.45 944.973L3687.85 944.614L3685.28 944.254L3673.76 1027.07L3664.92 1090.68L3667.51 1091.04L3668.51 1091.18L3716.52 1097.85L3725.36 1034.25L3678.93 1027.79Z" fill="#624AC9"/>
    <path d="M2461.53 817.613L2456.36 816.907L2450.79 857.013L2447.88 877.907L2453.05 878.627L2455.96 857.733L2461.53 817.613Z" fill="#4F37B3"/>
    <path d="M2494.95 863.147L2500.52 823.04L2497.93 822.68L2495.35 822.32L2489.77 862.427L2486.87 883.333L2489.45 883.693L2492.04 884.053L2494.95 863.147Z" fill="#4F37B3"/>
    <path d="M2568.15 835.013L2552.43 832.84L2550.27 848.373L2565.99 850.56L2568.15 835.013Z" fill="#FAF9FF"/>
    <path d="M2615.88 857.493L2987.47 909.16L2989.63 893.627L2618.04 841.96L2624.33 796.733V796.72L2621.75 796.36L2619.16 796L2619.03 796.947L2612.87 841.24L2582.65 837.04L2580.49 852.573L2610.71 856.773L2607.64 878.827L2598.93 941.493L2598.8 942.427L2601.39 942.787L2603.97 943.147L2612.83 879.533L2615.88 857.493Z" fill="#FAF9FF"/>
    <path d="M2612.71 949.64L2636.84 953L2637.56 947.813L2613.43 944.467L2612.71 949.64Z" fill="#624AC9"/>
    <path d="M2448.6 872.733L2399.33 865.88L2396.93 849.52L2391.11 809.72L2385.93 810.48L2394.73 870.52L2430.32 875.467L2443.35 877.28L2447.88 877.907L2448.6 872.733Z" fill="#4F37B3"/>
    <path d="M2985.63 1329.53C2985.63 1329.46 2985.6 1329.39 2985.6 1329.31L2985.59 1329.31L3051.95 1941.24L3052.04 1941.19H3086.03C3086.03 1940.93 3086 1940.68 3085.99 1940.41H3086.01L3068.56 1548.69H3009.37L2985.63 1329.53Z" fill="#FF95A9"/>
    <path d="M3052.04 1941.19L3051.95 1941.24L3034.77 1951.12C3032.52 1952.41 3031.12 1954.83 3031.12 1957.43C3031.12 1961.45 3034.39 1964.72 3038.41 1964.72H3086.03V1941.19H3052.04Z" fill="#40327A"/>
    <path d="M2984.87 1053.13C2985.29 1054.3 2985.72 1055.45 2986.16 1056.56L2987.51 1053.64L2984.87 1053.13Z" fill="#40327A"/>
    <path d="M3188.95 1254.71L3181.56 1262.49C3207.11 1252.85 3227 1231.91 3235.32 1205.86L3188.95 1254.71V1254.71Z" fill="#40327A"/>
    <path d="M3149.49 1249.8L3156.25 1250.04L3166.51 1235.98V1235.97L3230.56 1148.17C3228.17 1144.33 3225.53 1140.7 3222.89 1137.11C3216.11 1127.95 3209.36 1118.97 3207.04 1106.95L3169.41 1081.74L3123.56 1223.32L3129.17 1227.69C3137.24 1233.95 3144.05 1241.45 3149.49 1249.8Z" fill="#40327A"/>
    <path d="M3001.07 1222.6C3001.07 1221.24 3001.09 1219.88 3001.19 1218.51L3002.99 1130.38C2999.11 1129.72 2995.47 1128.43 2992.12 1126.66C2989.41 1131.86 2986.08 1136.69 2982.73 1141.56C2974.99 1152.96 2967.21 1164.63 2967.53 1181.9C2967.97 1205.61 2977.57 1226.98 2992.88 1242.74C2995.13 1235.86 2997.81 1229.12 3001.04 1222.6H3001.07Z" fill="#40327A"/>
    <path d="M3220.93 1017.12C3219.08 985.707 3200.37 980.28 3191.32 959.987C3188.85 954.467 3187.08 947.84 3186.59 939.28C3186.28 934.12 3186.67 929.573 3187.57 925.52C3190.6 920.213 3193.13 914.613 3195.16 908.787C3195.53 907.76 3195.87 906.72 3196.17 905.707C3199.05 896.573 3200.65 886.893 3200.84 876.907C3200.87 875.587 3200.88 874.28 3200.85 872.96C3199.8 816.12 3152.84 770.88 3096 771.933C3050.8 772.773 3012.97 802.613 2999.88 843.373C2999.33 845.04 2998.84 846.733 2998.39 848.44C2998.36 848.547 2998.33 848.64 2998.31 848.733C2995.96 857.68 2994.77 867.08 2994.95 876.773C2995.16 888.173 2997.2 899.12 3000.84 909.333C3001.19 910.333 3001.56 911.307 3001.93 912.307C3001.96 912.333 3001.96 912.333 3001.96 912.333C3004.21 918.12 3006.99 923.64 3010.2 928.867C3010.68 930.787 3011.05 932.813 3011.31 934.96C3011.59 937.32 3011.73 939.84 3011.67 942.533C3011.09 969 2997.93 977.533 2988.83 991.84L3041.04 994.96V994.987C3041.13 995 3041.23 994.987 3041.32 994.987V995H3041.33L3041.83 995.027C3041.91 995.04 3041.99 995.04 3042.07 995.04L3056.2 995.987C3056.24 995.347 3056.28 994.72 3056.36 994.08L3062.28 949.987L3058.88 949.52C3034.67 946.267 3018.21 923.267 3022.95 899.307L3036.24 832.093C3054.25 836.48 3070.81 845.667 3084.08 858.8C3096.41 871.027 3112.41 878.893 3129.63 881.213L3132.21 881.56C3139.37 882.52 3144.4 889.093 3143.44 896.253C3142.47 903.413 3135.89 908.427 3128.73 907.467L3126.15 907.12L3119.36 957.653L3119.29 957.64L3113.63 999.827L3124.45 1000.55L3139.63 1001.56L3167.44 1003.43C3168.48 1003.49 3169.49 1003.67 3170.48 1003.88C3174.84 1004.71 3179.11 1006.4 3183.03 1009.03C3184.28 1009.87 3185.43 1010.8 3186.52 1011.76L3186.55 1011.73L3219.6 1040.71C3220.29 1037.21 3220.8 1033.35 3221.01 1028.96C3221.19 1025.4 3221.19 1021.5 3220.93 1017.12Z" fill="#40327A"/>
    <path d="M3041.32 995V994.987C3041.23 994.987 3041.13 995 3041.04 994.987C3041.13 995 3041.23 994.987 3041.32 995Z" fill="#FF95A9"/>
    <path d="M3136.51 1284.97C3134.61 1285.54 3132.61 1285.83 3130.53 1285.76C3120.43 1285.4 3112.53 1276.92 3112.89 1266.82C3113.25 1256.72 3121.72 1248.82 3131.83 1249.18L3149.49 1249.8C3144.05 1241.45 3137.24 1233.95 3129.17 1227.69L3123.56 1223.32L3122.63 1222.6H3054.81H3001.07H3001.04C2997.81 1229.12 2995.13 1235.86 2992.88 1242.74C2987.96 1257.8 2985.37 1273.58 2985.37 1289.52V1325.33C2985.37 1326.25 2985.44 1327.16 2985.51 1328.07L2985.47 1328.07L2985.55 1328.71C2985.56 1328.91 2985.57 1329.11 2985.6 1329.31C2985.6 1329.39 2985.63 1329.46 2985.63 1329.53L3009.37 1548.69H3068.56H3156.29H3214.51L3173.23 1349.2L3176.23 1348.59L3162.31 1280.93L3162.27 1280.94C3162.03 1279.75 3161.77 1278.57 3161.48 1277.4L3136.51 1284.98V1284.97Z" fill="#8B77E8"/>
    <path d="M2994.35 1038.66L2998.8 1028.89L3003.56 1031.06L2999.09 1040.83L2994.35 1038.66ZM3186.55 1011.73L3186.52 1011.76C3185.43 1010.8 3184.28 1009.87 3183.03 1009.03C3179.11 1006.4 3174.84 1004.71 3170.48 1003.88C3169.49 1003.67 3168.48 1003.49 3167.44 1003.43L3139.63 1001.56L3043.59 1055.76L3047.61 995.413L3056.2 995.987L3042.07 995.04C3041.99 995.04 3041.91 995.04 3041.83 995.027L3041.33 995H3041.32C3041.23 994.987 3041.13 995 3041.04 994.987V994.96L2988.83 991.84L2971.07 990.773L2970.76 995.987L2926.79 993.36L2876.88 986.427L2872.36 1005.28C2869.17 1018.54 2877.35 1031.88 2890.61 1035.06C2890.99 1035.15 2891.36 1035.2 2891.73 1035.27V1035.3L2984.87 1053.13L2987.51 1053.64L2990.11 1047.94L2994.87 1050.11L2980.67 1081.29C2979.07 1084.79 2978.12 1088.47 2977.87 1092.21C2976.72 1109.47 2989.83 1124.45 3007.08 1125.61C3012.13 1125.94 3016.96 1125.11 3021.55 1123.13L3023.61 1127.93C3019.04 1129.91 3014.21 1130.91 3009.24 1130.91C3008.41 1130.91 3007.57 1130.88 3006.73 1130.82C3005.47 1130.74 3004.21 1130.59 3002.99 1130.38L3001.19 1218.51C3001.09 1219.88 3001.07 1221.24 3001.07 1222.6H3054.81H3122.63L3123.56 1223.32L3169.41 1081.74L3207.04 1106.95L3243.03 1131.07L3230.56 1148.17L3166.51 1235.97V1235.98L3188.95 1254.71V1254.71L3235.32 1205.86L3296.04 1141.89L3296.01 1141.87C3296.75 1141.1 3297.44 1140.3 3298.09 1139.42C3305.68 1129.01 3303.93 1114.65 3294.43 1106.32L3294.44 1106.31L3219.6 1040.71L3186.55 1011.73Z" fill="#FFC7BD"/>
    <path d="M3166.51 1235.98L3156.25 1250.04L3149.49 1249.8L3131.83 1249.18C3121.72 1248.82 3113.25 1256.72 3112.89 1266.82C3112.53 1276.92 3120.43 1285.4 3130.53 1285.76C3132.61 1285.83 3134.61 1285.54 3136.51 1284.97V1284.98L3161.48 1277.4L3166.52 1275.86V1275.8C3168.69 1275.14 3170.61 1273.94 3172.15 1272.33L3172.19 1272.36L3181.56 1262.49L3188.95 1254.71L3166.51 1235.98Z" fill="#FF95A9"/>
    <path d="M3053.81 926.627C3061.65 927.84 3069.01 922.48 3070.23 914.627L3041.81 910.2C3040.59 918.053 3045.96 925.4 3053.81 926.627Z" fill="#FAF9FF"/>
    <path d="M2923.13 841.72L2951.11 855.92C2960.12 860.507 2971.13 856.907 2975.71 847.893C2976.08 847.187 2976.37 846.453 2976.63 845.72C2976.67 845.627 2976.69 845.533 2976.73 845.427C2977.29 843.733 2977.61 842.013 2977.67 840.28C2977.92 833.373 2974.25 826.627 2967.68 823.293C2965.83 822.347 2963.89 821.773 2961.93 821.493L2961.95 821.48L2930.88 817.107L2930.85 817.173C2925.48 816.413 2920 819.027 2917.4 824.147C2916.17 826.573 2915.77 829.2 2916.09 831.72C2916.35 833.72 2917.12 835.613 2918.24 837.293C2918.32 837.413 2918.39 837.52 2918.48 837.64C2919.65 839.293 2921.2 840.733 2923.13 841.72Z" fill="#FF95A9"/>
    <path d="M3053.81 926.627C3045.96 925.4 3040.59 918.053 3041.81 910.2L3070.23 914.627C3069.01 922.48 3061.65 927.84 3053.81 926.627ZM3047.61 995.413L3043.59 1055.76L3139.63 1001.56L3124.45 1000.55L3113.63 999.827L3119.29 957.64L3119.36 957.653L3126.15 907.12L3128.73 907.467C3135.89 908.427 3142.47 903.413 3143.44 896.253C3144.4 889.093 3139.37 882.52 3132.21 881.56L3129.63 881.213C3112.41 878.893 3096.41 871.027 3084.08 858.8C3070.81 845.667 3054.25 836.48 3036.24 832.093L3022.95 899.307C3018.21 923.267 3034.67 946.267 3058.88 949.52L3062.28 949.987L3056.36 994.08C3056.28 994.72 3056.24 995.347 3056.2 995.987L3047.61 995.413Z" fill="#FF95A9"/>
    <path d="M2927.17 988.147L2927.29 988.16L2957.35 989.947L2927.84 988.187L2927.17 988.147Z" fill="#FFC7BD"/>
    <path d="M3003.56 1031.06L2998.8 1028.89L2994.35 1038.66L2999.09 1040.83L3003.56 1031.06Z" fill="#FF95A9"/>
    <path d="M3006.73 1130.82C3007.57 1130.88 3008.41 1130.91 3009.24 1130.91C3014.21 1130.91 3019.04 1129.91 3023.61 1127.93L3021.55 1123.13C3016.96 1125.11 3012.13 1125.94 3007.08 1125.61C2989.83 1124.45 2976.72 1109.47 2977.87 1092.21C2978.12 1088.47 2979.07 1084.79 2980.67 1081.29L2994.87 1050.11L2990.11 1047.94L2987.51 1053.64L2986.16 1056.56L2975.87 1079.1L2974.8 1081.55L2974.88 1081.58C2973.64 1084.91 2972.89 1088.36 2972.65 1091.86C2971.65 1106.72 2979.73 1120.1 2992.12 1126.66C2995.47 1128.43 2999.11 1129.72 3002.99 1130.38C3004.21 1130.59 3005.47 1130.74 3006.73 1130.82Z" fill="#FF95A9"/>
    <path d="M3214.51 1548.69H3156.29L3263.87 1943.48V1941.19H3297.85C3297.85 1940.01 3297.73 1938.85 3297.49 1937.75H3297.51L3274.8 1827.43L3272.23 1827.95L3266.88 1801.96L3269.44 1801.44L3266.45 1786.88L3263.88 1787.41L3214.51 1548.69Z" fill="#FF95A9"/>
    <path d="M3263.87 1941.19V1964.72H3311.48C3315.51 1964.72 3318.77 1961.45 3318.77 1957.43C3318.77 1954.83 3317.37 1952.41 3315.11 1951.12L3297.85 1941.19H3263.87Z" fill="#40327A"/>
    <path d="M3173.23 1349.2L3214.51 1548.69L3263.88 1787.41L3266.45 1786.88L3269.01 1786.35L3178.35 1348.15L3176.23 1348.59L3173.23 1349.2Z" fill="#7760DB"/>
    <path d="M3272.23 1827.95L3274.8 1827.43L3277.36 1826.89L3272 1800.91L3269.44 1801.44L3266.88 1801.96L3272.23 1827.95Z" fill="#7760DB"/>
    <path d="M2926.79 993.36L2970.76 995.987L2971.07 990.773L2957.35 989.947L2927.29 988.16L2927.17 988.147L2923.73 987.667L2878.11 981.32L2676.39 953.253L2675.67 958.44L2876.88 986.427L2926.79 993.36Z" fill="#624AC9"/>
    </svg>
    
  );
};

export default IncompleteSurveyManagerSVG;
