import { Outlet } from 'react-router-dom';
import NavDrawer from './nav-drawer';
import { useState } from 'react';
import { WarningDialog } from '../../warningdialog';
import { useNav } from '../../../hooks/use.nav.hook';

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { confirmNavigation, cancelNavigation, showWarningDialog } = useNav();

  return (
    <div className="flex flex-row justify-start flex-nowrap min-h-[100vh] bg-white">
      <NavDrawer
        setDrawerOpen={setDrawerOpen}
        overlayActive={showWarningDialog}
      />
      <main
        style={{
          overflowX: 'hidden',
          transition: 'margin-left 0.3s ease ',
          display: 'block',
          textWrap: 'wrap',
        }}
        className="m-0 p-2 md:p-4 md:pt-6 w-full min-h-[100vh]"
      >
        <Outlet />
      </main>
      <WarningDialog
        showWarningDialog={showWarningDialog}
        handleOkButton={() => confirmNavigation()}
        handleCloseButton={() => cancelNavigation()}
      />
    </div>
  );
};

export default Layout;
