const EyeIconSVG = ({ size = 'sm' }) => {
  const sizeMap = {
    xs: 0.8,
    sm: 1,
    md: 1.1,
    lg: 1.2,
  };
  //@ts-ignore
  const width = sizeMap[size] * 17;
  //@ts-ignore
  const height = sizeMap[size] * 13;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1014 0.94239C10.0308 0.94239 11.8757 1.95438 13.5951 3.61707C14.1816 4.18432 14.7123 4.7899 15.1794 5.39575C15.4613 5.76133 15.6615 6.04891 15.771 6.22025L16.0148 6.60138L15.771 6.98251C15.6615 7.15384 15.4613 7.44142 15.1794 7.80701C14.7123 8.41285 14.1816 9.01844 13.5951 9.58568C11.8757 11.2484 10.0308 12.2604 8.1014 12.2604C6.172 12.2604 4.32708 11.2484 2.60773 9.58568C2.02116 9.01844 1.4905 8.41285 1.02336 7.80701C0.741466 7.44142 0.541327 7.15384 0.431749 6.98251L0.187988 6.60138L0.431749 6.22025C0.541327 6.04891 0.741466 5.76133 1.02336 5.39575C1.4905 4.7899 2.02116 4.18432 2.60773 3.61707C4.32708 1.95438 6.172 0.94239 8.1014 0.94239ZM14.059 6.25963C13.6313 5.70494 13.1451 5.14999 12.6116 4.63407C11.1307 3.20201 9.59045 2.35714 8.10137 2.35714C6.6123 2.35714 5.07204 3.20201 3.59119 4.63407C3.05769 5.14999 2.5714 5.70494 2.1437 6.25963C2.05132 6.37943 1.96596 6.49373 1.88792 6.60138C1.96596 6.70903 2.05132 6.82333 2.1437 6.94313C2.5714 7.49782 3.05769 8.05277 3.59119 8.56869C5.07204 10.0007 6.6123 10.8456 8.10137 10.8456C9.59045 10.8456 11.1307 10.0007 12.6116 8.56869C13.1451 8.05277 13.6313 7.49782 14.059 6.94313C14.1514 6.82333 14.2368 6.70903 14.3148 6.60138C14.2368 6.49373 14.1514 6.37943 14.059 6.25963ZM5.27178 6.60138C5.27178 8.16406 6.53859 9.43087 8.10127 9.43087C9.66396 9.43087 10.9308 8.16406 10.9308 6.60138C10.9308 5.03869 9.66396 3.77189 8.10127 3.77189C6.53859 3.77189 5.27178 5.03869 5.27178 6.60138ZM9.51602 6.60138C9.51602 7.38272 8.88262 8.01613 8.10127 8.01613C7.31993 8.01613 6.68653 7.38272 6.68653 6.60138C6.68653 5.82004 7.31993 5.18663 8.10127 5.18663C8.88262 5.18663 9.51602 5.82004 9.51602 6.60138Z"
        fill="#7860DC"
      />
    </svg>
  );
};

export default EyeIconSVG;
