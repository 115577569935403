const IncompleteSurveySelfSVG = ({ size = 'xs' }) => {
  const sizeMap = {
    xs: 0.1,
    sm: 0.2,
    md: 0.3,
    lg: 0.4,
  };
  //@ts-ignore
  const width = sizeMap[size] * 1467;
  //@ts-ignore
  const height = sizeMap[size] * 987;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1467 987"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1466.67 986.668H0V0H1466.67V986.668Z" fill="#43A897" />
      <path
        d="M1303.35 832.057C1303.35 832.057 1426.6 751.824 1352.76 676.789C1278.93 601.755 1256.84 611.048 1278.1 527.413C1299.35 443.779 1385.44 412.668 1351.21 349.201C1310.13 273.021 1216.46 342.517 1158.24 369.195C1050 418.789 1050.65 344.651 1092.21 275.292C1133.78 205.933 1136.77 90.2921 1040.77 90.2921C936.351 90.2921 706.879 315.921 580.463 471.857C504.805 565.183 449.661 500.245 457.323 440.287C462.191 402.179 472.321 340.58 448.613 312.237C407.461 263.044 286.577 417.168 243.896 483.017C76.8506 740.739 279.905 832.057 279.905 832.057H1303.35Z"
        fill="#F8F7FF"
      />
      <path
        d="M370.744 203.479C375.432 192.74 376.934 184.508 373.605 177.724C363.736 157.609 313.422 154.387 281.197 173.957C236.258 201.248 224.377 274.149 244.948 290.335C268.884 309.168 346.544 258.92 370.744 203.479Z"
        fill="#F8F7FF"
      />
      <path d="M1071.7 854.73H691.596V852.064H1071.7V854.73Z" fill="#C4B8FF" />
      <path
        d="M1145.3 875.048H979.917V872.382H1145.3V875.048Z"
        fill="#C4B8FF"
      />
      <path
        d="M929.045 875.048H626.536V872.382H929.045V875.048Z"
        fill="#C4B8FF"
      />
      <path
        d="M443.859 875.048H398.923V872.382H443.859V875.048Z"
        fill="#C4B8FF"
      />
      <path
        d="M470.712 875.048H457.14V872.382H470.712V875.048Z"
        fill="#C4B8FF"
      />
      <path d="M1195.19 855.04H1117.9V852.373H1195.19V855.04Z" fill="#C4B8FF" />
      <path
        d="M661.194 854.73H544.018V852.064H661.194V854.73Z"
        fill="#C4B8FF"
      />
      <path
        d="M518.852 854.73H313.952V852.064H518.852V854.73Z"
        fill="#C4B8FF"
      />
      <path d="M289.54 854.73H253.737V852.064H289.54V854.73Z" fill="#C4B8FF" />
      <path
        d="M1018.89 200.937V832.057H528.901V200.937H1018.89Z"
        fill="#DDD6FF"
      />
      <path
        d="M1018.89 594.737V832.057H528.901V671.071L1018.89 594.737Z"
        fill="#C4B8FF"
      />
      <path
        d="M555.573 805.388V227.604H992.216V805.388H555.573Z"
        fill="white"
      />
      <path
        d="M905.856 386.32L874.842 360.728L883.327 350.444L903.868 367.393L946.947 313.187L957.386 321.484L905.856 386.32Z"
        fill="#FF95A9"
      />
      <path
        d="M845.737 354.039H805.292V351.372H845.737V354.039Z"
        fill="#40327A"
      />
      <path
        d="M790.181 354.039H669.07V351.372H790.181V354.039Z"
        fill="#40327A"
      />
      <path
        d="M845.737 373.588H749.317V370.921H845.737V373.588Z"
        fill="#40327A"
      />
      <path
        d="M743.317 373.588H737.738V370.921H743.317V373.588Z"
        fill="#40327A"
      />
      <path
        d="M723.96 373.588H614.182V370.921H723.96V373.588Z"
        fill="#40327A"
      />
      <path
        d="M771.071 393.137H614.182V390.471H771.071V393.137Z"
        fill="#40327A"
      />
      <path
        d="M905.856 488.476L874.842 462.885L883.327 452.601L903.868 469.549L946.948 415.345L957.386 423.641L905.856 488.476Z"
        fill="#FF95A9"
      />
      <path
        d="M845.737 456.195H669.07V453.528H845.737V456.195Z"
        fill="#40327A"
      />
      <path
        d="M655.292 456.195H614.182V453.528H655.292V456.195Z"
        fill="#40327A"
      />
      <path
        d="M828.182 475.745H614.182V473.079H828.182V475.745Z"
        fill="#40327A"
      />
      <path
        d="M696.404 495.295H691.984V492.628H696.404V495.295Z"
        fill="#40327A"
      />
      <path
        d="M683.759 495.295H614.182V492.628H683.759V495.295Z"
        fill="#40327A"
      />
      <path
        d="M905.856 590.633L874.842 565.043L883.327 554.759L903.868 571.707L946.948 517.503L957.386 525.799L905.856 590.633Z"
        fill="#FF95A9"
      />
      <path
        d="M845.738 558.352H614.182V555.685H845.738V558.352Z"
        fill="#40327A"
      />
      <path
        d="M845.737 577.903H773.895V575.236H845.737V577.903Z"
        fill="#40327A"
      />
      <path
        d="M757.294 577.903H614.182V575.236H757.294V577.903Z"
        fill="#40327A"
      />
      <path
        d="M812.848 597.453H614.182V594.787H812.848V597.453Z"
        fill="#40327A"
      />
      <path d="M896.18 391.804H887.596V377.512L896.18 391.804Z" fill="white" />
      <path
        d="M896.18 393.137H886.263V377.512H888.929V390.471H896.18V393.137Z"
        fill="#7760DB"
      />
      <path
        d="M942.263 393.137H902.554V390.471H939.596V349.819H942.263V393.137Z"
        fill="#7760DB"
      />
      <path
        d="M923.848 339.804H919.932V337.137H923.848V339.804Z"
        fill="#7760DB"
      />
      <path
        d="M887.596 344.491V338.471H915.181L887.596 344.491Z"
        fill="white"
      />
      <path
        d="M888.929 344.492H886.263V337.137H915.181V339.804H888.929V344.492Z"
        fill="#7760DB"
      />
      <path
        d="M911.347 495.295H886.263V488.076H888.929V492.628H911.347V495.295Z"
        fill="#7760DB"
      />
      <path
        d="M942.263 495.295H916.292V492.628H939.596V462.357H942.263V495.295Z"
        fill="#7760DB"
      />
      <path
        d="M942.262 456.181H939.596V451.357H942.262V456.181Z"
        fill="#7760DB"
      />
      <path
        d="M888.929 483.016H886.263V439.295H924.681V441.961H888.929V483.016Z"
        fill="#7760DB"
      />
      <path
        d="M942.263 597.453H886.263V571.624H888.929V594.787H939.596V585.512H942.263V597.453Z"
        fill="#7760DB"
      />
      <path
        d="M942.262 585.512H939.596V581.072H942.262V585.512Z"
        fill="#7760DB"
      />
      <path
        d="M942.262 575.179H939.596V558.349H942.262V575.179Z"
        fill="#7760DB"
      />
      <path
        d="M942.262 558.349H939.596V555.021H942.262V558.349Z"
        fill="#7760DB"
      />
      <path
        d="M921.514 544.12H917.625V541.453H921.514V544.12Z"
        fill="#7760DB"
      />
      <path
        d="M888.929 552.124H886.263V541.453H913.071V544.12H888.929V552.124Z"
        fill="#7760DB"
      />
      <path
        d="M888.929 684.176H886.263V680.516H888.929V684.176Z"
        fill="#7760DB"
      />
      <path
        d="M942.263 699.611H886.263V689.287H888.929V696.944H939.596V690.344H942.263V699.611Z"
        fill="#7760DB"
      />
      <path
        d="M942.262 684.176H939.596V654.509H942.262V684.176Z"
        fill="#7760DB"
      />
      <path
        d="M905.856 692.792L874.842 667.2L883.327 656.916L903.868 673.864L946.947 619.659L957.386 627.956L905.856 692.792Z"
        fill="#FF95A9"
      />
      <path
        d="M828.182 660.511H614.182V657.844H828.182V660.511Z"
        fill="#40327A"
      />
      <path
        d="M845.738 680.06H676.934V677.393H845.738V680.06Z"
        fill="#40327A"
      />
      <path d="M665.42 680.06H660.85V677.393H665.42V680.06Z" fill="#40327A" />
      <path
        d="M645.294 680.06H614.182V677.393H645.294V680.06Z"
        fill="#40327A"
      />
      <path
        d="M696.404 699.611H614.182V696.944H696.404V699.611Z"
        fill="#40327A"
      />
      <path
        d="M992.216 806.723H555.573V804.056H992.216V806.723Z"
        fill="#40327A"
      />
      <path
        d="M653.901 200.937V267.604H893.901V200.937H820.221L801.275 160.071H746.541L727.595 200.937H653.901Z"
        fill="#C4B8FF"
      />
      <path
        d="M653.901 200.937V249.537L893.901 212.151V200.937H820.221L801.275 160.071H746.541L727.595 200.937H653.901Z"
        fill="#A796F2"
      />
      <path
        d="M1345.67 834.723H167.426V832.056H1345.67V834.723Z"
        fill="#C4B8FF"
      />
      <path
        d="M1083.26 395.688C1079.34 413.857 1072.77 431.351 1067.52 449.183C1062.12 467.537 1056.68 486.209 1056.74 505.341C1056.83 530.227 1066.7 554.948 1083.79 573.043C1090.48 580.133 1098.3 586.329 1103.46 594.607C1108.61 602.883 1110.51 614.249 1104.76 622.119C1096.45 633.471 1078.67 631.011 1066.52 638.103C1051.23 647.032 1049.1 669.791 1057.82 685.207C1066.55 700.623 1106.26 744.729 1162.93 733.807C1188.29 728.919 1222.67 704.781 1221.69 678.971C1220.66 652.095 1195 630.325 1195.87 603.444C1196.45 585.833 1208.29 570.949 1218.53 556.617C1228.78 542.285 1238.33 524.823 1233.61 507.847C1226.67 482.856 1194.48 475.137 1178.9 454.404C1168.86 441.053 1166.65 423.492 1166.03 406.801C1165.4 390.109 1165.83 373.004 1160.26 357.257C1146.76 319.063 1098.81 323.619 1083.26 395.688Z"
        fill="#C4B8FF"
      />
      <path
        d="M1144.15 781.397H1141.49V491.731H1144.15V781.397Z"
        fill="#40327A"
      />
      <path
        d="M1144.14 575.428L1141.51 574.996C1145.82 548.791 1175.62 532.495 1176.89 531.813L1178.15 534.163C1177.85 534.323 1148.23 550.529 1144.14 575.428Z"
        fill="#40327A"
      />
      <path
        d="M1141.5 689.499C1138.59 671.8 1117.48 660.24 1117.26 660.125L1118.53 657.777C1119.44 658.269 1141.01 670.063 1144.14 689.067L1141.5 689.499Z"
        fill="#40327A"
      />
      <path
        d="M1157.6 832.055H1127.59C1109.37 832.055 1094.59 817.283 1094.59 799.059V771.396H1190.59V799.059C1190.59 817.283 1175.82 832.055 1157.6 832.055Z"
        fill="#FFC359"
      />
      <path
        d="M1111.64 829.464C1100.3 823.507 1093.26 811.855 1093.26 799.06V788.887H1095.93V799.06C1095.93 810.861 1102.42 821.608 1112.88 827.105L1111.64 829.464Z"
        fill="#40327A"
      />
      <path
        d="M1190.59 782.731H1094.59V780.064H1190.59V782.731Z"
        fill="white"
      />
      <path
        d="M1190.59 772.731H1129.44V770.064H1190.59V772.731Z"
        fill="#40327A"
      />
      <path
        d="M1212.61 834.723H1143.78V832.056H1212.61V834.723Z"
        fill="#40327A"
      />
      <path
        d="M388.224 834.723H264.759V832.056H388.224V834.723Z"
        fill="#40327A"
      />
      <path
        d="M828.181 834.723H815.654V832.056H828.181V834.723Z"
        fill="#40327A"
      />
      <path d="M800.1 834.723H418.337V832.056H800.1V834.723Z" fill="#40327A" />
      <path
        d="M1054.54 834.723H871.426V832.056H1054.54V834.723Z"
        fill="#40327A"
      />
      <path
        d="M245.425 834.723H235.759V832.056H245.425V834.723Z"
        fill="#40327A"
      />
      <path
        d="M1252.76 834.723H1226.76V832.056H1252.76V834.723Z"
        fill="#40327A"
      />
      <path
        d="M1020.22 812.513H1017.55V750.069H1020.22V812.513Z"
        fill="#7760DB"
      />
      <path
        d="M1020.22 721.627H1017.55V462.357H1020.22V721.627Z"
        fill="#7760DB"
      />
      <path
        d="M530.235 720.069H527.568V640.292H530.235V720.069Z"
        fill="#7760DB"
      />
      <path
        d="M530.235 627.628H527.568V622.179H530.235V627.628Z"
        fill="#7760DB"
      />
      <path
        d="M530.235 615.623H527.568V551.409H530.235V615.623Z"
        fill="#7760DB"
      />
      <path
        d="M895.236 268.937H767.844V266.271H892.569V200.937H895.236V268.937Z"
        fill="#7760DB"
      />
      <path
        d="M753.347 268.937H675.532V266.271H753.347V268.937Z"
        fill="#7760DB"
      />
      <path
        d="M1020.22 304.032H1017.55V202.271H978.953V199.604H1020.22V304.032Z"
        fill="#A796F2"
      />
      <path
        d="M967.408 202.271H948.292V199.604H967.408V202.271Z"
        fill="#A796F2"
      />
      <path
        d="M785.291 184.292C785.291 190.581 780.192 195.68 773.902 195.68C767.612 195.68 762.514 190.581 762.514 184.292C762.514 178.001 767.612 172.903 773.902 172.903C780.192 172.903 785.291 178.001 785.291 184.292Z"
        fill="white"
      />
      <path
        d="M786.031 188.139L783.488 187.335C783.799 186.352 783.957 185.329 783.957 184.291C783.957 178.747 779.445 174.236 773.901 174.236C770.041 174.236 766.584 176.389 764.876 179.853L762.485 178.675C764.644 174.292 769.019 171.569 773.901 171.569C780.916 171.569 786.624 177.277 786.624 184.291C786.624 185.603 786.424 186.897 786.031 188.139Z"
        fill="#7760DB"
      />
      <path
        d="M773.901 197.012C766.886 197.012 761.18 191.305 761.18 184.291L761.185 183.925L763.85 183.996L763.846 184.291C763.846 189.836 768.357 194.345 773.901 194.345C774.953 194.345 775.989 194.184 776.978 193.867L777.794 196.405C776.541 196.808 775.23 197.012 773.901 197.012Z"
        fill="#7760DB"
      />
      <path
        d="M888.929 664.291H886.263V643.611H919.571V646.277H888.929V664.291Z"
        fill="#7760DB"
      />
      <path
        d="M130.476 506.805C125.329 506.805 121.142 510.993 121.142 516.139C121.142 521.285 125.329 525.472 130.476 525.472C135.622 525.472 139.809 521.285 139.809 516.139C139.809 510.993 135.622 506.805 130.476 506.805ZM130.476 528.139C123.859 528.139 118.476 522.756 118.476 516.139C118.476 509.521 123.859 504.139 130.476 504.139C137.093 504.139 142.476 509.521 142.476 516.139C142.476 522.756 137.093 528.139 130.476 528.139Z"
        fill="#C4B8FF"
      />
      <path
        d="M1206.81 216.775H1204.14V205.345H1206.81V216.775Z"
        fill="#C4B8FF"
      />
      <path
        d="M1206.81 285.345H1204.14V273.917H1206.81V285.345Z"
        fill="#C4B8FF"
      />
      <path
        d="M1190.03 221.269L1184.32 211.372L1186.63 210.039L1192.34 219.936L1190.03 221.269Z"
        fill="#C4B8FF"
      />
      <path
        d="M1224.32 280.655L1218.6 270.756L1220.91 269.423L1226.63 279.321L1224.32 280.655Z"
        fill="#C4B8FF"
      />
      <path
        d="M1180.06 232.216L1170.17 226.501L1171.5 224.191L1181.4 229.905L1180.06 232.216Z"
        fill="#C4B8FF"
      />
      <path
        d="M1239.45 266.501L1229.55 260.788L1230.88 258.477L1240.78 264.191L1239.45 266.501Z"
        fill="#C4B8FF"
      />
      <path
        d="M1176.9 246.679H1165.47V244.012H1176.9V246.679Z"
        fill="#C4B8FF"
      />
      <path
        d="M1245.47 246.679H1234.04V244.012H1245.47V246.679Z"
        fill="#C4B8FF"
      />
      <path
        d="M1171.5 266.501L1170.17 264.191L1180.06 258.477L1181.4 260.788L1171.5 266.501Z"
        fill="#C4B8FF"
      />
      <path
        d="M1230.88 232.216L1229.55 229.905L1239.45 224.191L1240.78 226.501L1230.88 232.216Z"
        fill="#C4B8FF"
      />
      <path
        d="M1186.63 280.655L1184.32 279.321L1190.03 269.423L1192.34 270.756L1186.63 280.655Z"
        fill="#C4B8FF"
      />
      <path
        d="M1220.91 221.269L1218.6 219.936L1224.32 210.039L1226.63 211.372L1220.91 221.269Z"
        fill="#C4B8FF"
      />
      <path
        d="M593.296 124.959C599.096 127.12 603.709 131.735 605.869 137.532C608.029 131.735 612.644 127.12 618.442 124.959C612.644 122.799 608.029 118.184 605.869 112.387C603.709 118.184 599.096 122.799 593.296 124.959ZM607.202 144.959H604.536C604.536 134.667 596.162 126.292 585.869 126.292V123.625C596.162 123.625 604.536 115.252 604.536 104.959H607.202C607.202 115.252 615.577 123.625 625.869 123.625V126.292C615.577 126.292 607.202 134.667 607.202 144.959Z"
        fill="#C4B8FF"
      />
      <path
        d="M655.235 269.964H652.568V200.937H655.235V269.964Z"
        fill="white"
      />
      <path
        d="M480.091 314.604L494.787 312.076L513.307 389.217L488.524 393.48L480.091 314.604Z"
        fill="#FFC7BD"
      />
      <path
        d="M598.179 345.071L244.987 434.915L240.065 415.567L593.256 325.723L598.179 345.071Z"
        fill="#FF9D45"
      />
      <path
        d="M593.256 325.723L240.065 415.567L235.143 396.219L588.335 306.375L593.256 325.723Z"
        fill="#FFC359"
      />
      <path
        d="M588.335 306.375L235.143 396.219L230.222 376.871L583.414 287.027L588.335 306.375Z"
        fill="#FFC359"
      />
      <path
        d="M215.964 442.297L186.943 449.68L172.178 391.635L201.2 384.253L215.964 442.297Z"
        fill="#FF809F"
      />
      <path
        d="M244.987 434.915L215.964 442.297L201.2 384.253L230.222 376.871L244.987 434.915Z"
        fill="#EEEBFF"
      />
      <path
        d="M681.146 293.065L583.413 287.027L598.178 345.071L681.146 293.065Z"
        fill="#EEEBFF"
      />
      <path
        d="M651.799 311.464L598.176 345.075L593.262 325.725L650.058 304.621L651.799 311.464Z"
        fill="#DDD6FF"
      />
      <path
        d="M681.151 293.062L651.799 311.464L650.058 304.621L681.151 293.062Z"
        fill="#E5659B"
      />
      <path
        d="M681.15 293.063L650.057 304.621L646.574 290.923L681.15 293.063Z"
        fill="#FF809F"
      />
      <path
        d="M240.066 415.567L211.043 422.949L215.964 442.297L244.987 434.915L240.066 415.567Z"
        fill="#DDD6FF"
      />
      <path
        d="M211.043 422.949L182.021 430.332L186.943 449.68L215.964 442.297L211.043 422.949Z"
        fill="#E5659B"
      />
      <path
        d="M305.008 420.772L304.35 418.188L581.04 347.807L581.698 350.391L305.008 420.772Z"
        fill="#40327A"
      />
      <path
        d="M233.128 439.307L232.471 436.724L288.733 422.411L289.391 424.995L233.128 439.307Z"
        fill="#7760DB"
      />
      <path
        d="M185.979 451.3L175.723 410.983L178.308 410.327L187.905 448.059L227.531 437.98L228.188 440.564L185.979 451.3Z"
        fill="#7760DB"
      />
      <path
        d="M173.628 402.745L170.556 390.672L200.87 382.96L201.528 385.544L173.798 392.599L176.212 402.089L173.628 402.745Z"
        fill="#7760DB"
      />
      <path
        d="M241.666 427.268L228.93 377.199L231.515 376.543L244.251 426.612L241.666 427.268Z"
        fill="white"
      />
      <path
        d="M209.751 423.277L208.351 417.773L210.935 417.117L212.335 422.621L209.751 423.277Z"
        fill="white"
      />
      <path
        d="M207.096 412.839L203.299 397.913L205.883 397.257L209.68 412.181L207.096 412.839Z"
        fill="white"
      />
      <path
        d="M591.969 326.056L582.121 287.355L584.705 286.696L594.553 325.397L591.969 326.056Z"
        fill="white"
      />
      <path
        d="M334.544 456.445C338.722 450.799 342.898 445.289 343.051 437.009C343.43 416.604 334.347 415.648 335.543 395.259C336.739 374.869 351.862 375.952 353.058 355.563C353.212 352.931 353.014 350.611 352.552 348.543C351.012 345.835 349.718 342.979 348.683 340.005C348.496 339.488 348.324 338.955 348.167 338.437C346.508 333.167 345.674 327.548 345.782 321.736C346.32 292.749 370.264 269.677 399.251 270.215C428.255 270.753 451.326 294.699 450.787 323.685C450.679 329.497 449.638 335.081 447.784 340.287C447.608 340.797 447.416 341.293 447.226 341.805C447.211 341.82 447.211 341.82 447.211 341.82C446.066 344.767 444.65 347.587 443.011 350.252C442.488 352.301 442.203 354.599 442.26 357.219C442.699 377.639 457.836 377.117 458.275 397.537C458.714 417.957 449.602 418.575 449.223 438.98C449.07 447.26 453.038 452.921 457.018 458.719C460.968 464.531 464.931 470.48 464.767 479.289C464.299 504.568 443.438 524.669 418.159 524.2L370.931 523.323C345.652 522.853 325.567 501.993 326.036 476.715C326.199 467.905 330.38 462.107 334.544 456.445Z"
        fill="#40327A"
      />
      <path
        d="M447.608 497.957C448.75 515.041 435.827 529.816 418.743 530.957C401.66 532.1 386.886 519.176 385.743 502.093C384.602 485.009 397.524 470.235 414.608 469.093C431.691 467.951 446.466 480.875 447.608 497.957Z"
        fill="#FFC7BD"
      />
      <path
        d="M445.593 400.111C446.232 409.663 439.005 417.923 429.455 418.561C419.903 419.2 411.641 411.975 411.004 402.423C410.365 392.872 417.591 384.611 427.143 383.972C436.693 383.333 444.955 390.559 445.593 400.111Z"
        fill="#FF95A9"
      />
      <path
        d="M498.116 379.911C504.88 378.288 511.68 382.455 513.304 389.219C514.928 395.983 510.761 402.781 503.997 404.405C497.233 406.029 490.433 401.863 488.809 395.099C487.185 388.335 491.352 381.535 498.116 379.911Z"
        fill="#FFC7BD"
      />
      <path
        d="M427.281 383.957L500.32 379.584L503.425 404.529L431.555 418.287L427.281 383.957Z"
        fill="#FFC7BD"
      />
      <path
        d="M336.982 820.055C336.982 824.841 333.102 828.721 328.315 828.721C323.528 828.721 319.648 824.841 319.648 820.055C319.648 815.268 323.528 811.388 328.315 811.388C333.102 811.388 336.982 815.268 336.982 820.055Z"
        fill="#FF95A9"
      />
      <path
        d="M418.337 820.055C418.337 824.841 422.217 828.721 427.004 828.721C431.79 828.721 435.67 824.841 435.67 820.055C435.67 815.268 431.79 811.388 427.004 811.388C422.217 811.388 418.337 815.268 418.337 820.055Z"
        fill="#8B77E8"
      />
      <path
        d="M445.617 400.912L447.608 497.957L385.743 502.093L353.387 402.201C351.247 395.593 355.892 388.735 362.823 388.272L427.132 383.973C436.997 383.313 445.415 391.027 445.617 400.912Z"
        fill="#FFC7BD"
      />
      <path
        d="M411.272 404.521C413.072 413.924 422.152 420.087 431.555 418.287C440.958 416.487 447.12 407.405 445.32 398.004C443.52 388.601 434.439 382.439 425.038 384.239C415.635 386.037 409.472 395.119 411.272 404.521Z"
        fill="#FFC7BD"
      />
      <path
        d="M447.678 500.045H385.675L382.334 502.641C371.078 511.388 364.492 524.844 364.492 539.099V552.435C364.492 562.744 372.85 571.101 383.159 571.101H437.006C447.315 571.101 455.672 562.743 455.672 552.433V534.173C455.671 522.333 452.936 510.653 447.678 500.045Z"
        fill="#8B77E8"
      />
      <path
        d="M460.826 433.461C460.681 431.305 460.132 429.279 459.285 427.424L459.304 427.416L444.069 394.067L412.526 408.468L427.76 441.817L427.777 441.809C430.685 448.175 437.29 452.407 444.686 451.912C454.238 451.273 461.464 443.013 460.826 433.461Z"
        fill="#FFC7BD"
      />
      <path
        d="M448.671 407.348L446.395 402.365L448.821 401.259L451.097 406.241L448.671 407.348Z"
        fill="#FF95A9"
      />
      <path
        d="M443.499 453.287C440.963 453.287 438.502 452.777 436.167 451.768L437.224 449.32C439.558 450.328 442.039 450.751 444.599 450.581C453.402 449.993 460.084 442.352 459.496 433.549C459.368 431.641 458.89 429.767 458.072 427.977L457.506 426.736L457.524 426.729L450.832 412.079L453.258 410.972L461.084 428.104L461.022 428.131C461.656 429.829 462.038 431.585 462.158 433.371C462.843 443.641 455.046 452.556 444.776 453.243C444.348 453.272 443.922 453.287 443.499 453.287Z"
        fill="#FF95A9"
      />
      <path
        d="M350.122 415.179C355.45 423.132 366.218 425.259 374.17 419.929C382.122 414.6 384.248 403.833 378.92 395.881C373.591 387.928 362.824 385.801 354.871 391.131C346.919 396.46 344.792 407.227 350.122 415.179Z"
        fill="#FFC7BD"
      />
      <path
        d="M353.078 392.508L298.054 440.739L313.38 460.664L374.17 419.929L353.078 392.508Z"
        fill="#FFC7BD"
      />
      <path
        d="M418.337 552.431C418.337 562.74 426.694 571.097 437.004 571.097C447.313 571.097 455.67 562.74 455.67 552.431C455.67 542.121 447.313 533.764 437.004 533.764C426.694 533.764 418.337 542.121 418.337 552.431Z"
        fill="#8B77E8"
      />
      <path
        d="M364.492 552.431C364.492 562.74 372.85 571.097 383.159 571.097C393.468 571.097 401.826 562.74 401.826 552.431C401.826 542.121 393.468 533.764 383.159 533.764C372.85 533.764 364.492 542.121 364.492 552.431Z"
        fill="#8B77E8"
      />
      <path
        d="M418.337 648.951C418.337 657.28 425.089 664.031 433.417 664.031C441.745 664.031 448.497 657.28 448.497 648.951C448.497 640.623 441.745 633.871 433.417 633.871C425.089 633.871 418.337 640.623 418.337 648.951Z"
        fill="#8B77E8"
      />
      <path
        d="M418.338 552.431L418.337 648.951L448.454 650.087L455.62 553.836L418.338 552.431Z"
        fill="#FF95A9"
      />
      <path
        d="M390.396 387.409L419.469 383.504L414.66 347.705L385.588 351.61L390.396 387.409Z"
        fill="#FF95A9"
      />
      <path
        d="M390.396 387.409C391.474 395.437 398.857 401.071 406.885 399.993C414.913 398.915 420.546 391.532 419.469 383.504C418.39 375.476 411.008 369.843 402.98 370.921C394.952 371.999 389.318 379.381 390.396 387.409Z"
        fill="#FF95A9"
      />
      <path
        d="M382.559 339.336L389.166 338.449L387.391 325.235L380.783 326.123C377.134 326.612 374.574 329.968 375.063 333.617C375.554 337.267 378.91 339.827 382.559 339.336Z"
        fill="#FF95A9"
      />
      <path
        d="M428.391 294.151L378.729 300.821L387.342 364.928L418.184 360.784C430.532 359.125 438.924 347.396 436.506 335.173L428.391 294.151Z"
        fill="#FF95A9"
      />
      <path
        d="M382.104 325.944L380.783 326.123L375.656 287.952L414.992 282.668C425.38 281.273 434.932 288.563 436.327 298.951L436.428 299.699L435.106 299.876C423.854 301.388 413.402 306.528 405.336 314.519C399.042 320.753 390.886 324.765 382.104 325.944Z"
        fill="#40327A"
      />
      <path
        d="M425.984 414.963L423.928 384.187L377.004 387.324L425.984 414.963Z"
        fill="#FF95A9"
      />
      <path
        d="M448.454 650.096L435.646 820.713L418.337 820.055V648.951L448.454 650.096Z"
        fill="#FF95A9"
      />
      <path
        d="M371.891 520.592C372.022 524.272 369.144 527.36 365.464 527.491C361.784 527.621 358.696 524.744 358.566 521.064C358.435 517.384 361.312 514.296 364.992 514.165C368.672 514.035 371.76 516.912 371.891 520.592Z"
        fill="#FF95A9"
      />
      <path
        d="M381.642 532.255C380.581 532.292 379.56 532.143 378.598 531.851L378.597 531.859L363.289 527.208L364.992 514.165L380.981 513.6C386.133 513.417 390.457 517.445 390.64 522.596C390.821 527.748 386.794 532.072 381.642 532.255Z"
        fill="#FF95A9"
      />
      <path
        d="M313.792 460.377C319.412 456.277 320.644 448.399 316.545 442.779C312.445 437.159 304.567 435.927 298.947 440.025C293.327 444.125 292.095 452.004 296.193 457.624C300.293 463.244 308.172 464.476 313.792 460.377Z"
        fill="#FFC7BD"
      />
      <path
        d="M297.244 458.884L360.397 525.424L370.613 516.899L316.545 442.779L297.244 458.884Z"
        fill="#FF95A9"
      />
      <path
        d="M351.851 516.423L363.299 506.867L316.546 442.775L297.24 458.887L351.851 516.423Z"
        fill="#FFC7BD"
      />
      <path
        d="M364.748 549.332L319.767 818.617L336.736 822.112L401.296 556.86L364.748 549.332Z"
        fill="#FF95A9"
      />
      <path
        d="M319.648 820.055H336.982V832.055H312.696C310.644 832.055 308.98 830.391 308.98 828.339C308.98 827.009 309.691 825.78 310.844 825.117L319.648 820.055Z"
        fill="#40327A"
      />
      <path
        d="M426.888 340.732C427.511 344.735 424.771 348.484 420.768 349.107C416.767 349.729 413.017 346.989 412.395 342.987L426.888 340.732Z"
        fill="white"
      />
      <path
        d="M322.289 811.609L319.658 811.169L320.945 803.464L323.576 803.904L322.289 811.609Z"
        fill="#624AC9"
      />
      <path
        d="M419.671 618.584H417.004V609.961H419.671V618.584Z"
        fill="#624AC9"
      />
      <path
        d="M331.012 450.456L329.526 448.239L334.269 445.063L335.753 447.279L331.012 450.456Z"
        fill="#FF95A9"
      />
      <path
        d="M340.144 444.336L338.66 442.12L368.425 422.173L369.911 424.389L340.144 444.336Z"
        fill="#FF95A9"
      />
      <path
        d="M439.296 384.572L439.138 381.911L491.608 378.769L491.768 381.431L439.296 384.572Z"
        fill="#FF95A9"
      />
      <path
        d="M454.609 321.167L453.95 318.583L506.17 305.3L506.828 307.884L454.609 321.167Z"
        fill="#7760DB"
      />
      <path
        d="M476.834 311.327C475.168 308.044 476.476 304.032 479.758 302.364C483.041 300.696 487.054 302.005 488.722 305.288C490.389 308.571 489.081 312.584 485.798 314.251C482.516 315.919 478.502 314.609 476.834 311.327Z"
        fill="#FF95A9"
      />
      <path
        d="M463.078 304.856C464.025 304.376 465.014 304.081 466.009 303.941L466.006 303.932L481.85 301.704L485.798 314.251L471.533 321.499C466.938 323.833 461.32 322 458.985 317.405C456.65 312.809 458.482 307.191 463.078 304.856Z"
        fill="#FF95A9"
      />
      <path
        d="M349.621 639.887H449.214L455.62 553.836L364.748 549.332L349.621 639.887Z"
        fill="#8B77E8"
      />
      <path
        d="M324.364 799.185L321.734 798.745L361.9 558.285L364.53 558.725L324.364 799.185Z"
        fill="#624AC9"
      />
      <path
        d="M419.671 811.389H417.004V654.851H419.671V811.389Z"
        fill="#624AC9"
      />
      <path
        d="M435.67 820.055H418.337V832.055H442.621C444.674 832.055 446.338 830.391 446.338 828.339C446.338 827.009 445.628 825.78 444.474 825.117L435.67 820.055Z"
        fill="#40327A"
      />
    </svg>
  );
};

export default IncompleteSurveySelfSVG;
