const SkillGapsSVG = ({ size = 'xs' }) => {
  const sizeMap = {
    xs: 0.1,
    sm: 0.2,
    md: 0.3,
    lg: 0.4,
  };
  //@ts-ignore
  const width = sizeMap[size] * 1067;
  //@ts-ignore
  const height = sizeMap[size] * 987;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1067 987"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_937_2674)">
        <rect width="1066.67" height="986.667" fill="#FFFFFF" />
        <path
          d="M1066.66 986.667H0V0.00012207H1066.66V986.667Z"
          fill="#FFFFFF"
        />
        <path
          d="M732.225 585.217C728.9 602.247 740.01 618.747 757.04 622.072C774.069 625.396 790.569 614.287 793.894 597.257C797.218 580.227 786.109 563.727 769.08 560.403C752.049 557.077 735.549 568.188 732.225 585.217Z"
          fill="#F9F8FF"
        />
        <path
          d="M762.085 592.147L656.313 478.929L658.261 477.109L764.033 590.327L762.085 592.147Z"
          fill="#C4B8FF"
        />
        <path
          d="M925.636 376.207H833.726V345.9H925.636V376.207Z"
          fill="#EEEBFF"
        />
        <path
          d="M533.217 827.449C533.217 833.664 528.18 838.701 521.965 838.701C515.751 838.701 510.712 833.664 510.712 827.449C510.712 821.235 515.751 816.197 521.965 816.197C528.18 816.197 533.217 821.235 533.217 827.449Z"
          fill="#7760DB"
        />
        <path
          d="M533.235 596.213L533.237 544.691L496.86 545.597L510.725 828.011L533.217 827.451L533.235 596.213Z"
          fill="#7760DB"
        />
        <path
          d="M533.218 817.881H530.552L530.554 777.109H533.221L533.218 817.881Z"
          fill="#40327A"
        />
        <path
          d="M533.223 765.544H530.556V758.101H533.223V765.544Z"
          fill="#40327A"
        />
        <path
          d="M533.223 753.547H530.556L530.564 627.903H533.231L533.223 753.547Z"
          fill="#40327A"
        />
        <path
          d="M330.449 635.384H327.783V627.904H330.449V635.384Z"
          fill="#C4B8FF"
        />
        <path
          d="M330.449 680.261H327.783V672.781H330.449V680.261Z"
          fill="#C4B8FF"
        />
        <path
          d="M318.612 638.556L314.874 632.079L317.183 630.746L320.923 637.223L318.612 638.556Z"
          fill="#C4B8FF"
        />
        <path
          d="M341.051 677.421L337.312 670.943L339.621 669.609L343.361 676.088L341.051 677.421Z"
          fill="#C4B8FF"
        />
        <path
          d="M312.257 645.888L305.779 642.148L307.112 639.839L313.591 643.579L312.257 645.888Z"
          fill="#C4B8FF"
        />
        <path
          d="M351.121 668.327L344.644 664.587L345.977 662.277L352.455 666.017L351.121 668.327Z"
          fill="#C4B8FF"
        />
        <path
          d="M310.419 655.416H302.938V652.749H310.419V655.416Z"
          fill="#C4B8FF"
        />
        <path
          d="M355.296 655.416H347.816V652.749H355.296V655.416Z"
          fill="#C4B8FF"
        />
        <path
          d="M307.112 668.327L305.779 666.017L312.257 662.277L313.591 664.587L307.112 668.327Z"
          fill="#C4B8FF"
        />
        <path
          d="M345.977 645.888L344.644 643.579L351.121 639.839L352.455 642.148L345.977 645.888Z"
          fill="#C4B8FF"
        />
        <path
          d="M317.183 677.421L314.874 676.088L318.612 669.609L320.923 670.943L317.183 677.421Z"
          fill="#C4B8FF"
        />
        <path
          d="M339.621 638.556L337.312 637.223L341.051 630.746L343.361 632.079L339.621 638.556Z"
          fill="#C4B8FF"
        />
        <path
          d="M526.169 342.804L514.303 345.259C507.749 346.615 501.337 342.4 499.981 335.847L497.293 322.848L521.027 317.939L526.169 342.804Z"
          fill="#40327A"
        />
        <path
          d="M556.917 380.709L524.275 387.461L513.159 333.721L545.8 326.969L556.917 380.709Z"
          fill="#FF95A9"
        />
        <path
          d="M554.563 350.868L518.255 358.379L502.495 282.192L555.776 271.171L568.025 330.383C569.964 339.757 563.936 348.929 554.563 350.868Z"
          fill="#FF95A9"
        />
        <path
          d="M512.252 275.52L515.808 274.784L525.319 320.763L498.029 326.408L493.428 304.163C490.716 291.055 499.144 278.231 512.252 275.52Z"
          fill="#40327A"
        />
        <path
          d="M537 283.582L523.991 286.272C518.769 287.354 513.364 284.276 512.149 279.084C510.903 273.755 514.297 268.479 519.624 267.378L532.632 264.687C537.853 263.606 543.26 266.683 544.475 271.875C545.72 277.204 542.325 282.48 537 283.582Z"
          fill="#40327A"
        />
        <path
          d="M532.92 284.426L519.91 287.116C514.689 288.198 509.282 285.12 508.069 279.928C506.822 274.599 510.217 269.323 515.544 268.222L528.552 265.531C533.773 264.45 539.18 267.527 540.393 272.719C541.64 278.048 538.245 283.324 532.92 284.426Z"
          fill="#40327A"
        />
        <path
          d="M551.773 280.525L538.765 283.217C533.544 284.297 528.137 281.22 526.923 276.028C525.677 270.699 529.072 265.423 534.397 264.322L547.407 261.631C552.628 260.551 558.033 263.627 559.248 268.819C560.495 274.148 557.1 279.424 551.773 280.525Z"
          fill="#40327A"
        />
        <path
          d="M522.679 325.95C520.713 326.356 518.789 325.092 518.383 323.126L518.199 322.236L525.319 320.763L525.503 321.654C525.909 323.619 524.645 325.543 522.679 325.95Z"
          fill="#40327A"
        />
        <path
          d="M562.485 294.837L547.016 298.036C540.807 299.321 534.379 295.663 532.935 289.488C531.453 283.152 535.489 276.877 541.822 275.567L557.292 272.367C563.501 271.083 569.929 274.741 571.373 280.915C572.856 287.252 568.819 293.527 562.485 294.837Z"
          fill="#40327A"
        />
        <path
          d="M555.991 296.18L542.983 298.871C537.76 299.951 532.355 296.875 531.14 291.683C529.893 286.353 533.288 281.077 538.615 279.976L551.623 277.284C556.845 276.204 562.251 279.281 563.465 284.473C564.712 289.801 561.316 295.079 555.991 296.18Z"
          fill="#40327A"
        />
        <path
          d="M567.164 290.131L560.044 291.604L565.324 317.128C569.256 316.315 571.784 312.468 570.971 308.536L567.164 290.131Z"
          fill="#40327A"
        />
        <path
          d="M516.756 285.404L549.072 278.72L552.82 296.836L520.504 303.521L516.756 285.404Z"
          fill="#40327A"
        />
        <path
          d="M526.691 332.217C524.9 332.217 523.112 331.777 521.515 330.944L522.749 328.579C524.484 329.485 526.501 329.773 528.439 329.371L534.507 328.115L535.049 330.727L528.977 331.983C528.22 332.137 527.451 332.217 526.691 332.217Z"
          fill="#40327A"
        />
        <path
          d="M538.695 329.972L538.156 327.36L564.956 321.816C567.202 321.352 569.13 320.041 570.39 318.125C571.015 317.172 571.44 316.127 571.651 315.024L574.271 315.524C573.994 316.974 573.439 318.34 572.618 319.589C570.968 322.101 568.439 323.819 565.496 324.428L538.695 329.972Z"
          fill="#40327A"
        />
        <path
          d="M571.288 310.072L566.524 287.044L569.136 286.505L573.9 309.533L571.288 310.072Z"
          fill="#40327A"
        />
        <path
          d="M497.477 323.737L517.647 319.565L518.296 322.705C519.256 327.345 523.796 330.331 528.436 329.369L564.956 321.816C569.596 320.856 572.58 316.316 571.62 311.676L567.619 292.331L514.295 303.361C514.925 306.409 512.964 309.392 509.916 310.023L509.284 310.153L508.751 310.263L495.267 313.053L497.477 323.737Z"
          fill="#7760DB"
        />
        <path
          d="M497.747 325.044L497.208 322.432L500.496 321.752L501.035 324.364L497.747 325.044Z"
          fill="white"
        />
        <path
          d="M544.745 325.996L529.62 329.125L539.767 298.092L554.891 294.964L544.745 325.996Z"
          fill="#A796F2"
        />
        <path
          d="M552.688 324.353L543.42 326.271L553.567 295.237L562.833 293.32L552.688 324.353Z"
          fill="#C4B8FF"
        />
        <path
          d="M597.232 375.137C607.066 386.101 606.148 402.961 595.184 412.795C584.219 422.627 567.36 421.711 557.527 410.745C547.695 399.781 548.611 382.921 559.576 373.089C570.54 363.256 587.399 364.173 597.232 375.137Z"
          fill="#7760DB"
        />
        <path
          d="M482.105 413.601C489.003 426.613 505.143 431.571 518.156 424.673C531.168 417.777 536.127 401.637 529.229 388.624C522.332 375.611 506.192 370.653 493.179 377.551C480.167 384.448 475.208 400.588 482.105 413.601Z"
          fill="#7760DB"
        />
        <path
          d="M502.643 374.611L574.34 366.447L580.423 419.436L508.696 427.614L502.643 374.611Z"
          fill="#7760DB"
        />
        <path
          d="M720.952 218.943C726.404 221.172 729.017 227.4 726.789 232.852C724.56 238.305 718.332 240.919 712.878 238.691C707.426 236.461 704.812 230.233 707.041 224.781C709.27 219.328 715.498 216.715 720.952 218.943Z"
          fill="#FF95A9"
        />
        <path
          d="M741.494 168.685C774.705 182.26 790.621 220.187 777.046 253.396C763.472 286.607 725.545 302.524 692.336 288.948C659.126 275.373 643.208 237.447 656.784 204.237C670.358 171.027 708.285 155.111 741.494 168.685Z"
          fill="#FFE4E0"
        />
        <path
          d="M243.561 420.332C236.435 456.836 260.249 492.205 296.753 499.332C333.257 506.459 368.627 482.644 375.753 446.14C382.88 409.636 359.065 374.267 322.561 367.14C286.057 360.013 250.688 383.828 243.561 420.332Z"
          fill="#EEEBFF"
        />
        <path
          d="M468.598 154.347C465.274 171.376 476.384 187.877 493.414 191.201C510.443 194.527 526.943 183.416 530.268 166.387C533.592 149.357 522.483 132.857 505.452 129.532C488.423 126.207 471.923 137.317 468.598 154.347Z"
          fill="#EEEBFF"
        />
        <path
          d="M403.785 272.287C402.023 281.319 407.915 290.071 416.948 291.835C425.98 293.597 434.732 287.705 436.496 278.672C438.259 269.64 432.367 260.888 423.333 259.124C414.301 257.362 405.549 263.253 403.785 272.287Z"
          fill="#F9F8FF"
        />
        <path
          d="M867.903 426.975C866.139 436.007 872.032 444.759 881.064 446.523C890.098 448.285 898.85 442.393 900.612 433.36C902.376 424.328 896.483 415.576 887.451 413.812C878.418 412.049 869.666 417.941 867.903 426.975Z"
          fill="#F9F8FF"
        />
        <path
          d="M373.076 566.536C370.299 580.76 379.579 594.541 393.802 597.317C408.026 600.095 421.807 590.816 424.583 576.592C427.36 562.369 418.08 548.587 403.858 545.811C389.634 543.033 375.852 552.313 373.076 566.536Z"
          fill="#F9F8FF"
        />
        <path
          d="M105.142 396.025C102.764 408.207 110.71 420.008 122.891 422.387C135.072 424.764 146.875 416.817 149.252 404.637C151.631 392.456 143.684 380.655 131.503 378.276C119.322 375.897 107.52 383.845 105.142 396.025Z"
          fill="#F9F8FF"
        />
        <path
          d="M299.188 431.192C298.059 436.973 301.832 442.576 307.613 443.705C313.395 444.833 318.997 441.061 320.127 435.28C321.255 429.497 317.483 423.896 311.701 422.767C305.919 421.637 300.317 425.409 299.188 431.192Z"
          fill="#FF95A9"
        />
        <path
          d="M319.601 437.895C320.448 433.557 324.649 430.729 328.987 431.575C333.323 432.421 336.152 436.624 335.305 440.96C334.459 445.296 330.257 448.125 325.92 447.279C321.584 446.432 318.755 442.231 319.601 437.895Z"
          fill="#FF95A9"
        />
        <path
          d="M314.559 423.759L331.129 432.319L325.92 447.279L307.613 443.705L314.559 423.759Z"
          fill="#FF95A9"
        />
        <path
          d="M518.156 424.673L488.857 380.405L425.973 431.341L340.847 431.411L332.857 431.413L327.449 431.419L325.931 447.275L428.797 467.356C428.841 467.365 428.881 467.38 428.924 467.388C433.179 468.219 437.372 467.487 440.923 465.601L440.927 465.608L518.156 424.673Z"
          fill="#7760DB"
        />
        <path
          d="M368.839 455.653L344.144 450.832L344.655 448.215L369.348 453.036L368.839 455.653Z"
          fill="#40327A"
        />
        <path
          d="M432.416 467.728C431.251 467.728 430.076 467.615 428.925 467.389L375.729 457L376.24 454.383L429.436 464.772C432.901 465.447 436.553 464.903 439.674 463.247L440.892 462.608L496.593 433.084L497.843 435.441L440.917 465.605C438.304 466.992 435.363 467.728 432.416 467.728Z"
          fill="#40327A"
        />
        <path
          d="M447.891 417.02L446.212 414.948L451.683 410.515L453.363 412.588L447.891 417.02Z"
          fill="#40327A"
        />
        <path
          d="M360.698 434.059L360.696 431.392L425.973 431.343L435.846 423.343L437.526 425.415L426.918 434.009L360.698 434.059Z"
          fill="#40327A"
        />
        <path
          d="M340.849 450.187L325.931 447.275L327.449 431.419L332.857 431.413L340.847 431.412L340.849 450.187Z"
          fill="white"
        />
        <path
          d="M327.445 431.424L325.925 447.28L332.861 448.633L332.852 431.419L327.445 431.424Z"
          fill="#FF95A9"
        />
        <path
          d="M533.237 544.689C533.237 554.741 525.089 562.889 515.037 562.889C504.987 562.889 496.837 554.741 496.837 544.689C496.837 534.637 504.987 526.489 515.037 526.489C525.089 526.489 533.237 534.637 533.237 544.689Z"
          fill="#7760DB"
        />
        <path
          d="M592.285 544.689C592.285 554.741 584.137 562.889 574.085 562.889C564.035 562.889 555.885 554.741 555.885 544.689C555.885 534.637 564.035 526.489 574.085 526.489C584.137 526.489 592.285 534.637 592.285 544.689Z"
          fill="#7760DB"
        />
        <path
          d="M592.851 390.963L593.124 524.008L496.024 528.971L482.729 396.592C481.663 385.977 489.695 376.629 500.349 376.084L573.231 372.359C583.885 371.815 592.829 380.295 592.851 390.963Z"
          fill="#7760DB"
        />
        <path
          d="M593.188 526.489L592.295 544.689L592.251 545.581C591.775 555.275 583.777 562.889 574.072 562.889H515.076C505.371 562.889 497.373 555.275 496.897 545.581L496.855 544.689L495.961 526.489H593.188Z"
          fill="#7760DB"
        />
        <path
          d="M645.393 827.449C645.393 833.664 640.355 838.701 634.14 838.701C627.925 838.701 622.887 833.664 622.887 827.449C622.887 821.235 627.925 816.197 634.14 816.197C640.355 816.197 645.393 821.235 645.393 827.449Z"
          fill="#7760DB"
        />
        <path
          d="M522.139 374.971L551.012 469.713L564.505 400.921L563.072 372.879L522.139 374.971Z"
          fill="white"
        />
        <path
          d="M562.809 367.76L564.505 400.923L558.88 429.58L551.012 469.715L539.093 430.592L521.377 372.478L562.809 367.76Z"
          fill="white"
        />
        <path
          d="M593.124 524.008C594.495 550.822 573.869 573.67 547.056 575.04C520.243 576.411 497.395 555.786 496.024 528.971C494.653 502.158 515.279 479.311 542.092 477.94C568.905 476.57 591.753 497.195 593.124 524.008Z"
          fill="#7760DB"
        />
        <path
          d="M558.88 429.58L551.012 469.715L539.093 430.592L545.1 380.18L547.764 380.044L558.88 429.58Z"
          fill="#40327A"
        />
        <path
          d="M591.974 541.316L645.2 825.364L623.192 830.062L556.379 548.914L591.974 541.316Z"
          fill="#7760DB"
        />
        <path
          d="M622.891 828.793L597.868 723.499L600.462 722.881L625.486 828.177L622.891 828.793Z"
          fill="#40327A"
        />
        <path
          d="M609.018 646.753L600.938 603.632L603.557 603.14L611.638 646.261L609.018 646.753Z"
          fill="#40327A"
        />
        <path
          d="M598.819 592.32L597.005 582.644L599.625 582.152L601.439 591.828L598.819 592.32Z"
          fill="#40327A"
        />
        <path
          d="M656.728 840.783H622.887V827.449H645.407L658.331 834.497C659.405 835.084 660.075 836.211 660.075 837.436C660.075 839.284 658.576 840.783 656.728 840.783Z"
          fill="#40327A"
        />
        <path
          d="M719.721 250.125L705.632 242.696L702.661 247.213L698.275 253.891L650.645 326.328L565.429 369.095L593.15 414.448L673.975 355.028L673.964 355.011C676.569 353.095 678.708 350.477 680.021 347.264C680.039 347.224 680.048 347.183 680.065 347.141L719.721 250.125Z"
          fill="#7760DB"
        />
        <path
          d="M604.627 406.011L603.047 403.861L672.384 352.864C674.694 351.165 676.476 348.892 677.542 346.288L694.982 303.604L697.451 304.612L680.048 347.184C678.759 350.355 676.668 353.023 673.964 355.012L672.932 355.796L604.627 406.011Z"
          fill="#40327A"
        />
        <path
          d="M699.827 298.796L697.357 297.789L713.421 258.493L715.889 259.501L699.827 298.796Z"
          fill="#40327A"
        />
        <path
          d="M715.89 259.501L713.422 258.494L716.34 251.355L706.531 246.184L700.504 255.356L698.276 253.894L705.632 242.695L719.723 250.125L715.89 259.501Z"
          fill="#40327A"
        />
        <path
          d="M639.335 334.987L638.139 332.604L650.645 326.328L676.769 286.599L678.999 288.064L652.481 328.391L639.335 334.987Z"
          fill="#40327A"
        />
        <path
          d="M621.615 343.88L620.42 341.497L627.215 338.087L628.409 340.469L621.615 343.88Z"
          fill="#40327A"
        />
        <path
          d="M702.209 269.332C701.673 269.137 701.139 268.932 700.607 268.715C699.383 268.213 698.176 267.656 697.021 267.056L698.251 264.688C699.335 265.252 700.467 265.776 701.617 266.245C702.115 266.451 702.617 266.643 703.12 266.827L702.209 269.332Z"
          fill="#C4B8FF"
        />
        <path
          d="M719.805 271.832L719.625 269.171C735.154 268.116 748.458 258.515 754.344 244.117C762.781 223.479 752.852 199.824 732.216 191.388C731.84 191.235 731.462 191.088 731.085 190.947L732.02 188.449C732.421 188.599 732.822 188.756 733.221 188.917C755.222 197.912 765.805 223.125 756.813 245.124C750.538 260.473 736.358 270.707 719.805 271.832Z"
          fill="#C4B8FF"
        />
        <path
          d="M725.006 189.191C721.961 188.565 718.84 188.301 715.736 188.389L715.658 185.723C718.969 185.631 722.294 185.912 725.542 186.579L725.006 189.191Z"
          fill="#7760DB"
        />
        <path
          d="M687.81 260.623C674.553 248.488 670.216 229.152 677.017 212.509C682.333 199.505 693.697 189.884 707.414 186.771L708.006 189.372C695.134 192.292 684.473 201.319 679.486 213.516C673.105 229.132 677.172 247.272 689.61 258.656L687.81 260.623Z"
          fill="#E6659A"
        />
        <path
          d="M717.017 315.827C712.173 315.827 707.298 315.42 702.439 314.596L702.885 311.965C727.649 316.168 752.81 309.155 771.929 292.724L773.666 294.747C757.697 308.471 737.635 315.827 717.017 315.827Z"
          fill="#C4B8FF"
        />
        <path
          d="M788.909 277.646L786.701 276.151C789.955 271.344 792.732 266.151 794.953 260.716C801.493 244.718 802.973 227.378 799.231 210.574L801.836 209.994C805.695 227.332 804.169 245.22 797.423 261.726C795.131 267.331 792.267 272.686 788.909 277.646Z"
          fill="#FF95A9"
        />
        <path
          d="M797.141 202.899C796.657 201.404 796.123 199.897 795.555 198.424L798.041 197.463C798.628 198.984 799.179 200.537 799.677 202.079L797.141 202.899Z"
          fill="#FF95A9"
        />
        <path
          d="M786.181 180.739C776.897 167.336 763.976 156.975 748.816 150.779C718.334 138.319 683.129 144.845 659.125 167.404L657.3 165.461C682.062 142.188 718.384 135.457 749.824 148.309C765.464 154.703 778.794 165.392 788.374 179.221L786.181 180.739Z"
          fill="#C4B8FF"
        />
        <path
          d="M636.686 202.897L634.15 202.075C634.821 200.007 635.581 197.932 636.408 195.908C639.137 189.232 642.67 182.931 646.906 177.176L649.056 178.756C644.948 184.335 641.522 190.445 638.877 196.916C638.074 198.88 637.338 200.893 636.686 202.897Z"
          fill="#C4B8FF"
        />
        <path
          d="M649.731 284.076C633.803 264.678 627.001 239.403 631.071 214.736L633.701 215.172C629.759 239.079 636.351 263.578 651.791 282.384L649.731 284.076Z"
          fill="#A796F2"
        />
        <path
          d="M679.708 307.432C671.809 303.701 664.559 298.837 658.157 292.972L659.959 291.007C666.163 296.689 673.191 301.404 680.845 305.02L679.708 307.432Z"
          fill="#C4B8FF"
        />
        <path
          d="M726.704 247.86L725.485 245.488C729.456 243.449 732.575 240.047 734.265 235.909C735.331 233.303 735.791 230.567 735.636 227.78L738.297 227.631C738.476 230.817 737.951 233.943 736.733 236.917C734.803 241.644 731.24 245.529 726.704 247.86Z"
          fill="#7760DB"
        />
        <path
          d="M700.096 242.071C695.336 236.019 694.185 227.836 697.096 220.716C701.565 209.786 714.089 204.53 725.017 208.999C729.58 210.864 733.386 214.275 735.732 218.601L733.389 219.872C731.334 216.086 728.004 213.1 724.008 211.467C714.442 207.556 703.478 212.157 699.564 221.725C697.016 227.957 698.024 235.123 702.192 240.423L700.096 242.071Z"
          fill="#A796F2"
        />
        <path
          d="M307.554 476.297C305.198 476.181 302.835 475.869 300.532 475.368L301.1 472.761C303.26 473.232 305.475 473.525 307.683 473.633L307.554 476.297Z"
          fill="#C4B8FF"
        />
        <path
          d="M314.743 476.044L314.425 473.396C325.728 472.044 335.984 465.976 342.563 456.748L344.735 458.298C337.72 468.134 326.788 474.603 314.743 476.044Z"
          fill="#C4B8FF"
        />
        <path
          d="M352.193 440.247L349.562 439.809C349.756 438.648 349.9 437.456 349.99 436.268L352.649 436.468C352.553 437.736 352.4 439.009 352.193 440.247Z"
          fill="#C4B8FF"
        />
        <path
          d="M349.642 427.172C348.998 422.949 347.694 418.868 345.77 415.044L348.152 413.845C350.203 417.92 351.591 422.269 352.278 426.771L349.642 427.172Z"
          fill="#7760DB"
        />
        <path
          d="M342.875 410.171C339.544 405.364 335.308 401.421 330.284 398.451L331.642 396.156C336.999 399.323 341.515 403.527 345.067 408.652L342.875 410.171Z"
          fill="#C4B8FF"
        />
        <path
          d="M309.807 520.268C301.835 520.268 293.744 519.164 285.728 516.859L286.464 514.296C327.852 526.192 371.376 505.06 387.696 465.135C405.284 422.104 384.587 372.787 341.557 355.197C298.52 337.603 249.208 358.305 231.617 401.336C219.837 430.155 224.851 462.98 244.699 487.004L242.644 488.701C222.168 463.92 216.997 430.057 229.149 400.328C237.94 378.823 254.579 362.028 276 353.039C297.42 344.048 321.061 343.939 342.564 352.729C386.956 370.875 408.309 421.752 390.165 466.143C376.489 499.6 344.295 520.265 309.807 520.268Z"
          fill="#FF95A9"
        />
        <path
          d="M257.116 502.571C253.78 500.047 250.6 497.253 247.664 494.268L249.564 492.397C252.409 495.291 255.492 497.999 258.725 500.444L257.116 502.571Z"
          fill="#C4B8FF"
        />
        <path
          d="M325.164 422.703C323.093 419.647 320.183 417.288 316.748 415.885C313.164 414.421 309.28 414.096 305.523 414.953L304.932 412.351C309.231 411.379 313.667 411.744 317.757 413.416C321.68 415.02 325.004 417.715 327.372 421.208L325.164 422.703Z"
          fill="#C4B8FF"
        />
        <path
          d="M309.647 454.64C306.905 454.64 304.156 454.117 301.556 453.055C290.628 448.588 285.372 436.063 289.837 425.135C291.416 421.275 294.059 417.987 297.476 415.624L298.992 417.82C296.001 419.885 293.688 422.764 292.307 426.144C288.396 435.711 292.999 446.676 302.564 450.585C308.173 452.876 314.577 452.309 319.699 449.064L321.125 451.317C317.651 453.517 313.657 454.64 309.647 454.64Z"
          fill="#E6659A"
        />
        <path
          d="M561.391 328.937C562.201 332.905 559.643 336.78 555.675 337.592C551.707 338.403 547.832 335.844 547.02 331.876L561.391 328.937Z"
          fill="white"
        />
        <path
          d="M795.513 309.307L749.684 263.477L751.569 261.592L797.398 307.421L795.513 309.307Z"
          fill="#C4B8FF"
        />
        <path
          d="M826.558 340.352L800.684 314.477L802.569 312.592L828.445 338.467L826.558 340.352Z"
          fill="#C4B8FF"
        />
        <path
          d="M877.717 364.215H826.169V338.076H838.169V340.743H828.836V361.548H877.717V364.215Z"
          fill="#C4B8FF"
        />
        <path
          d="M897.948 364.215H891.275V361.548H895.282V340.743H850.386V338.076H897.948V364.215Z"
          fill="#C4B8FF"
        />
        <path
          d="M842.056 175.627C838.38 175.627 835.389 178.619 835.389 182.293C835.389 185.969 838.38 188.96 842.056 188.96C845.731 188.96 848.723 185.969 848.723 182.293C848.723 178.619 845.731 175.627 842.056 175.627ZM842.056 191.627C836.909 191.627 832.723 187.44 832.723 182.293C832.723 177.148 836.909 172.96 842.056 172.96C847.201 172.96 851.389 177.148 851.389 182.293C851.389 187.44 847.201 191.627 842.056 191.627Z"
          fill="#C4B8FF"
        />
        <path
          d="M869.948 348.567H833.726V345.9H869.948V348.567Z"
          fill="#A796F2"
        />
        <path
          d="M890.169 348.567H875.059V345.9H890.169V348.567Z"
          fill="#C4B8FF"
        />
        <path
          d="M850.386 356.391H833.726V353.724H850.386V356.391Z"
          fill="#A796F2"
        />
        <path
          d="M452.3 263.651H416.079V260.984H452.3V263.651Z"
          fill="#A796F2"
        />
        <path
          d="M472.523 263.651H457.412V260.984H472.523V263.651Z"
          fill="#C4B8FF"
        />
        <path
          d="M432.739 271.475H416.079V268.808H432.739V271.475Z"
          fill="#A796F2"
        />
        <path
          d="M463.516 255.827H416.079V253.16H463.516V255.827Z"
          fill="#A796F2"
        />
        <path
          d="M222.613 512.408H186.392V509.742H222.613V512.408Z"
          fill="#C4B8FF"
        />
        <path
          d="M181.28 512.408H166.169V509.742H181.28V512.408Z"
          fill="#C4B8FF"
        />
        <path
          d="M222.613 520.231H205.953V517.564H222.613V520.231Z"
          fill="#C4B8FF"
        />
        <path
          d="M222.613 504.584H175.175V501.917H222.613V504.584Z"
          fill="#C4B8FF"
        />
        <path
          d="M353.546 208.96H350.879V171.627H353.546V208.96Z"
          fill="#C4B8FF"
        />
        <path
          d="M388.212 208.96H385.545V171.627H388.212V208.96Z"
          fill="#C4B8FF"
        />
        <path
          d="M376.657 208.96H373.991V171.627H376.657V208.96Z"
          fill="#C4B8FF"
        />
        <path
          d="M365.101 208.96H362.435V171.627H365.101V208.96Z"
          fill="#C4B8FF"
        />
        <path
          d="M388.212 174.293H350.879V171.627H388.212V174.293Z"
          fill="#C4B8FF"
        />
        <path
          d="M388.212 208.96H350.879V206.294H388.212V208.96Z"
          fill="#C4B8FF"
        />
        <path
          d="M388.212 197.404H350.879V194.737H388.212V197.404Z"
          fill="#C4B8FF"
        />
        <path
          d="M388.212 185.849H350.879V183.183H388.212V185.849Z"
          fill="#C4B8FF"
        />
        <path
          d="M760.551 593.904L850.599 369.851L853.072 370.845L765.568 588.568L877.892 535.215L879.036 537.623L760.551 593.904Z"
          fill="#C4B8FF"
        />
        <path
          d="M884.83 534.872L883.686 532.463L901.926 523.799L903.068 526.208L884.83 534.872Z"
          fill="#C4B8FF"
        />
        <path
          d="M658.225 478.965L656.348 477.072L704.628 429.221L706.506 431.115L658.225 478.965Z"
          fill="#A796F2"
        />
        <path
          d="M720.66 417.085L718.781 415.192L725.72 408.316L727.597 410.209L720.66 417.085Z"
          fill="#C4B8FF"
        />
        <path
          d="M732.954 404.899L731.077 403.007L807.554 327.207L809.432 329.1L732.954 404.899Z"
          fill="#C4B8FF"
        />
        <path
          d="M820.512 318.119L818.635 316.225L895.457 240.083L897.336 241.976L820.512 318.119Z"
          fill="#C4B8FF"
        />
        <path
          d="M883.027 296.316L880.554 295.322L907.482 228.32L909.955 229.315L883.027 296.316Z"
          fill="#C4B8FF"
        />
        <path
          d="M870.032 328.651L867.559 327.656L878.165 301.265L880.639 302.26L870.032 328.651Z"
          fill="#C4B8FF"
        />
        <path
          d="M908.719 230.151H778.281V227.484H908.719V230.151Z"
          fill="#C4B8FF"
        />
        <path
          d="M916.719 228.817C916.719 233.235 913.136 236.817 908.719 236.817C904.3 236.817 900.719 233.235 900.719 228.817C900.719 224.399 904.3 220.817 908.719 220.817C913.136 220.817 916.719 224.399 916.719 228.817Z"
          fill="white"
        />
        <path
          d="M908.719 222.151C905.043 222.151 902.052 225.141 902.052 228.817C902.052 232.493 905.043 235.484 908.719 235.484C912.394 235.484 915.386 232.493 915.386 228.817C915.386 225.141 912.394 222.151 908.719 222.151ZM908.719 238.151C903.572 238.151 899.386 233.963 899.386 228.817C899.386 223.672 903.572 219.484 908.719 219.484C913.864 219.484 918.052 223.672 918.052 228.817C918.052 233.963 913.864 238.151 908.719 238.151Z"
          fill="#FF95A9"
        />
        <path
          d="M771.061 591.237C771.061 595.655 767.478 599.237 763.061 599.237C758.642 599.237 755.061 595.655 755.061 591.237C755.061 586.819 758.642 583.237 763.061 583.237C767.478 583.237 771.061 586.819 771.061 591.237Z"
          fill="white"
        />
        <path
          d="M763.059 584.569C759.384 584.569 756.392 587.561 756.392 591.236C756.392 594.912 759.384 597.903 763.059 597.903C766.735 597.903 769.726 594.912 769.726 591.236C769.726 587.561 766.735 584.569 763.059 584.569ZM763.059 600.569C757.914 600.569 753.726 596.383 753.726 591.236C753.726 586.091 757.914 581.903 763.059 581.903C768.206 581.903 772.392 586.091 772.392 591.236C772.392 596.383 768.206 600.569 763.059 600.569Z"
          fill="#C4B8FF"
        />
        <path
          d="M421.075 699.548C421.075 703.965 417.493 707.548 413.075 707.548C408.657 707.548 405.075 703.965 405.075 699.548C405.075 695.129 408.657 691.548 413.075 691.548C417.493 691.548 421.075 695.129 421.075 699.548Z"
          fill="white"
        />
        <path
          d="M413.075 692.881C409.399 692.881 406.408 695.872 406.408 699.548C406.408 703.224 409.399 706.215 413.075 706.215C416.751 706.215 419.741 703.224 419.741 699.548C419.741 695.872 416.751 692.881 413.075 692.881ZM413.075 708.881C407.929 708.881 403.741 704.693 403.741 699.548C403.741 694.401 407.929 690.215 413.075 690.215C418.221 690.215 422.408 694.401 422.408 699.548C422.408 704.693 418.221 708.881 413.075 708.881Z"
          fill="#FFD4CC"
        />
        <path
          d="M236.537 549.599L234.292 548.159L294.432 454.479L296.676 455.919L236.537 549.599Z"
          fill="#C4B8FF"
        />
        <path
          d="M218.725 572.407L230.457 554.132L232.702 555.572L223.837 569.381L262.881 566.679L263.065 569.34L218.725 572.407Z"
          fill="#C4B8FF"
        />
        <path
          d="M277.84 568.317L277.655 565.656L309.556 563.448L309.741 566.109L277.84 568.317Z"
          fill="#A796F2"
        />
        <path
          d="M314.685 565.767L314.501 563.106L539.291 547.548L539.476 550.21L314.685 565.767Z"
          fill="#C4B8FF"
        />
        <path
          d="M544.668 549.849L544.482 547.188L579.152 544.789L579.337 547.452L544.668 549.849Z"
          fill="#C4B8FF"
        />
        <path
          d="M596.948 546.231L596.763 543.569L645.108 540.225L645.292 542.887L596.948 546.231Z"
          fill="#C4B8FF"
        />
        <path
          d="M291.575 424.396L127.425 330.06L127.248 329.663L99.6221 267.516L102.06 266.433L129.509 328.181L292.906 422.084L291.575 424.396Z"
          fill="#C4B8FF"
        />
        <path
          d="M106.169 266.976C106.169 269.921 103.781 272.309 100.836 272.309C97.8899 272.309 95.5024 269.921 95.5024 266.976C95.5024 264.031 97.8899 261.643 100.836 261.643C103.781 261.643 106.169 264.031 106.169 266.976Z"
          fill="white"
        />
        <path
          d="M100.836 262.976C98.6299 262.976 96.8356 264.771 96.8356 266.976C96.8356 269.181 98.6299 270.976 100.836 270.976C103.041 270.976 104.836 269.181 104.836 266.976C104.836 264.771 103.041 262.976 100.836 262.976ZM100.836 273.643C97.1595 273.643 94.1689 270.652 94.1689 266.976C94.1689 263.3 97.1595 260.309 100.836 260.309C104.512 260.309 107.502 263.3 107.502 266.976C107.502 270.652 104.512 273.643 100.836 273.643Z"
          fill="#C4B8FF"
        />
        <path
          d="M136.465 329.125C136.465 333.544 132.883 337.125 128.464 337.125C124.046 337.125 120.464 333.544 120.464 329.125C120.464 324.707 124.046 321.125 128.464 321.125C132.883 321.125 136.465 324.707 136.465 329.125Z"
          fill="white"
        />
        <path
          d="M128.464 322.459C124.789 322.459 121.798 325.451 121.798 329.125C121.798 332.801 124.789 335.792 128.464 335.792C132.14 335.792 135.131 332.801 135.131 329.125C135.131 325.451 132.14 322.459 128.464 322.459ZM128.464 338.459C123.318 338.459 119.131 334.272 119.131 329.125C119.131 323.98 123.318 319.792 128.464 319.792C133.611 319.792 137.798 323.98 137.798 329.125C137.798 334.272 133.611 338.459 128.464 338.459Z"
          fill="#FF95A9"
        />
        <path
          d="M229.28 570.889C229.28 575.308 225.698 578.889 221.28 578.889C216.861 578.889 213.28 575.308 213.28 570.889C213.28 566.471 216.861 562.889 221.28 562.889C225.698 562.889 229.28 566.471 229.28 570.889Z"
          fill="white"
        />
        <path
          d="M221.28 564.223C217.605 564.223 214.613 567.213 214.613 570.889C214.613 574.564 217.605 577.556 221.28 577.556C224.956 577.556 227.947 574.564 227.947 570.889C227.947 567.213 224.956 564.223 221.28 564.223ZM221.28 580.223C216.135 580.223 211.947 576.035 211.947 570.889C211.947 565.743 216.135 561.556 221.28 561.556C226.427 561.556 230.613 565.743 230.613 570.889C230.613 576.035 226.427 580.223 221.28 580.223Z"
          fill="#C4B8FF"
        />
        <path
          d="M403.083 346.289L323.431 304.543L324.668 302.18L404.32 343.928L403.083 346.289Z"
          fill="#C4B8FF"
        />
        <path
          d="M496.6 395.304L407.209 348.453L408.447 346.091L497.837 392.943L496.6 395.304Z"
          fill="#C4B8FF"
        />
        <path
          d="M551.53 424.095L511.112 402.909L512.348 400.548L552.768 421.732L551.53 424.095Z"
          fill="#C4B8FF"
        />
        <path
          d="M646.509 541.804L643.891 541.307L655.792 478.742L567.584 432.509L568.821 430.147L658.781 477.296L646.509 541.804Z"
          fill="#C4B8FF"
        />
        <path
          d="M653.2 541.555C653.2 545.973 649.619 549.555 645.2 549.555C640.782 549.555 637.2 545.973 637.2 541.555C637.2 537.137 640.782 533.555 645.2 533.555C649.619 533.555 653.2 537.137 653.2 541.555Z"
          fill="white"
        />
        <path
          d="M645.2 534.889C641.524 534.889 638.533 537.88 638.533 541.556C638.533 545.231 641.524 548.223 645.2 548.223C648.876 548.223 651.867 545.231 651.867 541.556C651.867 537.88 648.876 534.889 645.2 534.889ZM645.2 550.889C640.055 550.889 635.867 546.701 635.867 541.556C635.867 536.409 640.055 532.223 645.2 532.223C650.345 532.223 654.533 536.409 654.533 541.556C654.533 546.701 650.345 550.889 645.2 550.889Z"
          fill="#FF95A9"
        />
        <path
          d="M841.317 410.269C841.317 413.215 838.929 415.603 835.984 415.603C833.038 415.603 830.65 413.215 830.65 410.269C830.65 407.324 833.038 404.936 835.984 404.936C838.929 404.936 841.317 407.324 841.317 410.269Z"
          fill="white"
        />
        <path
          d="M835.984 406.269C833.779 406.269 831.984 408.063 831.984 410.269C831.984 412.475 833.779 414.269 835.984 414.269C838.189 414.269 839.984 412.475 839.984 410.269C839.984 408.063 838.189 406.269 835.984 406.269ZM835.984 416.936C832.308 416.936 829.317 413.945 829.317 410.269C829.317 406.593 832.308 403.603 835.984 403.603C839.66 403.603 842.651 406.593 842.651 410.269C842.651 413.945 839.66 416.936 835.984 416.936Z"
          fill="#A796F2"
        />
        <path
          d="M206.257 293.237H220.304L213.28 281.071L206.257 293.237ZM224.924 295.904H201.637L213.28 275.737L224.924 295.904Z"
          fill="#C4B8FF"
        />
        <path
          d="M933.28 520.215H919.946V506.882H933.28V520.215Z"
          fill="white"
        />
        <path
          d="M921.281 518.881H931.948V508.215H921.281V518.881ZM934.614 521.548H918.614V505.548H934.614V521.548Z"
          fill="#FF95A9"
        />
        <path
          d="M665.288 478.019C665.288 482.437 661.707 486.019 657.288 486.019C652.869 486.019 649.288 482.437 649.288 478.019C649.288 473.6 652.869 470.019 657.288 470.019C661.707 470.019 665.288 473.6 665.288 478.019Z"
          fill="white"
        />
        <path
          d="M657.286 471.352C653.612 471.352 650.62 474.344 650.62 478.019C650.62 481.695 653.612 484.685 657.286 484.685C660.962 484.685 663.953 481.695 663.953 478.019C663.953 474.344 660.962 471.352 657.286 471.352ZM657.286 487.352C652.141 487.352 647.953 483.165 647.953 478.019C647.953 472.873 652.141 468.685 657.286 468.685C662.433 468.685 666.62 472.873 666.62 478.019C666.62 483.165 662.433 487.352 657.286 487.352Z"
          fill="#C4B8FF"
        />
        <path
          d="M314.991 200.217C314.991 203.163 312.603 205.551 309.658 205.551C306.712 205.551 304.324 203.163 304.324 200.217C304.324 197.271 306.712 194.884 309.658 194.884C312.603 194.884 314.991 197.271 314.991 200.217Z"
          fill="white"
        />
        <path
          d="M309.657 196.217C307.451 196.217 305.657 198.011 305.657 200.217C305.657 202.423 307.451 204.217 309.657 204.217C311.863 204.217 313.657 202.423 313.657 200.217C313.657 198.011 311.863 196.217 309.657 196.217ZM309.657 206.884C305.981 206.884 302.991 203.893 302.991 200.217C302.991 196.541 305.981 193.551 309.657 193.551C313.333 193.551 316.324 196.541 316.324 200.217C316.324 203.893 313.333 206.884 309.657 206.884Z"
          fill="#FFD4CC"
        />
        <path
          d="M310.991 391.911H308.324V354.208H310.991V391.911Z"
          fill="#C4B8FF"
        />
        <path
          d="M310.991 339.991H308.324V295.819H310.991V339.991Z"
          fill="#C4B8FF"
        />
        <path
          d="M317.657 295.817C317.657 300.236 314.076 303.817 309.657 303.817C305.239 303.817 301.657 300.236 301.657 295.817C301.657 291.4 305.239 287.817 309.657 287.817C314.076 287.817 317.657 291.4 317.657 295.817Z"
          fill="white"
        />
        <path
          d="M309.658 289.152C305.982 289.152 302.991 292.143 302.991 295.819C302.991 299.495 305.982 302.485 309.658 302.485C313.334 302.485 316.324 299.495 316.324 295.819C316.324 292.143 313.334 289.152 309.658 289.152ZM309.658 305.152C304.511 305.152 300.324 300.964 300.324 295.819C300.324 290.673 304.511 286.485 309.658 286.485C314.803 286.485 318.991 290.673 318.991 295.819C318.991 300.964 314.803 305.152 309.658 305.152Z"
          fill="#FF95A9"
        />
        <path
          d="M318.089 320.399L317.225 317.876C326.642 314.647 332.97 305.783 332.97 295.819C332.97 282.963 322.512 272.505 309.657 272.505C296.801 272.505 286.342 282.963 286.342 295.819C286.342 305.477 292.152 314 301.141 317.528L300.168 320.011C290.149 316.077 283.676 306.583 283.676 295.819C283.676 281.493 295.332 269.839 309.657 269.839C323.982 269.839 335.637 281.493 335.637 295.819C335.637 306.921 328.585 316.799 318.089 320.399Z"
          fill="#C4B8FF"
        />
        <path
          d="M341.62 274.088L340.076 271.913L498.661 159.28L500.206 161.453L341.62 274.088Z"
          fill="#C4B8FF"
        />
        <path
          d="M566.873 217.769C566.873 220.716 564.485 223.103 561.54 223.103C558.595 223.103 556.207 220.716 556.207 217.769C556.207 214.824 558.595 212.436 561.54 212.436C564.485 212.436 566.873 214.824 566.873 217.769Z"
          fill="white"
        />
        <path
          d="M561.54 213.771C559.334 213.771 557.54 215.564 557.54 217.771C557.54 219.976 559.334 221.771 561.54 221.771C563.745 221.771 565.54 219.976 565.54 217.771C565.54 215.564 563.745 213.771 561.54 213.771ZM561.54 224.438C557.864 224.438 554.873 221.447 554.873 217.771C554.873 214.095 557.864 211.104 561.54 211.104C565.216 211.104 568.206 214.095 568.206 217.771C568.206 221.447 565.216 224.438 561.54 224.438Z"
          fill="#C4B8FF"
        />
        <path
          d="M286.174 863.459H266.72V860.792H286.174V863.459Z"
          fill="#DDD6FF"
        />
        <path
          d="M572.001 863.459H295.056V860.792H572.001V863.459Z"
          fill="#DDD6FF"
        />
        <path
          d="M739.282 863.459H616.715V860.792H739.282V863.459Z"
          fill="#DDD6FF"
        />
        <path
          d="M789.047 863.459H752.605V860.792H789.047V863.459Z"
          fill="#DDD6FF"
        />
        <path
          d="M900.612 842.117H869.278V839.451H900.612V842.117Z"
          fill="#C4B8FF"
        />
        <path
          d="M957.336 842.117H236.275V839.451H957.336V842.117Z"
          fill="#C4B8FF"
        />
        <path
          d="M708.227 884.715H537.369V882.048H708.227V884.715Z"
          fill="#DDD6FF"
        />
        <path
          d="M548.572 884.715H535.653V882.048H548.572V884.715Z"
          fill="#DDD6FF"
        />
        <path
          d="M486.605 884.715H393.005V882.048H486.605V884.715Z"
          fill="#DDD6FF"
        />
        <path
          d="M219.608 842.117H205.728V839.451H219.608V842.117Z"
          fill="#C4B8FF"
        />
        <path
          d="M411.675 842.117H393.005V839.451H411.675V842.117Z"
          fill="#40327A"
        />
        <path
          d="M677.125 842.117H429.188V839.451H677.125V842.117Z"
          fill="#40327A"
        />
        <path
          d="M601.769 193.952L593.973 191.5L594.773 188.956L602.568 191.407L601.769 193.952Z"
          fill="#C4B8FF"
        />
        <path
          d="M586.293 189.084L513.592 166.219L514.39 163.675L587.093 186.54L586.293 189.084Z"
          fill="#A796F2"
        />
        <path
          d="M507.432 160.367C507.432 164.785 503.851 168.367 499.432 168.367C495.015 168.367 491.432 164.785 491.432 160.367C491.432 155.948 495.015 152.367 499.432 152.367C503.851 152.367 507.432 155.948 507.432 160.367Z"
          fill="white"
        />
        <path
          d="M499.433 153.7C495.757 153.7 492.767 156.691 492.767 160.367C492.767 164.043 495.757 167.034 499.433 167.034C503.109 167.034 506.1 164.043 506.1 160.367C506.1 156.691 503.109 153.7 499.433 153.7ZM499.433 169.7C494.287 169.7 490.1 165.512 490.1 160.367C490.1 155.222 494.287 151.034 499.433 151.034C504.579 151.034 508.767 155.222 508.767 160.367C508.767 165.512 504.579 169.7 499.433 169.7Z"
          fill="#FF95A9"
        />
        <path
          d="M291.685 472.412C270.616 462.765 260.986 438.395 269.76 416.928C278.589 395.325 302.649 384.72 324.532 392.781L323.61 395.284C303.078 387.723 280.512 397.672 272.228 417.936C263.997 438.075 273.032 460.939 292.794 469.988L291.685 472.412Z"
          fill="#7760DB"
        />
        <path
          d="M499.377 840.783H533.217V827.449H510.699L497.775 834.497C496.699 835.084 496.029 836.211 496.029 837.436C496.029 839.284 497.528 840.783 499.377 840.783Z"
          fill="#40327A"
        />
        <path
          d="M454.966 196.072C454.966 199.018 452.58 201.406 449.633 201.406C446.688 201.406 444.3 199.018 444.3 196.072C444.3 193.126 446.688 190.739 449.633 190.739C452.58 190.739 454.966 193.126 454.966 196.072Z"
          fill="white"
        />
        <path
          d="M449.633 192.071C447.428 192.071 445.633 193.865 445.633 196.071C445.633 198.277 447.428 200.071 449.633 200.071C451.839 200.071 453.633 198.277 453.633 196.071C453.633 193.865 451.839 192.071 449.633 192.071ZM449.633 202.737C445.957 202.737 442.967 199.747 442.967 196.071C442.967 192.395 445.957 189.404 449.633 189.404C453.309 189.404 456.3 192.395 456.3 196.071C456.3 199.747 453.309 202.737 449.633 202.737Z"
          fill="#C4B8FF"
        />
        <path
          d="M511.999 328.159L519.119 326.685L517.647 319.565C516.833 315.633 512.985 313.105 509.053 313.919C505.121 314.732 502.593 318.579 503.407 322.511C504.22 326.444 508.067 328.972 511.999 328.159Z"
          fill="#FF95A9"
        />
        <path
          d="M495.536 314.359L494.997 311.747L509.645 308.716C510.772 308.483 511.738 307.827 512.37 306.865C513.001 305.905 513.221 304.756 512.988 303.631L512.718 302.325L550.38 294.535L550.918 297.147L515.745 304.421C515.765 305.8 515.373 307.153 514.598 308.331C513.576 309.887 512.009 310.951 510.185 311.328L495.536 314.359Z"
          fill="white"
        />
        <path
          d="M557.341 295.817L556.803 293.205L567.349 291.024L567.889 293.636L557.341 295.817Z"
          fill="white"
        />
        <path
          d="M720.952 218.943C726.404 221.172 729.017 227.4 726.789 232.852C724.56 238.305 718.332 240.919 712.878 238.691C707.426 236.461 704.812 230.233 707.041 224.781C709.27 219.328 715.498 216.715 720.952 218.943Z"
          fill="#FF95A9"
        />
        <path
          d="M715.353 239.687C711.263 238.015 706.592 239.975 704.921 244.065C703.249 248.155 705.209 252.825 709.299 254.497C713.389 256.169 718.06 254.208 719.732 250.119C721.403 246.029 719.443 241.359 715.353 239.687Z"
          fill="#FF95A9"
        />
        <path
          d="M705.632 242.695L719.722 250.125L713.971 264.196L698.276 253.891L702.662 247.215L705.632 242.695Z"
          fill="white"
        />
        <path
          d="M706.303 227.713L704.367 246.265L719.733 250.119L726.79 232.852L706.303 227.713Z"
          fill="#FF95A9"
        />
        <path
          d="M705.64 242.693L719.729 250.123L717.056 256.664L702.669 247.211L705.64 242.693Z"
          fill="#FF95A9"
        />
        <path
          d="M794.741 734.643C785.476 734.643 777.937 742.18 777.937 751.447C777.937 760.712 785.476 768.249 794.741 768.249C804.007 768.249 811.544 760.712 811.544 751.447C811.544 742.18 804.007 734.643 794.741 734.643ZM794.741 770.916C784.005 770.916 775.271 762.181 775.271 751.447C775.271 740.711 784.005 731.976 794.741 731.976C805.476 731.976 814.211 740.711 814.211 751.447C814.211 762.181 805.476 770.916 794.741 770.916Z"
          fill="#40327A"
        />
        <path
          d="M858.742 839.447H730.74V751.447H858.742V839.447Z"
          fill="#DDD6FF"
        />
        <path
          d="M858.742 839.447H845.408V751.447H858.742V839.447Z"
          fill="#40327A"
        />
        <path
          d="M845.408 796.78H730.74V794.113H845.408V796.78Z"
          fill="#40327A"
        />
        <path
          d="M797.408 806.113H778.741V794.113H797.408V806.113Z"
          fill="white"
        />
        <path
          d="M913.946 842.117H901.692V839.451H913.946V842.117Z"
          fill="#40327A"
        />
        <path
          d="M884.578 842.117H823.083V839.451H884.578V842.117Z"
          fill="#40327A"
        />
        <path
          d="M354.231 884.715H328.004V882.048H354.231V884.715Z"
          fill="#DDD6FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_937_2674">
          <rect width="1066.67" height="986.667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SkillGapsSVG;
